<template>
  <div class="vehicles">
    <div :class="['vehicle', { 'hover-active': isHoverActive }]">
      <img :src="vehicle.logo" alt="" />
      <div class="infos">
        <div class="model" :data-cy="`vehicle-model-${vehicle.id}`">
          {{ `${vehicle.local_model} | ${vehicle.local_version[0]}` }}
        </div>
      </div>
      <div class="details">
        <div class="finition" :data-cy="`vehicle-trim-level-${vehicle.id}`">
          {{ vehicle.local_trim_level[0] }}
        </div>
        <div class="power" :data-cy="`vehicle-power-${vehicle.id}`">
          {{ vehicle.fiscal_hp[0] }} {{ $t("vehicleChoice.fiscal_hp") }}
        </div>
        <div class="year" :data-cy="`vehicle-year-${vehicle.id}`">
          {{ vehicle.model_year }}
        </div>
        <div class="version" :data-cy="`vehicle-version-${vehicle.id}`">
          {{ vehicle.version }}
        </div>
      </div>
      <div v-if="isSelectedVehicle(vehicle)" class="additional-info">
        <u>
          {{ $t("vehicleChoice.infos.additional_information") }}
        </u>
        <div class="dynamic-inputs" data-cy="vehicle-dynamic-data">
          <div v-if="showOptionalDynamicInputs" class="dynamic-inputs__optional-items">
            <div class="dynamic-inputs__item multiselect-44" data-cy="vehicle-type">
              <label for="vehicle-type">
                {{ $t("vehicleChoice.infos.vehicle_type") }}
              </label>
              <FormSelect
                for-id="vehicle-type"
                name="vehicle_type"
                label-select-attr="label"
                :selected-option.sync="form.dynamicInputs.vehicleType"
                :select-options="vehicleTypeOptions"
                :errors="{}"
                @
              ></FormSelect>
            </div>
            <div
              class="dynamic-inputs__item date-picker-100"
              data-cy="first-release-date"
            >
              <label for="first_release_date">
                {{ $t("vehicleChoice.infos.first_release_date") }}
              </label>
              <datepicker
                id="first_release_date"
                v-model="form.dynamicInputs.firstReleaseDate"
                data-cy="first-release-date"
                input-class="form-control"
                :language="language"
                :monday-first="isMondayFirst"
                :disabled-dates="{
                  from: new Date(),
                }"
              ></datepicker>
            </div>
            <div class="dynamic-inputs__item" data-cy="km">
              <label for="id-km">
                {{ $t("vehicleChoice.infos.km") }}
              </label>
              <FormInput
                v-model="form.dynamicInputs.km"
                name="km"
                type="text"
                :errors="dynamicInputsErrors"
              />
            </div>
          </div>
          <div class="dynamic-inputs__mandatory-items">
            <div class="dynamic-inputs__item__retail-price" data-cy="retail-price">
              <label for="id-retail_price">
                {{ $t("vehicleChoice.infos.retail_price") }}
              </label>
              <FormInput
                v-model="form.dynamicInputs.retailPrice"
                name="retailPrice"
                type="text"
                :errors="dynamicInputsErrors"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      v-if="isSelectedVehicle(vehicle)"
      class="btn btn-primary ml-auto"
      :disabled="isValidateButtonDisabled"
      data-cy="validate-dynamic-info-button"
      @click="onValidate"
    >
      {{ $t("vehicleChoice.infos.validate") }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { en, fr, ko, es } from "vuejs-datepicker/dist/locale"
import Datepicker from "vuejs-datepicker"
import NumberService from "../../services/technical/NumberService"
import DateService from "../../services/technical/DateService"
import VehicleSearchService from "../../services/business/VehicleSearchService"

export default {
  name: "Vehicle",
  components: { Datepicker },
  props: {
    vehicle: { type: Object, required: true },
    showOptionalDynamicInputs: { type: Boolean, required: true },
    vehicleTypeOptions: { type: Object, required: true },
  },
  data() {
    return {
      form: {
        dynamicInputs: {
          vehicleType: null,
          firstReleaseDate: null,
          km: null,
          retailPrice: null,
        },
      },
      dynamicInputsErrors: { km: "", retailPrice: "" },
      languages: { en, es, fr, ko },
    }
  },
  computed: {
    ...mapGetters("searchVehicle", ["getVehicleTypeFilterValue", "isSelectedVehicle"]),
    ...mapGetters("config", ["getCurrentLang"]),
    language() {
      return this.languages[this.getCurrentLang]
    },
    isMondayFirst() {
      return this.getCurrentLang === "fr" || this.getCurrentLang === "es"
    },
    isValidateButtonDisabled() {
      return !this.areAllDynamicInputsFilled
    },
    areAllDynamicInputsFilled() {
      return Object.values(this.form.dynamicInputs).every(
        (value) => value !== null && value !== ""
      )
    },
    isHoverActive() {
      return !this.isSelectedVehicle(this.vehicle)
    },
  },
  created() {
    this.initDynamicInputs()
  },
  methods: {
    initDynamicInputs() {
      this.form.dynamicInputs = {
        ...VehicleSearchService.getInitializedDynamicInputs(
          this.getVehicleTypeFilterValue,
          this.vehicle
        ),
      }
      this.dynamicInputsErrors = { km: "", retailPrice: "" }
    },
    async onValidate() {
      this.validateDynamicInputs()
      if (!this.areDynamicInputsInError()) {
        const selectedVehicle = {
          ...structuredClone(this.vehicle),
          ...this.getFormattedDynamicInputs(),
        }
        await this.$store.dispatch("searchVehicle/setSelectedVehicle", selectedVehicle)
      }
      this.$emit("dynamic-inputs-validated")
    },
    validateDynamicInputs() {
      this.validateDynamicInput("km")
      this.validateDynamicInput("retailPrice")
    },
    validateDynamicInput(inputName) {
      const inputValue = this.form.dynamicInputs[inputName]
      let errorMsg = ""
      if (!this.isDynamicInputValid(inputValue)) {
        errorMsg = this.$t(
          "vehicleChoice.dynamic_inputs_errors.not_a_valid_strictly_positive_number"
        )
      }
      this.$set(this.dynamicInputsErrors, inputName, errorMsg)
    },
    isDynamicInputValid(inputValue) {
      return NumberService.isNumber(inputValue) && inputValue > 0
    },
    areDynamicInputsInError() {
      return Object.values(this.dynamicInputsErrors).some((value) => value !== "")
    },
    getFormattedDynamicInputs() {
      return {
        vehicle_type: this.form.dynamicInputs.vehicleType,
        first_release_date: DateService.format(
          this.form.dynamicInputs.firstReleaseDate,
          "YYYY-MM-DD"
        ),
        km: parseInt(this.form.dynamicInputs.km),
        retail_price: parseInt(this.form.dynamicInputs.retailPrice),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.vehicle {
  display: grid;
  grid-template-areas:
    "img infos"
    "img details"
    "additional-info additional-info";
  grid-template-columns: 18% auto;
  column-gap: 10px;
  padding: 10px;
  border: 1px solid $table-border-color;
  &.hover-active {
    cursor: pointer;
    &:hover {
      background: rgba($bleuc50, 0.5);
    }
  }
  img {
    grid-area: img;
    justify-self: center;
    align-self: center;
    max-width: 136px;
    max-height: 64px;
  }
  .infos {
    grid-area: infos;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $caareagray;
    .model {
      color: $bleuc700;
      font-size: 24px;
      letter-spacing: 0.15px;
      line-height: 28px;
    }
  }
  .details {
    grid-area: details;
    display: grid;
    grid-template-areas:
      "finition power"
      "year version";
    justify-content: space-between;
    gap: 4px;
    color: $medium-gray;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 19px;
    .finition {
      grid-area: finition;
    }
    .power {
      grid-area: power;
      text-align: end;
    }
    .year {
      grid-area: year;
    }
    .engine {
      grid-area: engine;
      text-align: end;
    }
    .version {
      grid-area: version;
    }
  }
  .additional-info {
    grid-area: additional-info;
    background: $bleuc50;
    margin: 10px -5px -5px;
    padding: 16px 10px 24px;

    u {
      font-family: $font-family-title;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.15px;
    }

    .form-infos {
      .infos-selects {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
      }
    }
    .dynamic-inputs {
      margin-top: 8px;

      .dynamic-inputs__optional-items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;

        .dynamic-inputs__item {
          text-align: center;
          min-width: 180px;
          label {
            font-family: $font-family-title;
            line-height: 14px;
            letter-spacing: 0.15px;
            text-transform: inherit;
          }
        }
      }
      .dynamic-inputs__mandatory-items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;

        .dynamic-inputs__item__retail-price {
          grid-column-start: 2;
          grid-column-end: 3;
          text-align: center;
          min-width: 180px;
          label {
            font-family: $font-family-title;
            line-height: 14px;
            letter-spacing: 0.15px;
            text-transform: inherit;
          }
        }
      }
    }
  }
  ::v-deep {
    .vdp-datepicker__calendar .cell {
      &.selected {
        background: $primary;
        color: #fff;
      }

      &:hover {
        border-color: $primary;
      }

      &.day-header,
      &.blank {
        &:hover {
          border-color: transparent;
        }
      }

      &:not(.blank):not(.disabled) {
        &.day:hover,
        &.month:hover,
        &.year:hover {
          border-color: $primary;
        }
      }
    }
  }
}
</style>
