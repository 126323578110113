import Vue from "vue"
import Vuex from "vuex"
import auth from "@/store/auth"
import productLine from "@/store/product_line"
import sale from "@/store/sale"
import basket from "@/store/basket"
import config from "@/store/config"
import vehicle from "@/store/vehicle"
import repository from "@/store/repository"
import offer from "@/store/offer"
import program from "@/store/program"
import gui from "@/store/gui"
import searchVehicle from "@/store/search_vehicle"
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    basket,
    config,
    productLine,
    offer,
    sale,
    vehicle,
    repository,
    program,
    gui,
    searchVehicle,
  },
})
