import { render, staticRenderFns } from "./ProgramProductSynthesis.vue?vue&type=template&id=6774c64a&scoped=true&"
import script from "./ProgramProductSynthesis.vue?vue&type=script&lang=js&"
export * from "./ProgramProductSynthesis.vue?vue&type=script&lang=js&"
import style0 from "./ProgramProductSynthesis.vue?vue&type=style&index=0&id=6774c64a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6774c64a",
  null
  
)

export default component.exports