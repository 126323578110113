<template>
  <!-- content -->
  <div class="compte-infos">
    <span class="small text-uppercase">{{ $t("my_infos") }}</span>
    <div class="block-infos">
      <strong>Eric Chelli</strong>
      <div class="row">
        <div class="col-6 col-lg-8 infos-details">
          15 rue du Grand Chemin
          <br />
          94400 / Vitry-sur-Seine
          <br />
          01 65 89 55 23 / 06 87 65 85 32
          <br />
          eric.chelli18@gmail.com
          <span class="medium-gray">(identifiant)</span>
        </div>
        <div class="col-6 col-lg-4 text-right">
          <a class="text-btn" href="">
            {{ $t("modify_my_infos") }}
            <span class="btn btn-icon btn-icon-sm btn-primary ml-2">
              <img src="/img/icons/edit-alt.png" alt="" />
            </span>
          </a>
          <a class="text-btn" href="">
            {{ $t("modify_my_pwd") }}
            <span class="btn btn-icon btn-icon-sm btn-primary ml-2">
              <img src="/img/icons/edit-alt.png" alt="" />
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Account",
}
</script>
