import VehicleSearchService from "../../services/business/VehicleSearchService"

export default {
  computed: {
    vehicleTypeOptions() {
      return this.getSelectedVehicle
        ? VehicleSearchService.getVehicleTypeOptions(
            this.getVehicleTypeFilterValue,
            this.getSelectedVehicle
          )
        : {}
    },
    showOptionalDynamicInputs() {
      return this.getSelectedVehicle
        ? !VehicleSearchService.canVehicleOnlyBeVnpj(
            this.getVehicleTypeFilterValue,
            this.getSelectedVehicle
          )
        : true
    },
  },
  methods: {
    async onDynamicInputsValidated() {
      // TODO: rework external sale management (it has been removed when reworking vehicle search)
      if (!this.isExternalSale) {
        await this.$store.dispatch("sale/createSale", {
          vehicle: this.getSelectedVehicle,
        })
      }

      if (this.sharedToken) {
        await this.$router.push({
          name: "shared_products",
          params: { sharedToken: this.sharedToken, saleId: this.currentSaleId },
        })
      } else {
        await this.$router.push({
          name: "products",
          params: { saleId: this.currentSaleId },
        })
      }
      await this.$store.dispatch("searchVehicle/resetAllFilters")
      await this.$store.dispatch("searchVehicle/resetVehicles")
      await this.$store.dispatch("searchVehicle/resetSelectedVehicle")
    },
  },
}
