<template>
  <div class="page-offers">
    <div class="container">
      <ModalSharingStatus
        v-if="showSharingStatusModal"
        :status="sharingStatus"
      ></ModalSharingStatus>
      <ModalWarning
        v-if="warningModal.show"
        :title="warningModal.title"
        :reason="warningModal.reason"
        :detailed-reason="warningModal.detailedReason"
        :advice="warningModal.advice"
        @close="onCloseWarningModal"
      ></ModalWarning>
      <spinner
        v-if="showSpinner"
        :message="$t('spinner.loading')"
        class="mt-5 mb-5"
      ></spinner>
      <div v-else-if="offers && offers.length > 0">
        <div v-for="offer in offers" :key="offer.id">
          <OfferSheetFlitter
            v-if="isOfferExternal(offer.id)"
            :offer="offer"
          ></OfferSheetFlitter>
          <OfferSheet
            v-else
            :offer="offer"
            :vehicle="currentSale.vehicle"
            @addToBasket="addToBasket(offer)"
            @delFromBasket="delFromBasket(offer)"
            @computePricing="computePricing(offer)"
          ></OfferSheet>
        </div>
      </div>
      <div v-else>
        <div class="card mt-5 mb-5">
          <div class="card-body text-justify text-center" data-cy="no-product-line">
            <h4 class="">
              {{ $t("product_lines.no_product_line") }}
            </h4>
            <div>
              {{ $t("product_lines.go_back") }}
            </div>
          </div>
        </div>
      </div>
      <div class="nav-bottom-bar">
        <div class="text-left">
          <button
            class="text-uppercase font-size-14 font-roboto-medium nav-button"
            data-cy="button-previous-step"
            @click="$router.go(-1)"
          >
            {{ $t("button.previous") }}
          </button>
        </div>
        <div v-if="hasAtLeastOneProductLine" class="text-right">
          <button
            data-cy="next-button"
            class="text-uppercase font-size-14 font-roboto-medium nav-button"
            @click="onNextButtonClick"
          >
            {{ $t("next") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import OfferSheet from "@/components/offers/OfferSheet"
import OfferSheetFlitter from "@/components/offers/OfferSheetFlitter"
import ModalWarning from "@/components/modal/ModalWarning"
import ModalSharingStatus from "@/components/modal/ModalSharingStatus"

export default {
  name: "Products",
  components: {
    OfferSheet,
    OfferSheetFlitter,
    ModalWarning,
    ModalSharingStatus,
  },
  props: {
    saleId: { type: [Number, String], required: true },
    sharedToken: { type: String, default: "" },
  },
  data() {
    return {
      isLoading: true,
      sharingStatus: "",
      showSharingStatusModal: false,
      warningModal: {
        // for reactivity to work, the property "show" must be declared in data()
        show: false,
      },
    }
  },
  computed: {
    ...mapGetters("productLine", ["productLines", "areProductLinesLoading"]),
    ...mapGetters("offer", [
      "offers",
      "productLines",
      "productLinesIds",
      "allProductLines",
      "isOfferExternal",
    ]),
    ...mapGetters("sale", ["currentSale", "hasAtLeastOneProductLine"]),
    ...mapGetters("basket", ["hasItem"]),
    showSpinner() {
      return this.isLoading || this.areProductLinesLoading
    },
  },
  async created() {
    this.initWarningModalData()
    try {
      await this.$store.dispatch("sale/getSale", this.saleId)
      await this.$store.dispatch("offer/getEligibleOffersForSale", this.saleId)
      if (this.currentSale.product_lines.length > 0) {
        this.init_data()
      }
      const product_lines = this.allProductLines()
      await this.$store.dispatch("productLine/saveProductLinesForSale", {
        productLines: product_lines,
        saleId: this.saleId,
      })
      await this.computePricing()
    } catch (e) {
      console.warn("Error", e)
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    initWarningModalData() {
      this.warningModal.show = false
      this.warningModal.title = this.$t("offers.modals.pricingFailure.title")
      this.warningModal.reason = ""
      this.warningModal.detailedReason = ""
      this.warningModal.advice = this.$t("offers.modals.advice")
    },
    async addToBasket(offer) {
      await this.$store.dispatch("sale/addProductLinesToSale", offer.id)
      await this.$store.dispatch("basket/add", offer.id)
    },
    async delFromBasket(offer) {
      await this.$store.dispatch("sale/removeProductLinesFromSale", offer.id)
      await this.$store.dispatch("basket/del", offer.id)
    },
    init_data() {
      this.currentSale.product_lines.forEach((productLine) => {
        for (const offer of this.offers) {
          this.productLines(offer.id).forEach((productLineInOffer) => {
            if (productLineInOffer.id === productLine.id) {
              if (!this.hasItem(offer.id)) {
                this.$store.dispatch("basket/add", offer.id)
              }
            }
          })
        }
      })
    },
    async onNextButtonClick() {
      if (this.sharedToken !== "") {
        await this.$router.push({
          name: "shared_customer",
          params: { sharedToken: this.sharedToken, saleId: this.saleId },
        })
      } else {
        await this.$router.push({
          name: "customer",
          params: { saleId: this.saleId },
        })
      }
    },
    async computePricing(specificOffer = null) {
      let pricingErrors = {}

      // If compute pricing is requested for a specific offer, no need to compute pricing for all offers
      // => define which offers need to be managed
      let offers = []
      offers = specificOffer !== null ? [specificOffer] : this.offers

      for (const offer of offers) {
        if (!this.isOfferExternal(offer.id)) {
          try {
            await this.$store.dispatch(
              "sale/retrieveProductLinesPricing",
              this.productLines(offer.id)
            )
          } catch (e) {
            pricingErrors[offer.name] = e
          }
        }
      }

      // If errors occurred during pricing computation
      if (Object.keys(pricingErrors).length !== 0) {
        const isPricingAttemptsQuotaExceeded = Object.values(pricingErrors).some(
          (offerError) => offerError.data === "pricing_attempts_quota_exceeded"
        )
        if (isPricingAttemptsQuotaExceeded) {
          this.sharingStatus = "pricing_attempts_quota_exceeded"
          this.showSharingStatusModal = true
        } else {
          this.displayPricingErrors(pricingErrors)
        }
      }
    },
    displayPricingErrors(errors) {
      this.initWarningModalData()
      let detailedReason = []
      Object.entries(errors).forEach(([offerName, offerError]) => {
        // TODO improve this if if if ?!
        if (offerError.status === 422) {
          if (process.env.NODE_ENV !== "production") {
            if (offerError.data.hasOwnProperty("product_line_bundles")) {
              if (typeof offerError.data.product_line_bundles === "object") {
                detailedReason.push(
                  `${offerName}:\n ${this.buildProductLineBundlesErrors(
                    offerError.data.product_line_bundles
                  )}`
                )
              } else {
                // It's a string
                detailedReason.push(
                  `${offerName}:\n product_line_bundles: ${offerError.data.product_line_bundles}`
                )
              }
            } else {
              detailedReason.push(`${offerName}:\n ${offerError.data}`)
            }
          }
        } else {
          // TODO check error 400 or 500
          detailedReason.push(`${offerName}:\n ${offerError.data}`)
        }
      })

      this.warningModal.reason =
        this.$t("offers.modals.pricingFailure.reason") +
        ` :<br><strong>${this.currentSale.vehicle.version}</strong>`
      if (detailedReason.length !== 0) {
        this.warningModal.detailedReason = detailedReason.join("\n\n")
      }

      this.warningModal.advice = this.$t("offers.modals.advice")
      this.warningModal.show = true
    },
    buildProductLineBundlesErrors(productLineBundlesErrors) {
      // This is complicated but necessary to display all kinds of errors in a readable way
      // (maybe there is a better/faster way to do that)

      // List of product lines' errors
      // [{... errors of 1st pl ...}, {... errors of 2nd pl ...}]
      const errorsList = Object.values(productLineBundlesErrors)

      let result = errorsList.reduce((result, plErrors) => {
        // Format product line's errors
        // ["crit_name: crit_error", "crit_name: crit_error", "crit_name: crit_error", ...]
        let formattedPlErrors = Object.entries(plErrors).reduce(
          (formattedPlErrors, [crit_name, crit_error]) => {
            formattedPlErrors.push(`${crit_name}: ${crit_error}`)
            return formattedPlErrors
          },
          []
        )
        // convert into a string, while separating errors with '\n'
        result.push(formattedPlErrors.join("\n"))
        return result
      }, [])

      // finally convert into a string, while separating product lines' errors with '\n'
      return result.join("\n\n")
    },
    onCloseWarningModal() {
      this.warningModal.show = false
    },
  },
}
</script>
