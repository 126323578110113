<template>
  <div>
    <div class="list-group list-group-flush">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="hasSubTabs ? 'mb-1' : 'mb-5'"
      >
        <a
          v-if="isSpecificTab(tab)"
          :id="index"
          href="#"
          :class="getTabClass(tab, index)"
          :data-cy="`${tab.type}-${tab.item.id}-vertical-tab`"
          class="list-group-item list-group-item-action py-2"
          @click.prevent="onTabClick(tab, index)"
        >
          {{ tab.label }}
        </a>

        <p
          v-else-if="$route.name !== 'programDescription'"
          class="list-group-item list-group-item-action py-2 vertical-sub-tab"
          :data-cy="`${tab.type}-${tab.item.id}-vertical-tab`"
        >
          <span :class="getTabClass(tab, index)">{{ tab.label }}</span>
          <a
            v-for="(pl, i) in tab.productLines"
            :id="pl.id"
            :key="pl.id"
            href="#"
            class="list-group-pl"
            :class="getPlTabClass(pl.id)"
            :data-cy="`product_line-${pl.id}-vertical-tab`"
            @click.prevent="onTabClick(tab, index, pl.id)"
          >
            {{ $t("verticaltabs.case") + " " + (i + 1) }}
          </a>
        </p>
        <p
          v-else
          class="list-group-item list-group-item-action py-2 vertical-sub-tab"
          :data-cy="`${tab.type}-${tab.item.id}-vertical-tab`"
        >
          {{ tab.label }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "VerticalTab",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeTabIndex: 0,
      activeProductLine: 0,
    }
  },
  computed: {
    ...mapGetters("offer", ["getOfferByProgramProductId"]),
    hasSubTabs() {
      return this.tabs.some((tab) => tab.isSubTab === true)
    },
  },
  methods: {
    getTabClass(tab, index) {
      let tabClass = []
      tabClass.push(this.isTabActive(index) ? "activeTab" : "")
      tabClass.push(tab.isSubTab ? "vertical-sub-tab" : "vertical-tab")
      return tabClass
    },
    getPlTabClass(productLineId) {
      return this.activeProductLine === productLineId ? "activeTab" : ""
    },
    isTabActive(index) {
      return index === this.activeTabIndex
    },
    isSpecificTab(tab) {
      return (
        tab.type === "offer" ||
        tab.type === "program" ||
        (tab.type === "program_product" && tab.productLines.length === 0)
      )
    },
    onTabClick(tab, index, plId) {
      this.activeTabIndex = index
      this.activeProductLine = plId
      this.$emit("tab-change", index, plId)
    },
  },
}
</script>

<style scoped lang="scss">
.vertical-tab {
  border-left: 6px solid #fff;
  border-bottom: 0;
  border-right: 0;
  border-top: 0;
  font-size: 20px;
  text-align: right;
  background-color: $blue-c50;
}

.vertical-tab.list-group-item-action:hover,
.vertical-tab.list-group-item-action:focus {
  background-color: $blue-c50;
}

.vertical-sub-tab.list-group-item-action:hover,
.vertical-sub-tab.list-group-item-action:focus {
  background-color: transparent;
}

a:hover,
.activeTab {
  color: $caareablue;
  font-family: "Gilroy SemiBold", sans-serif;
}

a {
  font-family: "Gilroy", sans-serif;
}

p {
  margin: 0;
}

.vertical-sub-tab {
  border: 0;
  text-align: right;
  font-size: 18px;
  color: $caareablue;
  letter-spacing: 0.25px;
}
.list-group-flush {
  height: 100%;
  border-right: 1px solid $blue-c50;
}
.list-group-pl {
  display: block;
}
</style>
