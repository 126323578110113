var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('ModalBootstrap',{attrs:{"title":_vm.title,"show-only-confirm-button":false,"modal-size":"large","data-cy":"vehicle-info-modal"},on:{"close":_vm.onClose},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"mx-2 d-flex flex-column justify-content-center"},[_c('span',{staticClass:"font-size-12 mb-2 d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.subTitle)+" ")]),_c('div',{staticClass:"w-100 mt-4"},[_c('FormRowSelect',{staticClass:"vehicle-info",attrs:{"label":_vm.$t('vehicle_info.vehicle_type'),"label-class":[
            'text-right',
            'col-sm-4',
            'align-self-center',
            'font-size-20' ],"control-class":['col-sm-6', 'align-self-center'],"selected-option":_vm.form.vehicleType,"select-options":_vm.getTagsFromEligibleOffers,"name":"vehicle-type","label-select-attr":"key","multiple":false,"errors":{}},on:{"update:selectedOption":function($event){return _vm.$set(_vm.form, "vehicleType", $event)},"update:selected-option":function($event){return _vm.$set(_vm.form, "vehicleType", $event)}}}),(_vm.form.vehicleType !== '' && !_vm.isRetailPriceProvidedByVelastic)?_c('FormRowInput',{staticClass:"vehicle-info",attrs:{"label":_vm.$t('vehicle_info.retail_price.label'),"label-class":[
            'text-right',
            'col-sm-4',
            'align-self-center',
            'font-size-20' ],"control-class":['col-sm-6', 'align-self-center'],"name":"price","placeholder":_vm.$t('vehicle_info.retail_price.placeholder'),"errors":{}},model:{value:(_vm.form.retailPrice),callback:function ($$v) {_vm.$set(_vm.form, "retailPrice", $$v)},expression:"form.retailPrice"}}):_vm._e(),(_vm.form.vehicleType !== '' && _vm.form.vehicleType !== 'VNPJ')?_c('div',{staticClass:"row vehicle-info"},[_c('label',{staticClass:"text-right align-self-center col-sm-4"},[_vm._v(" "+_vm._s(_vm.$t("vehicle_info.first_release_date.label"))+" ")]),_c('datepicker',{staticClass:"col-sm-6 align-self-center",attrs:{"id":"first_release_date","data-cy":"first-release-date","input-class":"form-control","placeholder":_vm.$t('vehicle_info.first_release_date.placeholder'),"language":_vm.language,"monday-first":_vm.isMondayFirst,"disabled-dates":{ from: new Date() }},model:{value:(_vm.form.firstReleaseDate),callback:function ($$v) {_vm.$set(_vm.form, "firstReleaseDate", $$v)},expression:"form.firstReleaseDate"}})],1):_vm._e(),(_vm.form.vehicleType !== '' && _vm.form.vehicleType !== 'VNPJ')?_c('FormRowInput',{staticClass:"vehicle-info",attrs:{"label":_vm.$t('vehicle_info.mileage'),"label-class":[
            'text-right',
            'col-sm-4',
            'align-self-center',
            'font-size-20' ],"control-class":['col-sm-6', 'align-self-center'],"name":"kilometers","placeholder":"5400","errors":{}},model:{value:(_vm.form.kilometers),callback:function ($$v) {_vm.$set(_vm.form, "kilometers", $$v)},expression:"form.kilometers"}}):_vm._e()],1),_c('div',{staticClass:"row justify-content-center mt-3"},[_c('div',{staticClass:"col-md-6 px-2 text-center"},[_c('button',{staticClass:"btn btn-secondary w-75 px-1 mb-1",attrs:{"data-cy":"cancel-vehicle-button"},on:{"click":function($event){$event.preventDefault();return _vm.onClose($event)}}},[_vm._v(" "+_vm._s(_vm.$t("modal_cancel"))+" ")])]),_c('div',{staticClass:"col-md-6 px-2 text-center"},[_c('button',{staticClass:"btn btn-primary w-75 px-1 mb-1",attrs:{"data-cy":"confirm-vehicle-info-button","disabled":!_vm.areInputsValid},on:{"click":function($event){$event.preventDefault();return _vm.onValidateVehicle($event)}}},[_vm._v(" "+_vm._s(_vm.$t("modal_consult_offers"))+" ")])])])])]},proxy:true}],null,false,208054033)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }