<template>
  <div class="caarea-homepage">
    <div class="caarea-homepage__content">
      <div class="caarea-homepage__title">
        <h1 class="caarea-homepage__title--h1 font-effra-bold" data-cy="welcome">
          <span class="icon-cart"></span>
          {{ $t("home.welcome") }}
        </h1>
        <h2 class="caarea-homepage__title--h2 font-effra-light" data-cy="caarea-space">
          {{ $t("home.caarea_space") }}
        </h2>
      </div>
      <div class="caarea-homepage__buttons-list">
        <router-link
          :to="{
            name: programsRoute,
          }"
          class="caarea-homepage__router-link"
        >
          <LargeButton
            icon="icon-eye"
            :subhead="$t('home.button.program.line1')"
            :text="$t('home.button.program.line2')"
            :is-disabled="isDisabled('program')"
          />
        </router-link>

        <router-link
          :to="{
            name: vehicleRoute,
          }"
          class="caarea-homepage__router-link"
        >
          <LargeButton
            icon="icon-pencil4"
            :subhead="$t('home.button.subscribe.line1')"
            :text="$t('home.button.subscribe.line2')"
            :is-disabled="isDisabled('subscribe')"
          />
        </router-link>
        <component
          :is="isLoggedInSharingMode ? 'div' : 'router-link'"
          :to="{
            name: 'sales',
          }"
          class="caarea-homepage__router-link"
        >
          <LargeButton
            icon="icon-graph"
            :subhead="$t('home.button.achievement.line1')"
            :text="$t('home.button.achievement.line2')"
            :is-disabled="isDisabled('achievement')"
          />
        </component>
        <LargeButton
          icon="icon-coin-dollar"
          :subhead="$t('home.button.incentive.line1')"
          :text="$t('home.button.incentive.line2')"
          :is-disabled="isDisabled('incentive')"
        />
      </div>
    </div>
    <transition appear name="slide">
      <div
        v-if="!user.profile.accepted_cookies"
        id="overlay"
        data-cy="cookies-overlay"
        class="text-white"
      >
        <h1 class="pl-4 pt-3" data-cy="cookies-title">
          {{ $t("home.cookies.title") }}
        </h1>
        <div class="pt-2 px-5">
          <p class="mr-3" data-cy="cookies-description">
            {{ $t("home.cookies.description") }}
          </p>
          <div class="row">
            <div class="col-10">
              <p data-cy="cookies-description-2">
                {{ $t("home.cookies.description2") }}
              </p>
            </div>
            <div class="col-2 pt-2 pr-5 text-right">
              <button
                type="button"
                class="btn btn-primary"
                data-toggle="modal"
                data-target=".bd-example-modal-lg"
                data-cy="accept-cookies"
                @click="onClickAcceptCookies"
              >
                {{ $t("button.accept") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import LargeButton from "@/components/button/LargeButtonWithIcon"

export default {
  name: "Home",
  components: {
    LargeButton,
  },
  data() {
    return {
      user: null,
      disabledSectionsInSharingMode: ["achievement", "incentive"],
      disabledSectionsInNormalMode: ["incentive"],
    }
  },
  computed: {
    ...mapGetters("auth", [
      "getCurrentUser",
      "isLoggedInSharingMode",
      "getSharingToken",
      "getUserLang",
    ]),
    programsRoute() {
      return this.isLoggedInSharingMode ? "shared_programs" : "programs"
    },
    vehicleRoute() {
      return this.isLoggedInSharingMode ? "shared_vehicle" : "vehicle"
    },
  },
  async created() {
    this.user = this.getCurrentUser
    if (!this.isLoggedInSharingMode) {
      await this.$store.dispatch("config/setUserLang")
    }

    const to = this.$router.resolve({ params: { lang: this.getUserLang } })
    if (this.$route.params.lang !== to.location.params.lang) {
      await this.$router.push(to.location)
    }
    if (this.isLoggedInSharingMode) {
      await this.$store.dispatch("program/retrieveSharedProgram", this.getSharingToken)
    }
  },
  methods: {
    async onClickAcceptCookies() {
      await this.$store.dispatch("auth/acceptCookies", this.user.id)
      this.user = this.getCurrentUser
    },
    isDisabled(sectionName) {
      return this.isLoggedInSharingMode
        ? this.disabledSectionsInSharingMode.includes(sectionName)
        : this.disabledSectionsInNormalMode.includes(sectionName)
    },
  },
}
</script>

<style lang="scss" scoped>
.caarea-homepage {
  height: 100vh;
  color: white;
  background-image: url("https://res.cloudinary.com/caarea/image/upload/v1617955698/cs/assets/splash-screen-caarea_i7zhaa.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  &__content {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  &__title {
    position: relative;
    top: -75px;
    margin: auto;
    &--h1 {
      margin-bottom: 0;
      color: white;
      font-size: 50px;
      text-transform: uppercase;
      line-height: 10px;

      & .icon-cart {
        font-size: 45px;
      }
    }

    &--h2 {
      color: white;
      font-size: 35px;
    }
  }

  &__buttons-list {
    margin: 80px auto;
  }

  &__router-link:hover {
    text-decoration: none;
  }
}

#overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 25%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(106, 102, 94, 0.7);
  z-index: 2;
}
.slide-enter-active {
  transition: all 1.2s ease-in-out;
}
.slide-leave-active {
  transition: all 1.2s ease-out;
}
.wrapper {
  height: 100vh;
}
</style>
