<template>
  <div class="compte-dossier">
    <span class="small text-uppercase">
      {{ $t("my_sales_files") }}
    </span>
    <div class="row no-gutters compte-head">
      <div class="col">
        <div class="row">
          <div class="col-3 col-lg-2">
            <span class="small medium-gray">
              {{ $t("date") }}
            </span>
          </div>
          <div class="col-4 col-lg-3">
            <span class="small medium-gray">
              {{ $t("beneficiary") }}
            </span>
          </div>
          <div class="col-5 col-lg-7">
            <span class="small medium-gray">
              {{ $t("vehicle").toLowerCase() }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-auto">&nbsp;</div>
    </div>
    <ul>
      <li>
        <div class="row align-items-center">
          <div class="col">
            <div class="row">
              <div class="col-3 col-lg-2">
                <span>23.02.2020</span>
              </div>
              <div class="col-4 col-lg-3">
                <strong>Pierre Jacquemin</strong>
              </div>
              <div class="col-5 col-lg-7">
                <strong>TOYOTA Auris</strong>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary btn-details">
                  <img src="/img/icons/details.png" alt="" />
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/trash.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="compte-details">
          <div class="row">
            <div class="col-5">
              <span class="small medium-gray">Informations clients</span>
              <div class="details-infos">
                34 Boulevard Beaumarchais
                <br />
                75011 / PARIS
                <br />
                01 75 46 23 56 / 07 89 56 23 21
                <br />
                john.doe@gmail.com
              </div>
            </div>
            <div class="col-7">
              <div class="row">
                <div class="col-6">
                  <span class="small medium-gray">Informations véhicule</span>
                  <div class="details-infos">
                    (180) (Prod GB)
                    <br />
                    Wagon 1.8
                    <br />
                    VVT-i HSD 16V CVT Hybride 99
                    <br />
                    cv Boîte auto
                  </div>
                </div>
                <div class="col-6">
                  <span class="small medium-gray">&nbsp;</span>
                  <div class="details-infos">
                    Réf. stock :
                    <strong>753215-56G</strong>
                    <br />
                    <br />
                    Immatriculation :
                    <strong>DA-964-HK</strong>
                    <br />
                    VIN :
                    <strong>VFT32564886542997</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <span class="small medium-gray">Produits souscrits</span>
          <ul>
            <li>
              <div class="row">
                <div class="col-5">
                  <span class="text-uppercase">Body protection</span>
                </div>
                <div class="col-7">
                  10,75 {{ getCurrencySymbol }}
                  <span class="extra-small">TTC / mois</span>
                </div>
              </div>
            </li>
            <li>
              <div class="row">
                <div class="col-5">
                  <span class="text-uppercase">Extension de garantie</span>
                </div>
                <div class="col-7">
                  15,95 {{ getCurrencySymbol }}
                  <span class="extra-small">TTC / mois</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <div class="row align-items-center">
          <div class="col">
            <div class="row">
              <div class="col-3 col-lg-2">
                <span>12.02.2020</span>
              </div>
              <div class="col-4 col-lg-3">
                <strong>Jean-Marc Boutet</strong>
              </div>
              <div class="col-5 col-lg-7">
                <strong>AUDI Q2</strong>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/details.png" alt="" />
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/trash.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row align-items-center">
          <div class="col">
            <div class="row">
              <div class="col-3 col-lg-2">
                <span>22.01.2020</span>
              </div>
              <div class="col-4 col-lg-3">
                <strong>Catherine Trelle</strong>
              </div>
              <div class="col-5 col-lg-7">
                <strong>VOLKSWAGEN T-ROC</strong>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/details.png" alt="" />
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/trash.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row align-items-center">
          <div class="col">
            <div class="row">
              <div class="col-3 col-lg-2">
                <span>21.01.2020</span>
              </div>
              <div class="col-4 col-lg-3">
                <strong>Emilie Lemans</strong>
              </div>
              <div class="col-5 col-lg-7">
                <strong>TOYOTA YARIS III</strong>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/details.png" alt="" />
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/trash.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row align-items-center">
          <div class="col">
            <div class="row">
              <div class="col-3 col-lg-2">
                <span>18.01.2020</span>
              </div>
              <div class="col-4 col-lg-3">
                <strong>Julien Mareau</strong>
              </div>
              <div class="col-5 col-lg-7">
                <strong>AUDI A3 III</strong>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/details.png" alt="" />
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/trash.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import CurrencyService from "@/services/business/CurrencyService"

export default {
  name: "Sales",
  computed: {
    getCurrencySymbol() {
      return CurrencyService.getCurrencySymbol()
    },
  },
}
</script>
