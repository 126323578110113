<template>
  <div class="page-vehicle-search">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <button
            class="btn btn-primary btn-small rounded font-size-14 font-roboto-medium"
            @click="onBackBtnClick"
          >
            {{ $t("vehicleChoice.back") }}
          </button>
          <div class="bg-white mt-4" data-cy="presales-vehicle-search-modal">
            <spinner v-if="isLoading" class="mt-5"></spinner>
            <div v-else class="body-search">
              <div class="filters-list">
                <div class="title">
                  {{ $t("vehicleChoice.title") }}
                </div>
                <div>
                  <FormRowSelect
                    :label-inline="false"
                    :label="$t('vehicleChoice.vehicle_type')"
                    label-select-attr="label"
                    name="vehicle_type"
                    :select-options="getVehicleTypesSelectableValues"
                    :selected-option.sync="filters.vehicle_type"
                    :disabled="getIsFilterDisabled('vehicle_type')"
                    @update:selected-option="onUpdateVehicleTypes"
                  />
                  <div v-for="(_, filterName) in filters" :key="filterName">
                    <div v-if="filterName === 'year'">
                      <div>
                        <FormRowSelect
                          :label-inline="false"
                          :label="
                            $t(`vehicleChoice.${filterName}`) +
                            ' - ' +
                            $t(`vehicleChoice.from`)
                          "
                          label-select-attr="label"
                          :name="filterName"
                          :multiple="false"
                          :allow-empty="true"
                          :select-options="getSelectedOptions(filterName)"
                          :selected-option.sync="dateRange.from"
                          :disabled="getIsFilterDisabled(filterName)"
                          :placeholder="getFilterSelectPlaceholder(filterName)"
                          @update:selected-option="setDateFrom"
                        />
                      </div>
                      <div v-if="inRangeTo">
                        <FormRowSelect
                          :label-inline="false"
                          :label="
                            $t(`vehicleChoice.${filterName}`) +
                            ' - ' +
                            $t(`vehicleChoice.until`)
                          "
                          label-select-attr="label"
                          :name="filterName"
                          :multiple="false"
                          :allow-empty="true"
                          :select-options="inRangeTo"
                          :selected-option.sync="dateRange.to"
                          :disabled="getIsFilterDisabled(filterName)"
                          :placeholder="getFilterSelectPlaceholder(filterName)"
                          @update:selected-option="filterYears"
                        />
                      </div>
                    </div>
                    <div v-else-if="filterName !== 'vehicle_type'">
                      <div>
                        <FormRowSelect
                          :label-inline="false"
                          :label="$t(`vehicleChoice.${filterName}`)"
                          label-select-attr="label"
                          :name="filterName"
                          :multiple="true"
                          :allow-empty="true"
                          :select-options="getSelectedOptions(filterName)"
                          :selected-option.sync="filters[filterName]"
                          :disabled="getIsFilterDisabled(filterName)"
                          :placeholder="getFilterSelectPlaceholder(filterName)"
                          @update:selected-option="onUpdateFilter(filterName)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-light-gray"
                  :disabled="isResetButtonDisabled"
                  @click="resetFilters()"
                >
                  <i class="pr-2 icon-sync" aria-hidden="true"></i>
                  {{ $t("button.reset") }}
                </button>
              </div>
              <spinner v-if="areVehiclesUpdating" class="mt-5"></spinner>
              <div v-else class="vehicles-list">
                <div v-if="hasVehicles">
                  <VehiclesList
                    :show-optional-dynamic-inputs="showOptionalDynamicInputs"
                    :vehicle-type-options="vehicleTypeOptions"
                    @dynamic-inputs-validated="onDynamicInputsValidated"
                  ></VehiclesList>
                </div>
                <div v-else-if="areAllFiltersEmpty" class="message">
                  <i class="icon-magnifier"></i>
                  <span>{{ $t("vehicleChoice.no_criteria_selected") }}</span>
                </div>
                <div v-else class="message">
                  <i class="icon-question"></i>
                  <span>{{ $t("vehicleChoice.no_vehicle_found") }}</span>
                </div>
                <div
                  v-if="hasPagination && !getSelectedVehicle"
                  class="pagination"
                  data-cy="vehicle-pagination"
                >
                  <div class="label-page">{{ $t("vehicleChoice.page") }}</div>
                  <div
                    class="page"
                    :class="{ current: currentPage === 1 }"
                    @click="goToPage(1)"
                  >
                    1
                  </div>
                  <div
                    class="page"
                    :class="{ current: currentPage === 2 }"
                    data-cy="vehicle-pagination-2"
                    @click="goToPage(2)"
                  >
                    2
                  </div>
                  <div
                    v-if="hasAThirdPagePagination"
                    class="page"
                    :class="{ current: currentPage === 3 }"
                    @click="goToPage(3)"
                  >
                    3
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import VehicleSearchService from "@/services/business/VehicleSearchService"
import i18n from "@/i18n"
import VehiclesList from "../../components/subscription/VehiclesList"
import VehicleSearchMixin from "../../components/mixins/VehicleSearchMixin"
import ObjectService from "@/services/technical/ObjectService"

export default {
  name: "ManualSearch",
  components: { VehiclesList },
  mixins: [VehicleSearchMixin],
  props: {
    sharedToken: { type: String, default: "" },
  },
  data() {
    return {
      inRangeTo: null,
      dateRange: {
        from: null,
        to: null,
      },
      isLoading: true,
      currentPage: 1,
      filters: {
        vehicle_type: "",
        make: [],
        model: [],
        trim_level: [],
        year: [],
        power: [],
        cylinders: [],
        engine_type: [],
      },
      areVehiclesUpdating: false,
      isResetButtonDisabled: false,
    }
  },
  computed: {
    ...mapGetters("sale", ["currentSaleId"]),
    ...mapGetters("searchVehicle", [
      "getVehicleTypesSelectableValues",
      "getMakeSelectableValues",
      "getModelSelectableValues",
      "getTrimLevelSelectableValues",
      "getYearSelectableValues",
      "getPowerSelectableValues",
      "getCylindersSelectableValues",
      "getEngineTypeSelectableValues",
      "getFiltersData",
      "hasPagination",
      "hasAThirdPagePagination",
      "getFilterRank",
      "getIsFiltersDisabled",
      "getIsFilterDisabled",
      "getSelectedVehicle",
      "getVehicleTypeFilterValue",
      "hasVehicles",
    ]),

    areAllFiltersEmpty() {
      return (
        Object.keys(this.filters).filter(
          (filter) => filter !== "vehicle_type" && this.filters[filter].length !== 0
        ).length === 0
      )
    },
  },
  async created() {
    this.isLoading = true
    this.isResetButtonDisabled = true
    await this.$store.dispatch("searchVehicle/initFiltersAndSelectableValues")
    await this.$store.dispatch("searchVehicle/updateVehicleTypesFilter")
    await this.$store.dispatch("searchVehicle/resetSelectedVehicle")
    await this.setFiltersIfOnlyOneSelectableValue()
    this.isLoading = false
  },
  methods: {
    setDateFrom() {
      const currentYear = new Date().getFullYear().toString()

      if (!this.dateRange.to) {
        this.dateRange.to = currentYear
      }
      if (this.dateRange.to < this.dateRange.from) {
        this.dateRange.to = this.dateRange.from
      }

      this.inRangeTo = ObjectService.getRange(
        this.dateRange.from,
        currentYear,
        this.getSelectedOptions("year")
      )
      this.filterYears()
    },
    filterYears() {
      const filteredYears = ObjectService.getRange(
        this.dateRange.from,
        this.dateRange.to,
        this.getSelectedOptions("year")
      )
      this.filters.year = Object.values(filteredYears)
      this.onUpdateFilter("year")
    },
    getFilterSelectPlaceholder(filterName) {
      if (
        (filterName === "model" || filterName === "trim_level") &&
        this.getIsFilterDisabled(filterName)
      )
        return this.$t(`vehicleSearch.manual_search.disabled.${filterName}`)
      return this.$t(`vehicleSearch.manual_search.empty.${filterName}`)
    },
    onBackBtnClick() {
      if (this.sharedToken) {
        this.$router.push({ name: "shared_vehicle" })
      } else {
        this.$router.push({ name: "vehicle" })
      }
      this.$store.dispatch("searchVehicle/resetSelectedVehicle")
    },
    async onUpdateFilter(filterName) {
      this.areVehiclesUpdating = true
      this.isResetButtonDisabled = false
      this.currentPage = 1
      await this.$store.dispatch("searchVehicle/resetSelectedVehicle")
      let filterValues = []
      if (filterName === "engine_type") {
        const selectableValues = this.getEngineTypeSelectableValues
        this.filters[filterName].forEach((value) => {
          if (value === i18n.t("vehicleChoice.hybrid")) {
            filterValues = filterValues.concat(VehicleSearchService.hybridTypes)
          } else {
            filterValues.push(value)
          }
        })
      } else {
        filterValues = this.filters[filterName]
      }
      await this.$store.dispatch("searchVehicle/updateFilter", {
        filter: filterName,
        values: filterValues,
      })
      await this.$store.dispatch(
        "searchVehicle/removeFilterValuesIfNotInSelectableValues",
        {
          rank: this.getFilterRank(filterName),
        }
      )
      await this.setFiltersIfOnlyOneSelectableValue()
      await this.$store.dispatch("searchVehicle/resetVehicles")
      if (!this.areAllFiltersEmpty) {
        await this.$store.dispatch("searchVehicle/searchVehicles")
      }
      this.areVehiclesUpdating = false
    },
    async onUpdateVehicleTypes() {
      this.areVehiclesUpdating = true
      await this.$store.dispatch("searchVehicle/updateVehicleTypesFilter", {
        values: this.filters.vehicle_type,
      })
      await this.$store.dispatch(
        "searchVehicle/removeFilterValuesIfNotInSelectableValues",
        { rank: this.getFilterRank("vehicle_type") }
      )
      await this.$store.dispatch("searchVehicle/resetVehicles")
      await this.setFiltersIfOnlyOneSelectableValue()
      this.areVehiclesUpdating = false
    },
    async goToPage(pageNumber) {
      this.areVehiclesUpdating = true
      await this.$store.dispatch("searchVehicle/displaySpecificPageVehicles", {
        pageNumber: pageNumber,
      })
      this.currentPage = pageNumber
      this.areVehiclesUpdating = false
    },
    async resetFilters() {
      this.dateRange = { from: null, to: null }
      this.inRangeTo = null
      this.isResetButtonDisabled = true
      this.areVehiclesUpdating = true
      this.currentPage = 1
      await this.$store.dispatch("searchVehicle/initFiltersAndSelectableValues")
      await this.$store.dispatch("searchVehicle/updateVehicleTypesFilter")
      await this.$store.dispatch("searchVehicle/resetSelectedVehicle")
      this.filters = this.getFiltersData
      this.areVehiclesUpdating = false
    },
    async setFiltersIfOnlyOneSelectableValue() {
      this.filters = this.getFiltersData
      const isFiltersDisabled = this.getIsFiltersDisabled
      Object.keys(this.filters).forEach((filterName) => {
        if (filterName !== "vehicle_type") {
          const selectedOptions = this.getSelectedOptions(filterName)
          if (
            !isFiltersDisabled[filterName] &&
            Object.keys(selectedOptions).length === 1
          ) {
            this.filters[filterName] = Object.keys(selectedOptions)
          }
        }
      })
    },
    getTitledFilterName(filterName) {
      return filterName
        .split("_")
        .map((word) => word[0].toUpperCase() + word.substring(1))
        .join("")
    },
    getSelectedOptions(filterName) {
      const titledFilterName = this.getTitledFilterName(filterName)
      return eval(`this.get${titledFilterName}SelectableValues`)
    },
  },
}
</script>

<style lang="scss" scoped>
.body-search {
  display: grid;
  grid-template-columns: 22% 78%;
  border-left: 4px solid $body-color;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  .filters-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 20px;
    background-color: $blue-c50;
    button {
      min-width: auto;
      width: 100%;
      margin-top: 16px;
      padding: 8px 12px;
      i {
        font-weight: 600;
      }
    }
    .title {
      font-family: $font-family-title;
      font-size: 20px;
      line-height: 31px;
    }
    label {
      text-transform: uppercase;
    }
  }
  .vehicles-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 10px 16px 10px;
    .message {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      margin: auto;
      width: 75%;
      height: 100%;
      i {
        font-size: rem-calc(64);
        font-weight: 900;
      }
      span {
        font-size: rem-calc(32);
        line-height: 42px;
        letter-spacing: 0.25px;
        text-align: center;
      }
    }
  }
  .pagination {
    gap: 8px;
    padding: 4px 16px;
    background: rgba($bleuc50, 0.3);
    font-family: "Effra";
    font-size: 13px;
    .label-page {
      letter-spacing: 0.25px;
    }
    .page {
      color: #9e9fbb;
      cursor: pointer;
      &:hover {
        color: inherit;
      }
      &.current {
        color: inherit;
        cursor: inherit;
      }
    }
  }
}

::v-deep .v-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
