<template>
  <div :class="['product-line-edition__criteria__benef', ...componentClass]">
    <div class="benef-criteria">
      <FormRowSelect
        v-for="criterion in beneficiaryCriteriaNames"
        :key="criterion"
        class="font-size-14 white_space_nowrap"
        :label-inline="false"
        :label="$t('criteria.' + criterion)"
        :for-id="`program-context-${criterion}`"
        :name="`${productLineId}-${criterion}`"
        :selected-option.sync="form.beneficiaryCriteria[criterion]"
        label-select-attr="label"
        :select-options="getCoverageOptions(productLineId, criterion)"
        :errors="{}"
        :disabled="disabled"
        @update:selected-option="onUpdateCriterion(criterion)"
      ></FormRowSelect>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import BeneficiaryCriteriaService from "@/services/business/BeneficiaryCriteriaService"

export default {
  name: "CoverageDurationDistance",
  props: {
    programProductId: {
      type: [Number, String],
      required: true,
    },
    productLineId: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
    componentClass: {
      type: Array,
      default: () => [""],
    },
  },
  data() {
    return {
      form: {
        beneficiaryCriteria: {},
        coverageCouples: null,
      },
    }
  },
  computed: {
    ...mapGetters("productLine", [
      "getCoverageCouplesById",
      "hasCoverageCouple",
      "productLineCoefficients",
      "getConfig",
      "getUserSelectableValues",
      "beneficiaryCriteriaDefaultValues",
      "getBeneficiaryCriteriaValues",
    ]),
    beneficiaryCriteriaNames() {
      return this.productLineCoefficients(this.productLineId).beneficiary
    },
  },
  created() {
    this.form.beneficiaryCriteria = this.getBeneficiaryCriteriaValues(
      this.productLineId
    )
    if (
      BeneficiaryCriteriaService.hasCoverageDurationKmCouple(
        this.form.beneficiaryCriteria
      )
    ) {
      this.form.beneficiaryCriteria = BeneficiaryCriteriaService.getValidCoverageCouple(
        this.form.beneficiaryCriteria,
        this.beneficiaryCriteriaDefaultValues(this.productLineId),
        this.getUserSelectableValues(this.productLineId)
      )
    } else {
      this.form.beneficiaryCriteria = BeneficiaryCriteriaService.getValidCoverageDuration(
        this.form.beneficiaryCriteria,
        this.beneficiaryCriteriaDefaultValues(this.productLineId),
        this.getUserSelectableValues(this.productLineId)
      )
    }
    if (this.hasCoverageCouple(this.productLineId)) {
      this.coverageCouples = this.getCoverageCouplesById(this.productLineId)
    }
  },
  methods: {
    getCoverageOptions(productLineId, criterion) {
      const critNames = BeneficiaryCriteriaService.getCoverageCritNames(
        this.form.beneficiaryCriteria
      )
      const plConfig = this.getConfig(productLineId)
      let durationOptions = null
      let kmOptions = null
      if (
        BeneficiaryCriteriaService.hasCoverageDurationKmCouple(
          this.form.beneficiaryCriteria
        )
      ) {
        durationOptions = BeneficiaryCriteriaService.getCoverageDurationOptions(
          this.form.beneficiaryCriteria,
          plConfig.user_selectable_values
        )
        kmOptions = BeneficiaryCriteriaService.getCoverageKmOptionsForDuration(
          plConfig.user_selectable_values,
          this.form.beneficiaryCriteria[critNames.duration]
        )
      } else {
        durationOptions = BeneficiaryCriteriaService.getCoverageDurationOptions(
          this.form.beneficiaryCriteria,
          plConfig.user_selectable_values
        )
      }
      return criterion.startsWith("coverage_duration") ? durationOptions : kmOptions
    },
    async onUpdateCriterion(criterion) {
      if (
        criterion.startsWith("coverage_duration") &&
        BeneficiaryCriteriaService.hasCoverageDurationKmCouple(
          this.form.beneficiaryCriteria
        )
      ) {
        this.form.beneficiaryCriteria = BeneficiaryCriteriaService.getValidCoverageCoupleOnDurationChange(
          this.form.beneficiaryCriteria,
          this.getUserSelectableValues(this.productLineId)
        )
      }
      const payload = {
        productLineId: this.productLineId,
        beneficiaryCriteria: { ...this.form.beneficiaryCriteria },
      }
      await this.$store.dispatch("sale/updateBeneficiaryCriteria", payload)
      this.$emit("coverage-update")
    },
  },
}
</script>

<style scoped lang="scss">
.white_space_nowrap {
  white-space: nowrap;
}

.product-line-edition__criteria__benef {
  padding: 0.5rem 1rem;
}

.benef-criteria {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  align-items: center;
  font-family: "Effra", sans-serif;

  & label {
    color: $primarybutton;
  }
}
</style>
