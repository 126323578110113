<template>
  <div class="page-content">
    <div class="page-compte">
      <div class="container">
        <account></account>
        <hr />
        <sales></sales>
        <hr />
        <stock></stock>
        <hr />
        <users></users>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import account from "@/components/admin/account"
import sales from "@/components/admin/sales"
import stock from "@/components/admin/stock"
import users from "@/components/admin/users"
export default {
  name: "Admin",
  components: {
    account,
    sales,
    stock,
    users,
  },
}
</script>
