<template>
  <div class="page-content">
    <div class="page-compte">
      <div class="container">
        <sales></sales>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import sales from "@/components/admin/sales"
export default {
  name: "Admin",
  components: {
    sales,
  },
}
</script>
