<template>
  <div class="vehicles" data-cy="vehicles-list">
    <button
      v-if="getSelectedVehicle"
      class="btn btn-primary btn-small rounded font-size-14 font-roboto-medium align-self-start"
      data-cy="vehicle-show-all"
      @click="showAllVehicles"
    >
      <i class="pr-2 icon-chevron-left" aria-hidden="true"></i>
      {{ $t("vehicleChoice.back") }}
    </button>
    <div
      v-for="vehicle in listVehicles"
      :key="vehicle.id"
      class="hover-active"
      @click="showVehicleInfos(vehicle)"
    >
      <Vehicle
        v-if="!getSelectedVehicle || isSelectedVehicle(vehicle)"
        :vehicle="vehicle"
        :show-optional-dynamic-inputs="showOptionalDynamicInputs"
        :vehicle-type-options="vehicleTypeOptions"
        :data-cy="`vehicle-${vehicle.id}`"
        @dynamic-inputs-validated="onDynamicInputsValidated"
      ></Vehicle>
    </div>
  </div>
</template>

<script>
import Vehicle from "./Vehicle"
import { mapGetters } from "vuex"

export default {
  name: "VehiclesList",
  components: { Vehicle },
  props: {
    showOptionalDynamicInputs: { type: Boolean, required: true },
    vehicleTypeOptions: { type: Object, required: true },
  },
  computed: {
    ...mapGetters("searchVehicle", [
      "getSelectedVehicle",
      "listVehicles",
      "isSelectedVehicle",
    ]),
  },
  methods: {
    async showAllVehicles() {
      await this.$store.dispatch("searchVehicle/resetSelectedVehicle")
    },
    async showVehicleInfos(vehicle) {
      if (!this.getSelectedVehicle) {
        this.$store.dispatch("searchVehicle/setSelectedVehicle", vehicle)
      }
    },
    onDynamicInputsValidated() {
      this.$emit("dynamic-inputs-validated")
    },
  },
}
</script>

<style scoped lang="scss">
.vehicles {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
