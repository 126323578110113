import AbstractService from "../AbstractService"
import DateService from "../technical/DateService"
import ArrayService from "../technical/ArrayService"
import i18n from "../../i18n"

class VehicleSearch extends AbstractService {
  DEFAULT_VNPJ_KM = 500
  DEFAULT_VNPJ_FIRST_RELEASE_DATE_DELTA_IN_DAYS = 3
  HYBRID_ENGINE_CODES = ["H", "M", "P", "R"]
  urlFilterNameMatching = {
    vehicle_type: "cvdbIsVn",
    make: "cvdbMake",
    model: "cvdbModel",
    trim_level: "cvdbTrimLevel",
    year: "cvdbYear",
    power: "cvdbPower",
    cylinders: "cvdbCylinders",
    engine_type: "cvdbEngineType",
  }
  uppercaseSelectableNameMatching = {
    vehicle_type: "VEHICLE_TYPE",
    make: "MAKES",
    model: "MODELS",
    trim_level: "TRIM_LEVELS",
    year: "YEARS",
    power: "POWERS",
    cylinders: "CYLINDERS",
    engine_type: "ENGINE_TYPES",
  }
  uppercaseFilterNameMatching = {
    vehicle_type: "VEHICLE_TYPE",
    make: "MAKE",
    model: "MODEL",
    trim_level: "TRIM_LEVEL",
    year: "YEAR",
    power: "POWER",
    cylinders: "CYLINDERS",
    engine_type: "ENGINE_TYPE",
  }
  filterAndSelectableValueNameMatching = {
    vehicle_type: "vehicle_type",
    make: "makes",
    model: "models",
    trim_level: "trim_levels",
    year: "years",
    power: "powers",
    cylinders: "cylinders",
    engine_type: "engine_types",
  }
  hybridTypes = ["H", "M", "P", "R"]

  createQueryParams(filterName, filters) {
    const rankOfTheFilterToUpdate = filters[filterName].rank
    let query_params = {}
    for (let [key, value] of Object.entries(filters)) {
      if (key === "vehicle_type") {
        if (value.data === "vn_only") Object.assign(query_params, { ["is_vn"]: true })
      } else {
        const isNotTheFilterToUpdate = key !== filterName
        const isValueNotEmpty = value.data.length !== 0
        const isOfLowerRank = value.rank < rankOfTheFilterToUpdate
        if (isNotTheFilterToUpdate && isValueNotEmpty && isOfLowerRank) {
          Object.assign(query_params, { [key]: value.data })
        }
      }
    }
    return query_params
  }
  transformArraySelectableValuesObjectsToObjectSelectableValues(values) {
    let selectableValues = {}
    values.forEach((value) => {
      Object.assign(selectableValues, { [value.id]: value.local_name })
    })
    return selectableValues
  }
  transformArraySelectableValuesToObjectSelectableValues(values) {
    let selectableValues = {}
    values.forEach((value) => Object.assign(selectableValues, value))
    return selectableValues
  }
  listMakes(makes) {
    const listMakes = []
    makes.forEach((make) => listMakes.push(make.id.toString()))
    return listMakes
  }
  listQueryFilter(filters) {
    let queryFilters = {}
    for (const filter in filters) {
      if (filters[filter].data.length > 0) {
        if (filter === "vehicle_type") {
          if (filters[filter].data === "vn_only")
            Object.assign(queryFilters, { ["is_vn"]: true })
        } else {
          queryFilters[filter] = filters[filter].data
        }
      }
    }
    return queryFilters
  }
  listFilterValuesWithHigherRank(filtersStructure, filterToCompare) {
    return Object.keys(filtersStructure).filter(
      (filter) => filtersStructure[filter].rank > filtersStructure[filterToCompare].rank
    )
  }
  listEngineTypes() {
    return ["C", "E", "F", "H", "M", "P", "R"]
  }

  /**
   * Returns the vehicle's dynamic inputs initialized according to the provided vehicle type filter.
   *
   * @param {String} vehicleTypeFilter vehicle type filter ("vn_only" or "all_vehicles")
   * @param {Object} vehicle vehicle data
   * @returns {Object}
   */
  getInitializedDynamicInputs(vehicleTypeFilter, vehicle) {
    let dynamicInputs = {
      vehicleType: null,
      firstReleaseDate: null,
      km: null,
      retailPrice: null,
    }
    if (this.canVehicleOnlyBeVnpj(vehicleTypeFilter, vehicle)) {
      const initializedFirstReleaseDate = DateService.getFormattedDateDaysAgoFromNow(
        this.DEFAULT_VNPJ_FIRST_RELEASE_DATE_DELTA_IN_DAYS
      )
      dynamicInputs.vehicleType = "VNPJ"
      dynamicInputs.firstReleaseDate = new Date(initializedFirstReleaseDate)
      dynamicInputs.km = this.DEFAULT_VNPJ_KM
      dynamicInputs.retailPrice = this._getInitializedRetailPriceDynamicInput(vehicle)
    }
    return dynamicInputs
  }

  /**
   * Returns whether the vehicle can only be a VNPJ vehicle, according to the provided vehicle type filter and its
   * 'is_vn' indicator.
   *
   * @param {String} vehicleTypeFilter vehicle type filter ("vn_only" or "all_vehicles")
   * @param {Object} vehicle vehicle data
   * @returns {Object}
   */
  canVehicleOnlyBeVnpj(vehicleTypeFilter, vehicle) {
    return (
      vehicleTypeFilter === "vn_only" || vehicle.is_vn.every((value) => value === true)
    )
  }

  /**
   * Returns the vehicle's retail price dynamic input initialized according to the vehicle's retail price from BDD.
   *
   * @param {Object} vehicle vehicle data
   * @returns {Number}
   */
  _getInitializedRetailPriceDynamicInput(vehicle) {
    if (vehicle.retail_price === null) {
      return null
    }
    if (ArrayService.isArray(vehicle.retail_price)) {
      return vehicle.retail_price.length === 1
        ? Math.trunc(vehicle.retail_price[0])
        : null
    }
    return Math.trunc(vehicle.retail_price)
  }

  /**
   * Returns the available options for the vehicle type according to the provided vehicle type filter and the 'is_vn'
   * indicator of the vehicle.
   *
   * @param {String} vehicleTypeFilter vehicle type filter ("vn_only" or "all_vehicles")
   * @param {Object} vehicle vehicle data
   * @returns {Object}
   */
  getVehicleTypeOptions(vehicleTypeFilter, vehicle) {
    const vnpjOptionOnly = {
      VNPJ: i18n.t("vehicleChoice.infos.vehicle_type_choices.vnpj"),
    }
    const allOptionsExceptVnpj = {
      VNR: i18n.t("vehicleChoice.infos.vehicle_type_choices.vnr"),
      VO: i18n.t("vehicleChoice.infos.vehicle_type_choices.vo"),
    }
    const allOptions = {
      ...vnpjOptionOnly,
      ...allOptionsExceptVnpj,
    }
    if (vehicleTypeFilter === "vn_only") {
      return vnpjOptionOnly
    }
    if (vehicleTypeFilter === "all_vehicles" && vehicle.is_vn.length > 0) {
      const isVehicleVnpjOnly = vehicle.is_vn.every((value) => value === true)
      if (isVehicleVnpjOnly) {
        return vnpjOptionOnly
      }
      const isVehicleNotVnpj = vehicle.is_vn.every((value) => value === false)
      if (isVehicleNotVnpj) {
        return allOptionsExceptVnpj
      }
    }
    return allOptions
  }

  /**
   * Returns the vehicle variations with only the values common to all, for each datum.
   * Typically, for a given vehicle, several product lines can have different possible values (variations) for a datum.
   * Ex:
   * For product line 1, we could have :
   *    warranty_whole_duration: {
   *             24: "24 mois",
   *             36: "36 mois",
   *             48: "48 mois",
   *             60: "60 mois",
   *           },
   * For product line 2, we could have :
   *    warranty_whole_duration: {
   *             24: "24 mois",
   *             60: "60 mois",
   *           },
   * In this case, the final possible values must be :
   *    warranty_whole_duration: {
   *             24: "24 mois",
   *             60: "60 mois",
   *           },
   * Output format :
   * {
   *     warranty_whole_duration: {
   *             24: "24 mois",
   *             60: "60 mois",
   *           },
   *     engine_code: {
   *             C: "C",
   *             E: "E",
   *             F: "F",
   *             H: "H",
   *           },
   * }
   *
   * @param {Array} vehicleVariations vehicle variations to merge
   * @returns {Object}
   */
  getMergedVehicleVariations(vehicleVariations) {
    const dataNames = new Set(vehicleVariations.flatMap(Object.keys))
    const mergedVariationsByDatumName = {}

    for (const datumName of dataNames) {
      const variationsList = vehicleVariations.map(
        (vehicleVariationsItem) => vehicleVariationsItem[datumName]
      )
      mergedVariationsByDatumName[
        datumName
      ] = ArrayService.getObjectWithCommonEntriesOnly(variationsList)
    }

    return mergedVariationsByDatumName
  }
}

let VehicleSearchService = new VehicleSearch()
export default VehicleSearchService
