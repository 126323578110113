<template>
  <div>
    <div class="about text-center mt-5">
      <h1>403</h1>
    </div>
    <div class="nav-next text-center">
      <p class="bold">{{ $t("error403") }}</p>
      <p>{{ $t("error.continue") }}</p>
    </div>

    <div class="nav-next text-center">
      <button
        class="btn btn-primary"
        data-cy="home-btn"
        @click.prevent="pushI18nRoute({ name: getUrlName })"
      >
        {{ $t("button.home") }}
      </button>
    </div>
  </div>
</template>
<script>
import ErrorPageMixin from "@/mixins/ErrorPageMixin"
export default {
  name: "Page403",
  mixins: [ErrorPageMixin],
}
</script>
