<template>
  <div>
    <burger-menu
      :visible="showMenu"
      @closeMenu="showMenu = false"
      @toggleMenuVisibility="showMenu = !showMenu"
    ></burger-menu>
    <div class="wrapper">
      <top-bar
        :expanded="showMenu"
        @toggleMenuVisibility="showMenu = !showMenu"
      ></top-bar>
      <div v-if="$router.currentRoute.meta.breadcrumb" class="wizard">
        <div class="steps bg-wizard"></div>
      </div>
      <breadcrumb
        v-if="$router.currentRoute.meta.breadcrumb"
        :key="$t($router.currentRoute.meta.breadcrumb)"
      ></breadcrumb>
      <horizontal-wizard
        v-if="$router.currentRoute.meta.requireWizard"
        :is-sharing-mode-activated="!isLoggedIn"
      ></horizontal-wizard>
      <hr v-if="$router.currentRoute.meta.breadcrumb" class="mx-5 ruler" />
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </transition>
      <no-layout-footer></no-layout-footer>
    </div>
  </div>
</template>

<script>
import noLayoutFooter from "@/components/login_footer"
import burgerMenu from "@/components/menu/burger_menu"
import topBar from "@/containers/default_container_components/TopBar"
import horizontalWizard from "@/containers/default_container_components/horizontal_wizard"
import breadcrumb from "@/containers/default_container_components/breadcrumb"
import SsoService from "@/services/technical/SsoService"

export default {
  name: "DefaultContainer",
  components: {
    noLayoutFooter,
    burgerMenu,
    topBar,
    horizontalWizard,
    breadcrumb,
  },
  data() {
    return {
      showMenu: false,
    }
  },
  computed: {
    isLoggedIn() {
      return SsoService.isAuthenticated()
    },
  },
  watch: {
    $route() {
      // refresh token if necessary on route change
      SsoService.refreshToken()
    },
  },
}
</script>

<style scoped>
.wrapper {
  min-height: 100vh;
}
.ruler {
  border-color: #e7eaf0;
}
</style>
