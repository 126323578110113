import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import ArrayService from "../services/technical/ArrayService"

// STATE (snake_case)
const state = {
  offers: null,
  is_loading: false,
  tags: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_OFFERS: (state, offers) => {
    state.offers = offers
  },
  SET_START_OF_LOADING: () => {
    state.is_loading = true
  },
  SET_END_OF_LOADING: () => {
    state.is_loading = false
  },
  SET_TAGS: (state, tags) => {
    state.tags = tags
  },
}

// ACTIONS (camelCase)
const actions = {
  async getEligibleOffersForSale({ commit }, saleId) {
    commit("SET_START_OF_LOADING")
    try {
      const offers = await HttpService.get(
        UrlService.render("saleListEligibleOffers", { id: saleId })
      )
      commit("SET_OFFERS", offers)
    } catch (e) {
      console.error("getEligibleOffersForSale failed: ", e)
      throw e
    } finally {
      commit("SET_END_OF_LOADING")
    }
  },
  async getTagsFromEligibleOffers({ commit }, vehicle) {
    try {
      const tags = await HttpService.post(
        UrlService.render("saleListTagsFromEligibleOffers"),
        { vehicle: vehicle }
      )
      commit("SET_TAGS", tags)
      return tags
    } catch (e) {
      console.error("getTagsFromEligibleOffers failed: ", e)
      throw e
    }
  },
  setOffers({ commit }, offers) {
    commit("SET_OFFERS", offers)
  },
}

// GETTERS
const getters = {
  areOffersLoading: (state) => state.is_loading,
  offers: (state) => state.offers,
  tagsFromEligibleOffers: (state) => state.tags,
  productLines: (state) => (offerId) => {
    let productLines = []
    for (const offer of state.offers) {
      if (offer.id === offerId) {
        offer.program_products.forEach((programProduct) => {
          programProduct.product_lines.forEach((productLine) => {
            productLines.push(productLine)
          })
        })
        break
      }
    }
    return productLines
  },
  allProductLines: (state) => () => {
    let productLines = []
    for (const offer of state.offers) {
      offer.program_products.forEach((programProduct) => {
        programProduct.product_lines.forEach((productLine) => {
          productLines.push(productLine)
        })
      })
    }
    return productLines
  },
  productLinesIds: (state) => (offerId) => {
    let productLinesIds = []
    for (const offer of state.offers) {
      if (offer.id === offerId) {
        offer.program_products.forEach((programProduct) => {
          programProduct.product_lines.forEach((productLine) => {
            productLinesIds.push(productLine.id)
          })
        })
        break
      }
    }
    return productLinesIds
  },
  isOfferWithMonthlyPayment: (state) => (offerId) => {
    const offer = state.offers.find((offer) => offer.id === offerId)
    return (
      offer.program_products[0].product_lines[0].config.payment_frequency.value ===
      "monthly"
    )
  },
  getOffer: (state) => (offerId) => state.offers.find((offer) => offer.id === offerId),
  getOfferByProgramProductId: (state) => (programProductId) => {
    return state.offers.find((offer) =>
      offer.program_products.some((pp) => pp.id === programProductId)
    )
  },
  listCurrentOfferSharedCoefCriteriaWithSpecificNames: (state, getters) => (
    offerId
  ) => {
    // List all the coefficient criteria shared within the current offer
    // For criteria that have specific name for a given product, use this specific name
    // (ex: will return "sales_mode_vo" and "sales_mode_maintenance" instead of just "sales_mode")
    const offer = getters.getOffer(offerId)
    let criteria = []
    for (const program_product of offer.program_products) {
      const productLine = program_product.product_lines[0]
      Object.values(
        productLine.config.shared_coefficient_criteria
      ).forEach((criterion) => ArrayService.pushIfNotExist(criteria, criterion))
    }
    return criteria
  },
  isOfferExternal: (state, getters) => (offerId) =>
    getters.getOffer(offerId).program_products[0].product_lines[0].is_external,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
