<template>
  <router-view data-cy="app" />
</template>
<script>
export default {
  name: "App",
}
</script>
<style lang="scss">
@import "./assets/scss/app.scss";
</style>
