<template>
  <header data-cy="top_bar">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-auto">
          <a>
            <img
              src="https://res.cloudinary.com/caarea/image/upload/v1617955697/cs/assets/logo-caarea_ebk5o1.svg"
              alt="Caarea Solutions"
              class="logo"
            />
          </a>
        </div>
        <div class="d-flex align-items-center mr-5">
          <div class="d-flex align-items-center mr-5">
            <h4 class="font-size-30 mb-0 sharing-title" data-cy="sharing-space-title">
              {{ $t("topbar.sharing.title") }}
            </h4>
          </div>
          <div
            class="col-auto slide"
            :class="{
              'slide-start': expanded,
            }"
          >
            <div class="col-auto dropdown show">
              <button
                id="languageDropdownMenuButton"
                class="invisible-button text-uppercase dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ currentLang }}
              </button>

              <div class="dropdown-menu" aria-labelledby="languageDropdownMenuButton">
                <button
                  v-for="lang in languages"
                  :key="lang"
                  class="dropdown-item text-uppercase"
                  :data-cy="`change-language-btn-${lang}`"
                  :disabled="lang === getCurrentLang"
                  @click.prevent="onChangeCurrentLanguage(lang)"
                >
                  {{ lang }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex"
import i18n from "@/i18n/index"
import SsoService from "@/services/technical/SsoService"

export default {
  name: "TopBarSharing",
  props: ["expanded"],
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("config", ["getCurrentLang"]),
    currentLang() {
      return this.getCurrentLang
    },
    languages() {
      let languages = []
      for (const lang of Object.keys(i18n.messages)) {
        if (lang === this.getCurrentLang) {
          languages.unshift(lang)
        } else {
          languages.push(lang)
        }
      }
      return languages
    },
  },
  methods: {
    onChangeCurrentLanguage(newLang) {
      this.$store.dispatch("config/setCurrentLang", newLang)
      const to = this.$router.resolve({ params: { lang: newLang } })
      this.$router.push(to.location)
    },
  },
}
</script>

<style lang="scss" scoped>
.slide {
  transition: all 0.3s;
}
.slide-start {
  transform: translateX(-100%);
}
.sharing-title {
  font-family: "Effra", sans-serif;
}
</style>
