<template>
  <ModalBootstrap
    class="criteria"
    :title="$t('offers.sharingStatusModal.title')"
    modal-size="large"
    :data-cy="`sharing-program-status-modal-${status}`"
    :show-close-button="false"
  >
    <template #body>
      <div class="mx-0 mt-4 mb-3 d-flex flex-column justify-content-center">
        <div class="row pb-4">
          <div>
            <i
              class="p-2 text-primary icon-prohibited prohibited"
              aria-hidden="true"
            ></i>
          </div>
          <div class="pt-2 ml-3 font-size-36" data-cy="sharing-status">
            <p>{{ statusLabel }}</p>
          </div>
          <div class="ml-4 sad">
            <i class="text-primary icon-sad font-size-36" aria-hidden="true"></i>
          </div>
        </div>
        <div class="font-size-16">
          <p>{{ reasonLabel }}</p>
        </div>
        <div class="font-size-16">
          <p>{{ reasonAdvice }}</p>
        </div>
        <div class="font-size-16">
          <p>{{ greetings }}</p>
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"

export default {
  name: "ModalSharingStatus",
  components: { ModalBootstrap },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      statusLabel: "",
      reasonLabel: "",
      reasonAdvice: "",
      greetings: "",
    }
  },
  created() {
    this.reasonAdvice =
      this.status === "revoked" || this.status === "expired"
        ? this.$t("offers.sharingStatusModal.advice")
        : ""
    this.greetings =
      this.status === "revoked" || this.status === "expired"
        ? this.$t("offers.sharingStatusModal.greetings")
        : ""
    if (this.status === "revoked") {
      this.statusLabel = this.$t("offers.sharingStatusModal.revoke_status")
      this.reasonLabel = this.$t("offers.sharingStatusModal.revoke_reason")
    } else if (this.status === "expired") {
      this.statusLabel = this.$t("offers.sharingStatusModal.expired_status")
      this.reasonLabel = this.$t("offers.sharingStatusModal.expired_reason")
    } else {
      this.statusLabel = this.$t("offers.sharingStatusModal.quota_status")
      this.reasonLabel = this.$t("offers.sharingStatusModal.quota_reason")
      this.reasonAdvice = this.$t("offers.sharingStatusModal.quota_advice")
    }
  },
  methods: {
    onClose() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>
.prohibited {
  font-size: 60px;
  font-weight: bold;
}
.sad {
  padding-top: 0.8rem;
}
</style>
