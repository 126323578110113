import AbstractService from "@/services/AbstractService"

class CurrencyService extends AbstractService {
  currencyCode = {
    kow: "₩",
    eur: "€",
  }

  getCurrencySymbol() {
    return this.currencyCode[`${process.env.VUE_APP_CURRENCY_CODE}`]
  }
}

export default new CurrencyService()
