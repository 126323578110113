<template>
  <div
    v-if="!isLoading"
    class="offer_accordion"
    :class="showInfosButton ? 'nbCols-3' : 'nbCols-2'"
    :data-cy="`offer-accordion-${programProduct.id}`"
  >
    <div class="offer_info">
      <p class="font-effra-light font-size-12 text-capitalize">
        {{ $t("offers.coverage") }}
      </p>
      <h4 class="font-gilroy-regular font-size-18 text-capitalize">
        {{ programProduct.name }}
      </h4>
    </div>
    <div v-show="showInfosButton" class="text-center offer_info">
      <button
        class="offer_accordion-button text-uppercase font-roboto-medium letter-spacing-75"
        :data-cy="`product-${programProduct.id}-infos-button`"
        data-toggle="modal"
        data-target="#modalMemo"
        @click="toggleMemoVisibility"
      >
        {{ $t("offers.infos") }}
      </button>
    </div>
    <!-- dynamiser l'id de la product line -->
    <CoverageDurationDistance
      v-if="showCoverageCriteria"
      :program-product-id="programProduct.id"
      :product-line-id="programProduct.product_lines[0].id"
      :disabled="disabled"
      @coverage-update="onUpdateCoverageDurationKm"
    ></CoverageDurationDistance>
    <CoveragePerformanceCriteria
      v-else
      :product-line-id="programProduct.product_lines[0].id"
      :disabled="disabled"
      @coverage-update="onUpdateCoverageDurationKm"
    ></CoveragePerformanceCriteria>
    <div class="container-fluid px-0">
      <ModalMemo
        v-if="showMemo && getMemo"
        id-modal="modalMemo"
        :title="programProduct.name"
        :sub-title="programProduct.description"
        modal-size="extraLarge"
        :data-cy="`memo-modal-${programProduct.code}`"
        :is-subscribable="isSubscribable"
        @close="toggleMemoVisibility"
        @add-to-cart="onSubscribe"
      >
        <template #body>
          <div class="d-flex">
            <img class="col-7 pl-0" :src="programProduct.image" alt="image" />
            <div class="col-5 p-0">
              <div v-if="getMemo.hasOwnProperty('object')">
                <p class="memo-label px-2">{{ $t("memo.object") }}</p>
                <div
                  v-for="(object, index) in getMemo.object"
                  :key="`${index}-${object}`"
                  class="d-flex ml-2 mb-3"
                >
                  <em :class="getObjectIcons(index)" class="mr-2"></em>
                  <p class="m-0">{{ object }}</p>
                </div>
              </div>
              <div v-if="getMemo.hasOwnProperty('coverage')">
                <p class="memo-label px-2">{{ $t("memo.coverage") }}</p>
                <div
                  v-for="(coverage, index) in getMemo.coverage"
                  :key="`${index}-${coverage}`"
                  class="d-flex align-items-center ml-2 mb-3"
                >
                  <em :class="getCoverageIcons(index)" class="mr-2"></em>
                  <span class="m-0">{{ coverage }}</span>
                  <em class="icon-check-square ml-2 icon-coverage-right"></em>
                </div>
              </div>
              <div v-if="getMemo.hasOwnProperty('price')">
                <div class="memo-label mb-2 d-flex justify-content-between">
                  <p class="mb-0 px-2">{{ $t("memo.price") }}</p>
                  <p class="memo-month mb-0">{{ $t("memo.month", { value: "12" }) }}</p>
                </div>
                <div class="d-flex ml-2">
                  <em class="icon-coin-euro mr-2"></em>
                  <p class="font-weight-bold">
                    {{ getMemo.price.price_min }} {{ $t("memo.to") }}
                    {{ getMemo.price.price_max }} {{ getCurrencySymbol }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="getMemo.hasOwnProperty('coverage_example')" class="mt-3">
            <p class="memo-label px-2">{{ $t("memo.coverage_example") }}</p>
            <div class="d-flex justify-content-between">
              <div
                v-for="(coverageExample, index) in getMemo.coverage_example"
                :key="`${index}-${coverageExample}`"
                class="d-flex flex-column align-items-center"
              >
                <p class="mb-0">{{ coverageExample.label }}</p>
                <p class="font-weight-bold">
                  {{ coverageExample.price }} {{ getCurrencySymbol }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </ModalMemo>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import CoverageDurationDistance from "@/components/offers/CoverageDurationDistance"
import CoveragePerformanceCriteria from "@/components/offers/CoveragePerformanceCriteria"
import ModalMemo from "@/components/modal/ModalMemo"
import local_product_memo from "@/conf/product_memo"
import product_memo_icons from "@/conf/product_memo_icons"
import BeneficiaryCriteriaService from "@/services/business/BeneficiaryCriteriaService"
import CurrencyService from "@/services/business/CurrencyService"

export default {
  name: "OfferAccordion",
  components: { CoverageDurationDistance, CoveragePerformanceCriteria, ModalMemo },
  props: {
    programProduct: {
      type: Object,
      required: true,
    },
    isSubscribable: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      showMemo: false,
    }
  },
  computed: {
    ...mapGetters("productLine", [
      "productLineCoefficients",
      "isProductWithCoupleDurationKmCoverage",
      "hasCoverageCouple",
      "getBeneficiaryCriteriaValues",
    ]),
    getMemo() {
      return local_product_memo[this.programProduct.code]
    },
    showInfosButton() {
      // CSO_726 : Infos button is temporarily not shown
      return false
    },
    showCoverageCriteria() {
      return BeneficiaryCriteriaService.hasCoverageCriteria(
        this.getBeneficiaryCriteriaValues(this.programProduct.product_lines[0].id)
      )
    },
    getCurrencySymbol() {
      return CurrencyService.getCurrencySymbol()
    },
  },
  async created() {
    const productLineId = this.programProduct.product_lines[0].id
    //todo to remove later if necessary
    if (
      this.isProductWithCoupleDurationKmCoverage(this.programProduct.code) &&
      !this.hasCoverageCouple(productLineId)
    ) {
      const beneficiaryCriteria = this.programProduct.product_lines[0].config
        .coefficient.beneficiary
      await this.$store.dispatch("productLine/setDurationKmCoverageCouples", {
        productLineId: productLineId,
        beneficiaryConfig: beneficiaryCriteria,
        programProductId: this.programProduct.id,
      })
    }
    this.isLoading = false
  },
  methods: {
    onUpdateCoverageDurationKm(beneficiaryCriteria) {
      this.$emit("coverage-update", beneficiaryCriteria)
    },
    getObjectIcons(index) {
      return product_memo_icons[this.programProduct.code][`object_${index + 1}`]
    },
    getCoverageIcons(index) {
      return product_memo_icons[this.programProduct.code][`coverage_${index + 1}`]
    },
    toggleMemoVisibility() {
      this.showMemo = !this.showMemo
    },
    onSubscribe() {
      this.toggleMemoVisibility()
      if (this.isSubscribable) this.$emit("add-to-cart")
    },
  },
}
</script>

<style scoped lang="scss">
.offer_accordion {
  display: grid;
  align-items: center;
  &.nbCols-2 {
    grid-template-columns: 1fr 1fr;
  }
  &.nbCols-3 {
    grid-template-columns: 1fr 1fr 2fr;
  }
  div.offer_info {
    padding: 2.2rem;
    &:nth-child(even) {
      padding-top: 3rem;
    }
    p {
      margin-bottom: 0;
      line-height: 1.5rem;
    }
  }
}
.offer_accordion {
  &:nth-child(even) {
    background-color: $background-secondary;
  }
  &:nth-child(odd) {
    background-color: $white;
  }
}

.offer_accordion-button {
  color: $light-grey;
  border: 0.5px solid $light-grey;
  border-radius: 5px;
  padding: 2px 70px;
  background-color: white;
}
.memo-label {
  background: #f0f0f0;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 3px;
  border-left: 3px solid $primary;
  .memo-month {
    text-transform: lowercase;
  }
}
.icon-coverage-right {
  font-size: 1.2rem;
}
</style>
