import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"

// STATE (snake_case)
const state = {
  shared_program_status: null,
  programs: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_SHARED_PROGRAM_STATUS(state, status) {
    state.shared_program_status = status
  },
  SET_PROGRAMS(state, programs) {
    state.programs = programs
  },
}

// ACTIONS (camelCase)
const actions = {
  async retrieveSharedProgram({ commit, dispatch }, sharedToken) {
    try {
      const sharedProgram = await HttpService.get(
        UrlService.render("programSharedByToken", { id: sharedToken }, { by_token: 1 })
      )
      commit("SET_PROGRAMS", [sharedProgram.program])
      await dispatch("_updateOffersAndProductLinesInStore", [sharedProgram.program])
      commit("SET_SHARED_PROGRAM_STATUS", "active")
    } catch (e) {
      console.error("retrieveSharedProgram failed: ", e)
      if (e && e.status && e.status === 400) {
        commit("SET_SHARED_PROGRAM_STATUS", e.data)
        await dispatch("gui/showSharingStatusModal", null, { root: true })
      }
    }
  },
  async listPrograms({ commit, dispatch }) {
    try {
      const programs = await HttpService.get(
        UrlService.render("programs", {}, { is_sales_app: true })
      )
      commit("SET_PROGRAMS", programs)
      await dispatch("_updateOffersAndProductLinesInStore", programs)
    } catch (e) {
      console.error("listPrograms failed: ", e)
      throw e
    }
  },
  async _updateOffersAndProductLinesInStore({ commit, dispatch }, programs) {
    const offers = await dispatch("_getAllOffersFromPrograms", programs)
    await dispatch("offer/setOffers", offers, { root: true })
    const productLines = await dispatch("_getAllProductLinesFromOffers", offers)
    await dispatch("productLine/setProductLines", productLines, { root: true })
  },
  _getAllOffersFromPrograms({}, programs) {
    let offers = []
    programs.forEach((program) => offers.push(...program.offers))
    return offers
  },
  _getAllProductLinesFromOffers({}, offers) {
    let productLines = []
    offers.forEach((offer) =>
      offer.program_products.forEach((program_product) =>
        productLines.push(...program_product.product_lines)
      )
    )
    return productLines
  },
}

// GETTERS
const getters = {
  getSharedProgramStatus: (state) => state.shared_program_status,
  getPrograms: (state) => state.programs,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
