import Vue from "vue"
import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"

// STATE (snake_case)
const state = {
  product_lines: null,
  coefficient_criteria: {},
  coverage_couples: null,
  is_loading: false,
  product_with_duration_km_coverage: [
    "extension_garantie_vn",
    "extension_garantie_vn_elec",
    "garantie_vo",
    "body_care",
    "interior_care",
    "vitrerie_eclairage",
  ],
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_PRODUCT_LINES: (state, productLines) => {
    state.product_lines = productLines
  },
  SET_COEFFICIENT_CRITERIA(state, payload) {
    Vue.set(state.coefficient_criteria, payload.id, payload.coefficientCriteria)
  },
  SET_COVERAGE_COUPLES(state, payload) {
    if (state.coverage_couples === null) state.coverage_couples = {}
    Vue.set(state.coverage_couples, payload.id, payload.coverage_couples)
  },
  SET_START_OF_LOADING: () => {
    state.is_loading = true
  },
  SET_END_OF_LOADING: () => {
    state.is_loading = false
  },
}

// ACTIONS (camelCase)
const actions = {
  async saveProductLinesForSale({ commit, dispatch }, payload) {
    commit("SET_START_OF_LOADING")
    try {
      commit("SET_PRODUCT_LINES", payload.productLines)

      for (const productLine of payload.productLines) {
        await dispatch("getCoefficientCriteria", {
          id: productLine.id,
          saleId: payload.saleId,
        })
      }
    } catch (e) {
      console.error("saveProductLinesForSale failed: ", e)
      throw e
    } finally {
      commit("SET_END_OF_LOADING")
    }
  },
  async getCoefficientCriteria({ commit }, payload) {
    const queryString = payload.saleId ? { saleId: payload.saleId } : {}
    try {
      const coefficientCriteria = await HttpService.get(
        UrlService.render(
          "productLineCoefficientCriteria",
          {
            id: payload.id,
          },
          queryString
        )
      )
      commit("SET_COEFFICIENT_CRITERIA", {
        id: payload.id,
        coefficientCriteria: coefficientCriteria,
      })
    } catch (e) {
      console.error("getCoefficientCriteria failed: ", e)
      throw e
    }
  },

  async setDurationKmCoverageCouples({ commit, rootGetters }, payload) {
    try {
      const coverageCouples = await HttpService.post(
        UrlService.render("coverageDurationKmCouples"),
        { criteria_keys: payload.beneficiaryConfig }
      )
      commit("SET_COVERAGE_COUPLES", {
        id: payload.productLineId,
        coverage_couples: coverageCouples,
      })
    } catch (e) {
      console.error(e)
      throw e
    }
  },
  async _buildPricingPayload({ getters, rootGetters }, productLines) {
    let payload = []
    productLines.forEach((productLine) => {
      const beneficiaryCriteria = getters.getBeneficiaryCriteriaValues(productLine.id)
      payload.push({
        product_line_id: productLine.id,
        beneficiary_criteria: beneficiaryCriteria,
      })
    })
    return payload
  },
  setProductLines({ commit }, productLines) {
    commit("SET_PRODUCT_LINES", productLines)
  },
}

// GETTERS
const getters = {
  areProductLinesLoading: (state) => state.is_loading,
  productLines: (state) => state.product_lines,
  getConfig: (state) => (id) => {
    const productLine = state.product_lines.find((pl) => pl.id === id)
    return JSON.parse(JSON.stringify(productLine.config))
  },
  listCoefCritOptions: (state) => (id, criterion) =>
    state.coefficient_criteria[id][criterion],
  getCoefCritValue: (state) => (id, criterion) => {
    const productLine = state.product_lines.find((pl) => pl.id === id)
    return productLine.config["coefficient"]["values"][criterion]
  },
  getCoefCritValueLabel: (state, getters) => (id, criterion) =>
    getters.listCoefCritOptions(id, criterion)[getters.getCoefCritValue(id, criterion)],
  listCoefCritProgramByGroup: (state) => (id, group) => {
    const productLine = state.product_lines.find((pl) => pl.id === id)
    return productLine.config["coefficient"]["program"][group]
  },
  beneficiaryCriteriaDefaultValues: (state, getters) => (id) => {
    const productLine = state.product_lines.find((pl) => pl.id === id)
    return productLine.config.coefficient.beneficiary.reduce((acc, k) => {
      acc[k] = productLine.config.coefficient.default_values[k]
      return acc
    }, {})
  },
  productLineCoefficients: (state) => (id) => {
    const productLine = state.product_lines.find((pl) => pl.id === id)
    return productLine.config.coefficient
  },
  isProductWithCoupleDurationKmCoverage: (state) => (product_name) =>
    !!state.product_with_duration_km_coverage.includes(product_name),
  hasCoverageCouple: (state) => (productLineId) =>
    state.coverage_couples !== null &&
    state.coverage_couples.hasOwnProperty(productLineId),
  getCoverageCouplesById: (state) => (productLineId) =>
    state.coverage_couples[productLineId],
  // Warning : keep the 'rootState' parameter even if it's not used, otherwise rootGetters won't work !
  getBeneficiaryCriteriaValues: (state, getters, rootState, rootGetters) => (id) => {
    return rootGetters["sale/hasBeneficiaryCriteriaValues"](id)
      ? rootGetters["sale/beneficiaryCriteriaValues"](id)
      : JSON.parse(JSON.stringify(getters.beneficiaryCriteriaDefaultValues(id)))
  },
  getPlBeneficiaryCriteriaValues: (state) => (id) => {
    const productLine = state.product_lines.find((pl) => pl.id === id)
    return productLine.config.coefficient.beneficiary.reduce(
      (acc, beneficiary_criteria) => {
        acc[beneficiary_criteria] =
          productLine.config.coefficient.values[beneficiary_criteria]
        return acc
      },
      {}
    )
  },
  getUserSelectableValues: (state) => (id) => {
    const productLine = state.product_lines.find((pl) => pl.id === id)
    return JSON.parse(JSON.stringify(productLine.config.user_selectable_values))
  },
  /**
   * Payment Frequency
   */
  getPaymentFrequencyCriteriaValue: (state) => (id) => {
    if (state.product_lines === null) return null
    const productLine = state.product_lines.find((pl) => pl.id === id)
    return productLine ? productLine.config["payment_frequency"]["value"] : null
  },
  isProductLineWithMonthlyPayment: (state, getters) => (id) => {
    return getters.getPaymentFrequencyCriteriaValue(id) === "monthly"
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
