export default {
  fetchLoggedUser: "users/me/",
  userAcceptCookies: "users/{id}/cookies/",
  userLang: "/users/{id}/lang/",
  sale: "sales/",
  external_sale: "sales/?is_external=True",
  beneficiary: "beneficiaries/",
  updateBeneficiary: "beneficiaries/{id}/",
  saleBeneficiary: "sales/{id}/beneficiaries/",
  subscriptionSaleRetrieve: "sales/{id}/subscription-sale/",
  saleFinalize: "sales/{id}/finalize/",
  saleSubscribe: "sales/{id}/subscribe/",
  saleSendEmail: "sales/{id}/send-email/",
  saleRetrieve: "sales/{id}/",
  saleUpdateVehicle: "sales/{id}/vehicle/",
  saleListEligibleOffers: "sales/{id}/eligible-offers/",
  saleListTagsFromEligibleOffers: "sales/tags-eligible-offers/",
  saleSelectedProductLines: "sales/{id}/selected-product-lines/",
  productLineCoefficientCriteria: "product-lines/{id}/coefficient-criteria",
  productLinesPricing: "sales/{id}/product-lines-pricing/",
  usedVehiclesSearch: "used-vehicles/search/",
  velasticSearch: "velastic/search/",
  velasticSearchModelsByMake: "velastic/models/",
  listMakes: "reference/makes/",
  listEnergies: "reference/energies/",
  coverageDurationKmCouples: "coverage/couples/",
  programSharedByToken: "program-shared/{id}/",
  programs: "programs/",
  cvdbIsVn: "vehicles/is-vn/",
  cvdbMake: "vehicles/makes/",
  cvdbModel: "vehicles/models/",
  cvdbTrimLevel: "vehicles/trim-levels/",
  cvdbYear: "vehicles/years/",
  cvdbPower: "vehicles/powers/",
  cvdbCylinders: "vehicles/cylinders/",
  cvdbEngineType: "vehicles/engine-types/",
  vehicleSearch: "vehicles/search/",
  vehicleVariations: "vehicles/variations/",
}
