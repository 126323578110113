<template>
  <div>
    <div class="offer_sheet-container mb-1">
      <div class="offer_sheet-img">
        <img
          src="https://res.cloudinary.com/caarea/image/upload/v1678962153/cs/products/vehicles/thumbnail_insurance_caarea_1_jva2ex.png"
          alt="Offer logo"
        />
      </div>
      <div class="offer_sheet-details">
        <div>
          <p class="offer_sheet-details-catchphrase font-size-13 mt-1 font-effra-light">
            {{ offerContent.description }}
          </p>
          <h3 class="font-size-25 text-uppercase font-gilroy-regular">
            {{ offerContent.name }}
          </h3>
          <div class="badge badge-pill badge-light">
            {{ $t("offers.flitter.by_flitter") }}
          </div>
        </div>
      </div>
      <div class="offer_sheet-button">
        <div>
          <a
            class="btn text-uppercase font-size-14 font-roboto-medium letter-spacing-9 flitter"
            :href="flitterSubscriptionArea"
            target="_blank"
          >
            <span class="icon-envelope font-size-16"></span>
            {{ $t("offers.flitter.button") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OfferSheetFlitter",
  props: {
    offer: { required: true },
  },
  data() {
    return {
      flitterSubscriptionArea: process.env.VUE_APP_FLITTER_SUBSCRIPTION_AREA,
    }
  },
  computed: {
    offerContent() {
      return this.offer.offer_contents.find(
        (offerContent) => offerContent.type === "B2C"
      )
    },
  },
}
</script>

<style scoped lang="scss">
.offer_sheet-container {
  display: grid;
  grid-template-columns: 130px auto 250px;
  border-left: 4px solid $body-color;
  border-right: 4px solid $body-color;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  margin-top: 24px;
  background: white;
}
.offer_sheet-details,
.offer_sheet-button {
  display: flex;
  flex-direction: row;
}

.offer_sheet-button {
  position: relative;
}

.offer_sheet-details {
  padding-left: 14px;
  justify-content: space-between;
  p:last-child {
    margin-bottom: 0;
  }
  .offer_sheet-details-button {
    border: 0.5px solid $light-grey;
    border-radius: 5px;
    padding: 2px 20px;
    background-color: white;
    color: $light-grey;
  }
}

.offer_sheet-button {
  background-color: $background-secondary;
  justify-content: space-evenly;
  align-items: center;
  .btn {
    background-color: $caareabluec700;
    border: 0.5px solid $caareabluec700;
    border-radius: 5px;
    padding: 4px 12px;
    letter-spacing: 0.75px;
    color: $caareagray6;
    mix-blend-mode: normal;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &.flitter {
      background-color: #427a88;
      border-color: #427a88;
    }
    .button-cancel {
      background-color: $secondary;
    }
  }
}

.offer_sheet-img img {
  padding: 0.5rem;
  width: 130px;
  height: 110px;
  overflow: hidden;
}

.icon-cart {
  margin-right: 10px;
}

.badge {
  margin: 0 0 8px 8px;
  padding: 8px 72px;
  color: #427a88;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.15px;
}
</style>
