<template>
  <ModalBootstrap
    class="criteria"
    :title="title"
    modal-size="large"
    data-cy="warning-modal"
    @close="onClose"
  >
    <template #body>
      <div class="mx-0 mt-4 mb-3 d-flex flex-column justify-content-center">
        <div class="d-flex flex-row justify-content-center align-items-center pb-4">
          <div>
            <em class="p-2 text-primary icon-warning warning" aria-hidden="true"></em>
          </div>
          <div class="col-9 pt-2 ml-3 font-size-20" data-cy="warning-reason">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html="reason"></p>
          </div>
        </div>
        <div
          v-if="detailedReason"
          class="font-size-14 text-center detailed-reason pb-4"
          data-cy="warning-detailed-reason"
        >
          <p>{{ detailedReason }}</p>
        </div>
        <div v-if="advice" class="font-size-16 text-center" data-cy="warning-advice">
          <p>{{ advice }}</p>
        </div>
        <div class="pt-2 text-center">
          <button
            class="btn btn-primary btn-centered w-25 px-1 mb-1"
            data-cy="warning-ok-button"
            @click.prevent="onClose"
          >
            Ok
          </button>
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"

export default {
  name: "ModalWarning",
  components: { ModalBootstrap },
  props: {
    title: { type: String, required: true },
    reason: { type: String, required: true },
    detailedReason: { type: String, default: null },
    advice: { type: String, default: null },
  },
  methods: {
    onClose() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>
.warning {
  font-size: 60px;
  font-weight: bold;
}

.detailed-reason {
  white-space: pre-wrap;
}
</style>
