import Vue from "vue"
import HttpService, { HttpError } from "@/services/technical/HttpService"
import StringService from "@/services/technical/StringService"
import UrlService from "@/services/technical/UrlService"
import DateService from "@/services/technical/DateService"

// STATE (snake_case)
const state = () => ({
  search_loading: false,
  search_result: { used: null, velastic: null },
  active_filters: {},
  search_errors: { used: {}, velastic: {} },
  velastic_selected: null,
  vehicle_details: null,
  selected_vehicle: null,
})

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_SEARCH_LOADING(state, value) {
    state.search_loading = value
  },
  SET_USED_VEHICLE_SEARCH_RESULT(state, result) {
    state.search_result.used = result
  },
  RESET_USED_VEHICLE_SEARCH_RESULT(state) {
    state.search_result.used = null
  },
  SET_USED_VEHICLE_SEARCH_ERRORS(state, errors) {
    state.search_errors.used = errors
  },
  RESET_USED_VEHICLE_SEARCH_ERRORS(state) {
    state.search_errors.used = {}
  },
  SET_VELASTIC_SEARCH_RESULT(state, result) {
    state.search_result.velastic = result
  },
  RESET_VELASTIC_SEARCH_RESULT(state) {
    state.search_result.velastic = null
    state.velastic_selected = null
  },
  SET_VELASTIC_SEARCH_ERRORS(state, errors) {
    state.search_errors.velastic = errors
  },
  RESET_VELASTIC_SEARCH_ERRORS(state) {
    state.search_errors.velastic = {}
  },
  SET_VELASTIC_SELECTED(state, result) {
    state.velastic_selected = result
  },
  SET_VEHICLE_DETAILS(state, details) {
    state.vehicle_details = details
  },
  RESET_VEHICLE_DETAILS(state) {
    state.vehicle_details = null
  },
  SET_ACTIVE_FILTER(state, payload) {
    Vue.set(state.active_filters, payload.filter, payload.value)
  },
  RESET_FILTERS(state) {
    state.active_filters = {}
  },
  REMOVE_ACTIVE_FILTER(state, filter) {
    Vue.delete(state.active_filters, filter.name)
  },
  SET_SELECTED_VEHICLE(state, vehicle) {
    state.selected_vehicle = vehicle
  },
}

// ACTIONS (camelCase)
const actions = {
  _resetSearch({ commit }, searchName) {
    commit(`RESET_${searchName.toUpperCase()}_SEARCH_RESULT`)
    commit(`RESET_${searchName.toUpperCase()}_SEARCH_ERRORS`)
  },
  resetUsedVehicleSearch({ dispatch }) {
    dispatch("_resetSearch", "used_vehicle")
  },
  resetVelasticSearch({ dispatch }) {
    dispatch("_resetSearch", "velastic")
  },
  resetVelasticErrors({ commit }) {
    commit("RESET_VELASTIC_SEARCH_ERRORS")
  },
  setActiveFilter({ commit }, payload) {
    if ([null, "", undefined].includes(payload.value)) {
      commit("REMOVE_ACTIVE_FILTER", payload)
    } else {
      // Format input to match backend serializer
      const formatedValue = payload.type === Array ? [payload.value] : payload.value
      commit("SET_ACTIVE_FILTER", { filter: payload.name, value: formatedValue })
    }
  },
  async searchModelByMake(directives, make) {
    try {
      return await HttpService.post(UrlService.render("velasticSearchModelsByMake"), {
        make: make,
      })
    } catch (e) {
      console.error(e)
      throw e
    }
  },
  async velasticSearch({ commit, dispatch, state }, payload) {
    commit("SET_SEARCH_LOADING", true)
    dispatch("resetVelasticSearch")
    dispatch("resetUsedVehicleSearch")
    let api_args = null
    if (payload.searchType) {
      api_args = {
        [payload.searchType]: payload.searchText,
        filters: payload.filters,
      }
    } else {
      api_args = {
        text: payload.text,
        filters: payload.filters,
      }
    }
    try {
      let searchResult = await HttpService.post(
        UrlService.render("velasticSearch"),
        api_args
      )
      commit("SET_VELASTIC_SEARCH_RESULT", searchResult)
      commit("SET_USED_VEHICLE_SEARCH_RESULT", searchResult.vehicles[0])
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.status === 422 || e.status === 404) {
          commit("SET_VELASTIC_SEARCH_ERRORS", e.data)
          return
        }
      }
      console.error("failed: ", e)
      throw e
    } finally {
      commit("SET_SEARCH_LOADING", false)
    }
  },
  setVelasticSearchResultSelected({ commit, dispatch }, result) {
    commit("SET_VELASTIC_SELECTED", result)
  },
  setUsedVehicleSearchResult({ commit, dispatch }, vehicle) {
    commit("SET_USED_VEHICLE_SEARCH_RESULT", vehicle)
  },
  setVehicleDetails({ commit }, details) {
    commit("SET_VEHICLE_DETAILS", details)
  },
  resetVehicleDetails({ commit }) {
    commit("RESET_VEHICLE_DETAILS")
  },
  setSelectedVehicle({ commit }, vehicle) {
    commit("SET_SELECTED_VEHICLE", vehicle)
  },
  async retrieveVehicleVariations({ commit, getters, rootGetters }, productLineId) {
    try {
      return await HttpService.get(
        UrlService.render(
          "vehicleVariations",
          {},
          {
            unique_vehicle_id: rootGetters["sale/getVehicleUniqueId"],
            product_line_id: productLineId,
          }
        )
      )
    } catch (e) {
      console.error("retrieveVehicleVariations failed: ", e)
      throw e
    }
  },
}

// GETTERS (camelCase)
const getters = {
  isSearchLoading: (state) => state.search_loading,
  /**
   * Used Vehicle
   */
  getUsedVehiclesSearchResult: (state) => state.search_result.used,
  getUsedVehicleFound: (state) => {
    const vehicle = state.search_result.used
    if (!vehicle) return null
    const name = `${vehicle.make} ${vehicle.model} ${vehicle.version}`
    const transmission = vehicle.transmission === "MECANIQUE" ? "manual" : "automatic"
    return {
      name: StringService.title(name),
      ...("version" in vehicle && { version: StringService.title(vehicle.version) }),
      ...("transmission" in vehicle && { transmission: transmission }),
      ...("make" in vehicle && { make: StringService.title(vehicle.make) }),
      ...("model" in vehicle && { model: StringService.title(vehicle.model) }),
      ...("year" in vehicle && {
        model_year: parseInt(vehicle.year),
      }),
      ...("energy" in vehicle && { energy: StringService.title(vehicle.energy) }),
      ...("power_tax" in vehicle && { fiscal_hp: parseInt(vehicle.power_tax) }),
      ...("power_horse" in vehicle && { power_hp: parseInt(vehicle.power_horse) }),
      ...("cylinder_number" in vehicle && {
        engine_nb_cylinders: parseInt(vehicle.cylinder_number),
      }),
    }
  },
  getUsedVehiclesSearchErrors: (state) => {
    const vehicle_details = state.vehicle_details
    let vehicle = state.search_errors.used
    if (vehicle_details) {
      vehicle = {
        ...vehicle,
        vehicle_type:
          vehicle_details.vehicleType === "" ? null : vehicle_details.vehicleType,
      }
    }
    return vehicle
  },
  hasUsedVehiclesSearchResult: (state) => state.search_result.velastic !== null,
  getVehicleFound: (state) => {
    const vehicle = state.selected_vehicle
    const vehicle_details = state.vehicle_details
    if (!vehicle) return null

    let vehicleFound = {
      ...vehicle,
      name: `${vehicle.make} ${vehicle.model}`,
      label: `${vehicle.make} ${vehicle.model} ${vehicle.version}`,
    }
    if (vehicle_details) {
      vehicleFound["km"] = vehicle_details.km
      vehicleFound["first_release_date"] = vehicle_details.first_release_date

      // As retail_price is originally provided by Velastic,
      // override it only if the user entered a new value manually (retail price is in vehicle_details)
      if (vehicle_details.hasOwnProperty("retail_price")) {
        vehicleFound["retail_price"] = vehicle_details.retail_price
      }
    }

    return vehicleFound
  },
  /**
   * Velastic
   */
  getVelasticFound: (state) => {
    const vehicle = state.selected_vehicle
    const vehicle_details = state.vehicle_details
    if (!vehicle) return null

    let vehicleFound = {
      ...vehicle,
      name: `${vehicle.make} ${vehicle.model}`,
    }
    if (vehicle_details) {
      vehicleFound["age"] =
        vehicle_details.age === "" ? null : parseInt(vehicle_details.age, 10)
      vehicleFound["km"] = vehicle_details.kilometers
        ? parseInt(vehicle_details.kilometers, 10)
        : 0
      vehicleFound["first_release_date"] = vehicle_details.first_release_date
        ? vehicle_details.first_release_date
        : DateService.format(new Date(), "YYYY-MM-DD")
      vehicleFound["age_unity"] = vehicle_details.ageUnity
      vehicleFound["retail_price"] = parseInt(vehicle_details.price, 10)
      vehicleFound["vehicle_type"] =
        vehicle_details.vehicleType === "" ? null : vehicle_details.vehicleType
    }
    return vehicleFound
  },
  getVelasticSearchErrors: (state) => state.search_errors.velastic,
  hasVelasticSearchResults: (state) => state.search_result.velastic !== null,
  getActiveFilters: (state) => state.active_filters,
  listVelasticSearchResults: (state) =>
    state.search_result.velastic ? state.search_result.velastic.vehicles : [],
  getVelasticSearchResultTotalHitsNb: (state, getters) => {
    return getters.hasVelasticSearchResults ? state.search_result.velastic.total : 0
  },
  getVehiclePrice: (state) =>
    state.vehicle_details ? state.vehicle_details.retail_price : null,
  getVehicleKm: (state) => (state.vehicle_details ? state.vehicle_details.km : null),
  getVehicleFirstReleaseDate: (state) =>
    state.vehicle_details ? state.vehicle_details.first_release_date : null,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
