<template>
  <header data-cy="top_bar">
    <div class="container">
      <div class="row align-items-center justify-content-between">
        <div class="col-auto">
          <a href="/">
            <img
              src="https://res.cloudinary.com/caarea/image/upload/v1617955697/cs/assets/logo-caarea_ebk5o1.svg"
              alt="Caarea Solutions"
              class="logo"
            />
          </a>
        </div>
        <div
          class="col-auto slide"
          :class="{
            'slide-start': expanded,
          }"
        >
          <div v-if="isLoggedIn" class="row align-items-center" data-cy="logged-block">
            <div class="col-auto block-user">
              <div class="row align-items-center">
                <div class="col-auto p-0">
                  <img src="/img/icons/user.png" alt="" />
                </div>
                <div class="col-auto">
                  <div
                    v-if="getCurrentUser"
                    class="user-name text-truncate"
                    data-cy="username"
                  >
                    {{ getCurrentUser.first_name + " " + getCurrentUser.last_name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto cart-basket">
              <span
                data-cy="basket-button"
                :class="{ clickable: isClickable }"
                @click.stop="onBasketClick"
              >
                <em class="las la-2x la-shopping-cart"></em>
                <span
                  v-if="showBadge"
                  class="badge badge-pill badge-basket"
                  data-cy="basket_badge"
                >
                  {{ nbItems }}
                </span>
              </span>
            </div>
            <div class="col-auto dropdown show">
              <button
                id="languageDropdownMenuButton"
                class="invisible-button dropdown-btn dropdown-toggle lang-dropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ currentLang }}
              </button>

              <div class="dropdown-menu" aria-labelledby="languageDropdownMenuButton">
                <button
                  v-for="lang in languages"
                  :key="lang"
                  class="dropdown-item dropdown-btn"
                  :data-cy="`change-language-btn-${lang}`"
                  :disabled="lang === getCurrentLang"
                  @click.prevent="onChangeCurrentLanguage(lang)"
                >
                  {{ lang }}
                </button>
              </div>
            </div>
            <div v-if="areCookiesAccepted" class="col-auto">
              <a
                id="open-sidenav"
                href="#"
                data-cy="burger_icon"
                @click="toggleVisibility()"
              >
                <img
                  src="/img/icons/menu.png"
                  alt=""
                  class="rotate"
                  :class="{
                    'rotate-start': expanded,
                  }"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex"
import i18n from "@/i18n/index"
import SsoService from "@/services/technical/SsoService"

export default {
  name: "TopBar",
  props: ["expanded"],
  computed: {
    ...mapGetters("basket", ["nbItems"]),
    ...mapGetters("auth", ["getCurrentUser", "areCookiesAccepted"]),
    ...mapGetters("config", ["getCurrentLang", "isLangAvailable"]),
    currentLang() {
      return this.getCurrentLang
    },
    languages() {
      let languages = []
      for (const lang of Object.keys(i18n.messages)) {
        if (lang === this.getCurrentLang) {
          languages.unshift(lang)
        } else {
          languages.push(lang)
        }
      }
      return languages
    },
    showBadge() {
      return this.nbItems > 0
    },
    isClickable() {
      return this.showBadge && this.$route.name !== "products"
    },
    isLoggedIn() {
      return SsoService.isAuthenticated()
    },
  },
  methods: {
    onBasketClick() {
      if (this.isClickable) {
        this.$router.push({
          name: "products",
          params: { saleId: this.$route.params.saleId },
        })
      }
    },
    toggleVisibility() {
      this.$emit("toggleMenuVisibility")
    },
    async onChangeCurrentLanguage(newLang) {
      await this.$store.dispatch("config/setCurrentLang", newLang)
      const payload = {
        userId: this.getCurrentUser.id,
        lang: newLang,
      }
      await this.$store.dispatch("auth/updateLang", payload)
      const to = this.$router.resolve({ params: { lang: newLang } })
      await this.$router.push(to.location)
    },
  },
}
</script>

<style lang="scss" scoped>
.rotate {
  transition: all 0.3s;
}
.clickable {
  cursor: pointer;
}
.rotate-start {
  rotate: 180deg;
}
.slide {
  transition: all 0.3s;
}
.slide-start {
  transform: translateX(-100%);
}
.badge-basket {
  position: relative;
  top: -15px;
  left: -10px;
  font-size: 62%;
  background-color: $primary;
  color: white;
}
.dropdown-btn {
  text-transform: uppercase;
}

.cart-basket em,
.lang-dropdown {
  display: inline-block;
  transition: all 0.25s ease-in-out;
  &:hover {
    background: $grey6;
    border-radius: 4px;
  }
}
</style>
