<template>
  <div
    class="large-button"
    :class="{ 'large-button__disable': isDisabled }"
    :data-cy="dataCyButton"
  >
    <span class="large-button__picto" :class="icon"></span>
    <div class="large-button__text">
      <p class="large-button__text--line1">{{ subhead }}</p>
      <p class="large-button__text--line2">{{ text }}</p>
    </div>
  </div>
</template>

<script>
//todo remove css large-button__disable and isDisabled props when all button are enable
export default {
  props: {
    icon: {
      type: String,
      require: true,
    },
    subhead: {
      type: String,
      require: true,
    },
    text: {
      type: String,
      require: true,
    },
    isDisabled: {
      type: Boolean,
    },
  },
  computed: {
    dataCyButton() {
      return "home-button-" + this.text.split(" ").join("-")
    },
  },
}
</script>

<style lang="scss" scoped>
.large-button__disable {
  cursor: not-allowed !important;

  & > * {
    color: #989898;
  }
}
.large-button {
  cursor: pointer;
  font-family: "Effra", sans serif;
  display: flex;
  justify-content: start;
  align-items: center;
  border-radius: 4px;
  background-color: white;
  color: $caareablue;
  padding: 11px 35px 11px 20px;
  margin: 30px;
  box-shadow: 5px 5px 14px #000000;

  &__picto {
    vertical-align: center;
    font-size: 23px;
    margin: 0 15px 0 0;
  }

  &__text {
    text-transform: uppercase;
    &--line1 {
      margin: 0;
      font-size: 12px;
    }
    &--line2 {
      margin: 0;
      font-size: 20px;
      line-height: 17px;
    }
  }
}
</style>
