import Vue from "vue"
import VueI18n from "vue-i18n"
import localeEN from "./en/locale_en.json"
import localeFR from "./fr/locale_fr.json"
import localeES from "./es/locale_es.json"
import localeKO from "./ko/locale_ko.json"
import indexEn from "./en/index"
import indexFr from "./fr/index"
import indexEs from "./es/index"
import indexKo from "./ko/index"
import numberFormats from "@/i18n/numberFormats.json"

Vue.use(VueI18n)

const messages = {
  en: { ...localeEN, ...indexEn },
  fr: { ...localeFR, ...indexFr },
  es: { ...localeES, ...indexEs },
  ko: { ...localeKO, ...indexKo },
}

export default new VueI18n({
  fallbackLocale: "fr",
  locale: "fr",
  messages,
  numberFormats,
})
