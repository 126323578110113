import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import ArrayService from "@/services/technical/ArrayService"

/* This store is used to handle search criteria dynamic content */

// STATE (snake_case)
const state = {
  makes: {},
  selected_make: null,
  models: {},
  energies: {},
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_MAKES: (state, makes) => {
    state.makes = makes.reduce((acc, make) => {
      acc[make.code] = make.label
      return acc
    }, {})
  },
  SET_SELECTED_MAKE: (state, make) => {
    state.selected_make = make
  },
  SET_MODELS: (state, models) => {
    state.models = Object.values(models).reduce((acc, v) => {
      acc[v.model] = v.local
      return acc
    }, {})
  },
  RESET_MODELS: (state) => {
    state.models = {}
  },
  SET_ENERGIES: (state, energies) => {
    state.energies = energies.reduce((acc, energy) => {
      acc[energy.code] = energy.label
      return acc
    }, {})
  },
}

// ACTIONS (camelCase)
const actions = {
  async getAllMakes({ commit }) {
    try {
      const makes = await HttpService.get(UrlService.render("listMakes"))
      commit("SET_MAKES", makes)
    } catch (e) {
      console.error("getAllMakes failed: ", e)
      throw e
    }
  },
  selectMake({ commit }, make) {
    if (ArrayService.isArray(make))
      commit(
        "SET_SELECTED_MAKE",
        make.filter((n) => n)
      )
    else commit("SET_SELECTED_MAKE", make)
    commit("RESET_MODELS")
  },
  setModels({ commit }, modelsResult) {
    commit("SET_MODELS", modelsResult.models)
  },
  async getAllEnergies({ commit }) {
    try {
      const energies = await HttpService.get(UrlService.render("listEnergies"))
      commit("SET_ENERGIES", energies)
    } catch (e) {
      console.error("getAllEnergies failed: ", e)
      throw e
    }
  },
}

// GETTERS
const getters = {
  listMakes: (state) => state.makes,
  getSelectedMake: (state) => state.selected_make,
  listModels: (state) => state.models,
  getSelectedModel: (state) => state.selected_model,
  listEnergies: (state) => state.energies,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
