<template>
  <div class="wrapper">
    <ModalSharingStatus
      v-if="hasSharingStatusModalToBeDisplayed"
      :status="getSharedProgramStatus"
    ></ModalSharingStatus>
    <TopBarSharing
      :expanded="showMenu"
      @toggleMenuVisibility="showMenu = !showMenu"
    ></TopBarSharing>
    <horizontal-wizard
      v-if="$router.currentRoute.meta.requireWizard"
      :is-sharing-mode-activated="!isLoggedIn"
    ></horizontal-wizard>
    <transition name="fade" mode="out-in">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
    <no-layout-footer></no-layout-footer>
  </div>
</template>

<script>
import noLayoutFooter from "@/components/login_footer"
import horizontalWizard from "@/containers/default_container_components/horizontal_wizard"
import SsoService from "@/services/technical/SsoService"
import TopBarSharing from "@/containers/default_container_components/TopBarSharing"
import ModalSharingStatus from "../components/modal/ModalSharingStatus"
import { mapGetters } from "vuex"

export default {
  name: "SharingContainer",
  components: {
    TopBarSharing,
    noLayoutFooter,
    horizontalWizard,
    ModalSharingStatus,
  },
  data() {
    return {
      showMenu: false,
    }
  },
  computed: {
    ...mapGetters("gui", ["hasSharingStatusModalToBeDisplayed"]),
    ...mapGetters("program", ["getSharedProgramStatus"]),

    isLoggedIn() {
      return SsoService.isAuthenticated()
    },
  },
  watch: {
    $route() {
      // refresh token if necessary on route change
      SsoService.refreshToken()
    },
  },
}
</script>

<style scoped>
.wrapper {
  min-height: 100vh;
}
</style>
