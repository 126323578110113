export default {
  extension_garantie_vn: {
    object: ["Pannes fortuites (électronique ou mécaniques)"],
    coverage: ["Pièces détachées", "Main d'oeuvre", "Fluide"],
    price: { price_min: 200, price_max: 500 },
  },
  garantie_vo: {
    object: ["Pannes fortuites (électronique ou mécaniques)"],
    coverage: ["Pièces détachées", "Main d'oeuvre", "Fluide"],
    price: { price_min: 150, price_max: 450 },
    coverage_example: [
      { label: "Système d'injection", price: "1 500" },
      { label: "Boite de vitesse automatique", price: "6 000" },
      { label: "Remplacement de l'alternateur", price: "600" },
    ],
  },
  extension_garantie_vn_elec: {
    object: ["Pannes fortuites (électronique ou mécaniques)"],
    coverage: ["Pièces détachées", "Main d'oeuvre", "Fluide"],
    price: { price_min: 150, price_max: 400 },
    coverage_example: [
      { label: "Pile à combustible", price: "4 500" },
      { label: "Réservoir d'hydrogène", price: "2 500" },
      { label: "Batterie", price: "2 000" },
    ],
  },
  rachat_franchise: {
    coverage: ["Pare-brise", "Clés", "Carrosserie", "Pneumatique"],
    price: { price_min: 50, price_max: 150 },
    coverage_example: [
      { label: "Frais de réparation", price: "900" },
      { label: "Remboursement de l'assurance automobile", price: "500" },
      { label: "Sans assurance complémentaire", price: "400" },
      { label: "Avec notre assurance complémentaire", price: "0" },
    ],
  },
  gap: {
    coverage: ["Dommage total", "Incendie", "Vol"],
    price: { price_min: 50, price_max: 100 },
    coverage_example: [
      { label: "Prix du véhicule", price: "35 000" },
      { label: "L'assurance automobile paie", price: "22 000" },
      { label: "Notre produit GAP paie", price: "12 000" },
    ],
  },
  gap_finance: {
    coverage: ["Dommage total", "Incendie", "Vol"],
    price: { price_min: 50, price_max: 100 },
    coverage_example: [
      { label: "Solde créditeur", price: "25 000" },
      { label: "L'assurance automobile paie", price: "21 000" },
      { label: "Notre produit GAP paie", price: "4 000" },
    ],
  },
  vitrerie_eclairage: {
    coverage: ["Pare-brise", "Vitres", "Vitre custode", "Rétroviseur"],
    price: { price_min: 0, price_max: 100 },
    coverage_example: [
      { label: "Coût de la main-d'oeuvre", price: "70" },
      { label: "Pièces détachées", price: "230" },
      { label: "Notre assurance vitrerie paie", price: "300" },
    ],
  },
  interior_care: {
    coverage: ["Taches", "Rayures", "Fissures"],
    price: { price_min: 50, price_max: 200 },
    coverage_example: [
      { label: "Coût de la main-d'oeuvre", price: "250" },
      { label: "Peinture, matériaux", price: "650" },
      { label: "Notre assurance Interior Care paie", price: "900" },
    ],
  },
  body_care: {
    coverage: ["Taches", "Rayures", "Fissures"],
    price: { price_min: 50, price_max: 200 },
    coverage_example: [
      { label: "Coût de la main-d'oeuvre", price: "250" },
      { label: "Peinture, matériaux", price: "650" },
      { label: "Notre assurance Body Care paie", price: "900" },
    ],
  },
  maintenance: {
    coverage: ["Pièces détachées", "Main d'oeuvre", "Fluide"],
    price: { price_min: 200, price_max: 600 },
  },
  garantie_perfo_batterie: {
    coverage: ["Pièces détachées", "Main d'oeuvre"],
    price: { price_min: 100, price_max: 200 },
    coverage_example: [
      { label: "Coût de la main-d'oeuvre", price: "800" },
      { label: "Batterie", price: "6 500" },
      { label: "Notre assurance Performance de la batterie paie", price: "7 300" },
    ],
  },
}
