<template>
  <div :class="[...buttonClass]">
    <button
      :disabled="disabled"
      class="btn btn-primary btn-block"
      :data-cy="buttonDataCy ? buttonDataCy : 'validate-button'"
      @click="onButtonClick(optionalArg)"
    >
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ActionButton",
  props: {
    disabled: {
      type: Boolean,
    },
    buttonClass: {
      type: Array,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonDataCy: {
      type: String,
    },
    optionalArg: {
      default: () => "",
    },
  },
  methods: {
    onButtonClick(optionalArg) {
      this.$emit("click-action", optionalArg)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  width: 284px;
  padding: 0.375rem 1rem;
  border-radius: 5px;
  margin: 0 auto;
}
</style>
