import AbstractService from "@/services/AbstractService"
import Keycloak from "keycloak-js"

class SsoUtils extends AbstractService {
  constructor() {
    super()
    this.clear()
  }
  clear() {
    this._keycloakAuth = null
  }
  _initSsoClient() {
    if (!this._keycloakAuth) {
      this._keycloakAuth = Keycloak({
        url: process.env.VUE_APP_SSO_URL,
        realm: process.env.VUE_APP_SSO_REALM,
        clientId: process.env.VUE_APP_SSO_CLIENT_ID,
      })
    }
  }
  isAuthenticated() {
    return this._keycloakAuth && this._keycloakAuth.authenticated
  }
  async init(context, callbackSuccess, callbackError) {
    this._initSsoClient()
    this._keycloakAuth
      .init({
        onLoad: "login-required",
        checkLoginIframe: !window.Cypress,
      })
      .then((authenticated) => {
        // console.log("init success", authenticated)
        if (!authenticated) {
          window.location.reload()
        }
        callbackSuccess(context)
      })
      .catch((reject) => {
        console.error("Sso failed", reject)
        callbackError(context, reject)
      })
  }
  async refreshToken() {
    if (!this._keycloakAuth || !this.isAuthenticated()) {
      // console.log("refreshToken no auth so no need to refresh")
      return null
    }
    // Update the token when will last less than 70s
    await this._keycloakAuth
      .updateToken(70)
      .then((refreshed) => {
        if (!refreshed) {
          // console.log(
          //   "Token not refreshed, valid for " +
          //     Math.round(
          //       this._keycloakAuth.tokenParsed.exp +
          //         this._keycloakAuth.timeSkew -
          //         new Date().getTime() / 1000
          //     ) +
          //     " seconds"
          // )
        }
      })
      .catch(function (error) {
        console.error("Failed to refresh token")
      })
    return this._keycloakAuth.token
  }
  async logout() {
    if (!this._keycloakAuth) {
      return null
    }
    // Specify the uri to redirect to after logout
    // (in dev, it will be "http://localhost:8003/")
    await this._keycloakAuth.logout({ redirectUri: window.location.origin })
  }
}

let SsoService = new SsoUtils()
export default SsoService
