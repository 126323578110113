<template>
  <div id="horizontal-wizard" class="wizard">
    <div class="steps" @click="$emit('toggle')">
      <div>
        <div class="d-flex align-items-center justify-content-around">
          <horizontal-wizard-item
            key="vehicule"
            data-cy="vehicle_link"
            :title="$t('your_vehicle.title')"
            :sub-title="$t('your_vehicle.subTitle')"
            number="1"
            :is-done="currentStateNumber > 1"
            :disabled="wasSubscriptionSent"
            :route="vehicleRoute"
          ></horizontal-wizard-item>
          <horizontal-wizard-item
            key="product"
            data-cy="product_link"
            :title="$t('your_offers.title')"
            :sub-title="$t('your_offers.subTitle')"
            number="2"
            :is-done="currentStateNumber > 2"
            :route="productsRoute"
            :disabled="!hasSale || wasSubscriptionSent"
          ></horizontal-wizard-item>
          <horizontal-wizard-item
            key="customer"
            data-cy="customer_link"
            :title="$t('customer_infos.title')"
            :sub-title="$t('customer_infos.subTitle')"
            number="3"
            :is-done="currentStateNumber > 3"
            :route="customerRoute"
            :disabled="!hasSale || currentStateNumber < 3 || wasSubscriptionSent"
          ></horizontal-wizard-item>
          <horizontal-wizard-item
            key="subscription"
            data-cy="subscription_link"
            :title="$t('subscribe.title')"
            :sub-title="$t('subscribe.subTitle')"
            number="4"
            :is-done="currentStateNumber > 4"
            :route="subscriptionRoute"
            :disabled="!hasSale || currentStateNumber < 4"
          ></horizontal-wizard-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import horizontalWizardItem from "@/containers/default_container_components/horizontal_wizard_item"
import { mapGetters } from "vuex"
export default {
  name: "Wizard",
  components: {
    horizontalWizardItem,
  },
  props: {
    isSharingModeActivated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("sale", [
      "hasSale",
      "currentSale",
      "hasAtLeastOneProductLine",
      "wasSubscriptionSent",
    ]),
    currentStateNumber() {
      /*
      si il n'y a pas de sale, on est à l'étape 1
      si il y a une sale, on est à l'étape 2
      si il y a des produits on est à l'étape 3
      si il y a un bénéficiaire on est à l'étape 4
       */
      let currentStateNumber = 1
      if (this.currentSale) {
        if (this.currentSale.beneficiary) {
          currentStateNumber = 4
        } else if (this.hasAtLeastOneProductLine) {
          currentStateNumber = 3
        } else {
          currentStateNumber = 2
        }
      }
      return currentStateNumber
    },
    vehicleRoute() {
      return this.isSharingModeActivated ? "shared_vehicle" : "vehicle"
    },
    productsRoute() {
      return this.isSharingModeActivated ? "shared_products" : "products"
    },
    customerRoute() {
      return this.isSharingModeActivated ? "shared_customer" : "customer"
    },
    subscriptionRoute() {
      return this.isSharingModeActivated ? "shared_subscription" : "subscription"
    },
  },
  methods: {
    onToggle: function (stepNumber) {
      this.tabsVisibility.splice(stepNumber, 1, !this.tabsVisibility[stepNumber])
    },
  },
}
</script>
