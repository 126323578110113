<template>
  <div class="external-sales-list" data-cy="external-sales-list">
    <h4 class="font-effra-bold font-size-18">{{ $t("sale.sales_list") }}</h4>
    <div class="search-bar__underline"></div>
    <table class="external-sales-list__table">
      <caption></caption>
      <tbody>
        <tr
          v-for="sale in externalSales"
          :key="sale.id"
          :data-cy="`external-sale-${sale.id}`"
          @click="onExternalSaleClick(sale)"
        >
          <td class="external-sales__ref">
            <div class="table-data">
              <span class="table-data__label">{{ $t("sale.sales_reference") }}</span>
              <span class="gilroy-text" :data-cy="'external-sale-num-' + sale.id">
                {{ sale.num_external }}
              </span>
            </div>
          </td>
          <td class="external-sales__first-name">
            <div class="table-data">
              <span class="table-data__label">
                {{ $t("sale.sales_beneficiary_last_name") }}
              </span>
              <span class="gilroy-text" :data-cy="'external-sale-last-name-' + sale.id">
                {{ sale.beneficiary.user.last_name }}
              </span>
            </div>
          </td>
          <td class="external-sales__last-name">
            <div class="table-data">
              <span class="table-data__label">
                {{ $t("sale.sales_beneficiary_first_name") }}
              </span>
              <span
                class="gilroy-text"
                :data-cy="'external-sale-first-name-' + sale.id"
              >
                {{ sale.beneficiary.user.first_name }}
              </span>
            </div>
          </td>
          <td class="external-sales__plate">
            <div class="table-data">
              <span class="table-data__label">{{ $t("sale.sales_immat") }}</span>
              <span class="gilroy-text" :data-cy="'external-sale-plate-' + sale.id">
                {{ sale.vehicle.plate }}
              </span>
            </div>
          </td>
          <td class="external-sales__vehicle">
            <div class="table-data">
              <span class="table-data__label">{{ $t("sale.sales_vehicle") }}</span>
              <span class="gilroy-text" :data-cy="'external-sale-vehicle-' + sale.id">
                {{ sale.vehicle.make }} {{ sale.vehicle.model }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "ExternalSalesList",
  props: {
    externalSales: { type: Array, required: true },
  },
  methods: {
    onExternalSaleClick(externalSale) {
      this.$emit("click-external-sale", externalSale)
    },
  },
}
</script>

<style scoped lang="scss">
.external-sales-list {
  margin-top: 50px;
  .external-sales-list__table {
    width: 100%;
    cursor: pointer;
    tbody {
      tr {
        background: white;
        &:hover {
          box-shadow: inset 0px 0px 0px 1px $caareabordergray;
          animation-delay: 250ms;
        }
        &:nth-child(even) {
          background: #efede9;
        }
        td {
          padding: 1rem;
          font-weight: bold;
        }
      }
    }
  }
}

.table-data {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table-data__label {
  font-family: "Effra", sans-serif;
  font-weight: normal;
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
}
.third-parties__no-third-party {
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}
.table-data__phone {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d5d7dd;
  border-radius: 0.5rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
}
.search-bar__underline {
  width: 6rem;
  height: 1px;
  background-color: $caareablue;
  margin-bottom: 1rem;
}
</style>
