<template>
  <div class="bg-light d-flex flex-column">
    <div data-cy="search-by-external-sale">
      <p class="mb-1">{{ $t("sale.by_external_sale") }}</p>
      <div class="label__separator"></div>
    </div>
    <div>
      <FormInput
        v-model="externalSale"
        placeholder="123BCNHN"
        :max-length="17"
        name="search-by-external-sale"
        @keyboard-enter="onSearchExternalSale"
      ></FormInput>
    </div>
    <div class="p-2 text-center mt-auto">
      <button
        type="button"
        class="btn btn-primary rounded mt-4"
        :disabled="isButtonDisabled"
        data-cy="search-button-external-sale"
        @click.prevent="onSearchExternalSale"
      >
        {{ $t("button.validate") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExternalSaleSearchArea",
  data() {
    return {
      externalSale: "",
    }
  },
  computed: {
    isButtonDisabled() {
      return this.externalSale === ""
    },
  },
  methods: {
    onSearchExternalSale() {
      this.$emit("search-external-sale", this.externalSale)
    },
  },
}
</script>

<style scoped lang="scss">
.label__separator {
  width: 4rem;
  height: 1px;
  background-color: $caareablue;
  margin-bottom: 2rem;
}
</style>
