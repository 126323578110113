<template>
  <div class="search-bar" data-cy="external-sale-search-bar">
    <p>{{ $t("sale.search_title") }}</p>
    <div class="search-bar__underline"></div>
    <FormInput
      v-model="saleNumber"
      :name="name"
      :placeholder="$t('sale.by_ref')"
      :errors="errors"
      @keyboard-enter="searchSaleNumber"
    ></FormInput>
    <ActionButton
      class="search-bar__button"
      :button-text="$t('button.validate')"
      :disabled="saleNumber === ''"
      @click-action="searchSaleNumber"
    ></ActionButton>
  </div>
</template>

<script>
import ActionButton from "@/components/ActionButton"
import FormElementMixin from "@/components/mixins/FormElementMixin"
export default {
  name: "SearchBar",
  components: { ActionButton },
  mixins: [FormElementMixin],
  data() {
    return {
      saleNumber: "",
    }
  },
  created() {
    this.$set(this.errors, this.name, "")
  },
  methods: {
    searchSaleNumber() {
      if (this.saleNumber !== "") {
        this.$emit("search-sale", this.saleNumber)
      } else {
        this.$set(this.errors, this.name, this.$t("sale.error"))
        //todo: arriver à faire afficher par formInput l'erreur retournée
      }
    },
  },
}
</script>

<style scoped lang="scss">
.search-bar {
  background-color: white;
  width: 60rem;
  margin: 8px auto 0;
  padding: 20px 47px;
  p {
    margin-bottom: 3px;
  }
  .search-bar__underline {
    width: 17rem;
    height: 1px;
    background-color: $caareablue;
    margin-bottom: 2rem;
  }
  .search-bar__button {
    margin: 50px 0 10px 0;
  }
}
</style>
