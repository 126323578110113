<template>
  <div class="h-100">
    <spinner
      v-if="isLoading"
      :message="$t('spinner.loading')"
      class="mt-5 mb-5"
    ></spinner>
    <div v-else class="mt-5" data-cy="programs-details">
      <div
        v-if="offers && offers.length === 0"
        class="d-flex flex-column align-items-center"
      >
        <h4 class="text-justify text-center mt-5 mb-5">
          {{ $t("programs.no_offers") }}
        </h4>
        <button
          class="rounded btn-primary px-5 py-1 font-size-14 font-roboto-medium"
          data-cy="button-back"
          @click="$router.push({ name: homeRoute })"
        >
          {{ $t("programs.back") }}
        </button>
      </div>
      <div v-else class="container">
        <ProgramOfferSheet
          v-for="offer in offers"
          :key="offer.id"
          :offer="offer"
        ></ProgramOfferSheet>
        <button
          class="rounded btn-primary px-5 py-1 font-size-14 font-roboto-medium"
          data-cy="button-back"
          @click="$router.push({ name: homeRoute })"
        >
          {{ $t("programs.back") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ProgramOfferSheet from "@/components/programs/ProgramOfferSheet"

export default {
  name: "Programs",
  components: { ProgramOfferSheet },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters("auth", ["isLoggedInSharingMode", "getSharingToken"]),
    ...mapGetters("program", ["getPrograms"]),
    ...mapGetters("offer", ["offers"]),
    homeRoute() {
      return this.isLoggedInSharingMode ? "shared_home" : "home"
    },
  },
  async created() {
    this.isLoading = true
    if (this.isLoggedInSharingMode) {
      await this.$store.dispatch("program/retrieveSharedProgram", this.getSharingToken)
    } else {
      await this.$store.dispatch("program/listPrograms")
    }
    this.isLoading = false
  },
}
</script>

<style scoped></style>
