import AbstractService from "@/services/AbstractService"

class ObjectUtils extends AbstractService {
  /**
   *
   * @param {String} from
   * @param {String} to
   * @param {Object} obj
   */
  getRange(from, to, obj) {
    const asArray = Object.entries(obj)
    const filtered = asArray.filter(([key, value]) => value >= from && value <= to)
    return Object.fromEntries(filtered)
  }
}

let ObjectService = new ObjectUtils()
export default ObjectService
