<template>
  <div class="pr-4">
    <OfferSynthesis
      v-if="itemType === 'offer' && offerConfig"
      :offer-in-progress="getOffer(item.id)"
      :offer-config="offerConfig"
    ></OfferSynthesis>
    <p v-else-if="itemType === 'offer'" class="offer-no-details">
      {{ $t("programProductSynthesis.offer-no-details") }}
    </p>
    <ProgramProductSynthesis
      v-else-if="itemType === 'program_product'"
      :program-product="item"
      :product-line-id="productLineId"
    ></ProgramProductSynthesis>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import OfferSynthesis from "@/components/offers/OfferSynthesis"
import ProgramProductSynthesis from "@/components/offers/ProgramProductSynthesis"

export default {
  name: "ProgramSynthesis",
  components: { OfferSynthesis, ProgramProductSynthesis },
  props: {
    item: { type: Object, required: true },
    itemType: { type: String, required: true },
    productLineId: { type: Number },
  },
  computed: {
    ...mapGetters("offer", ["getOffer"]),
    ...mapGetters("productLine", ["getConfig"]),
    offerConfig() {
      // we take the config of a pl because they are common to all the pl of an offer
      return this.getConfig(
        this.getOffer(this.item.id).program_products[0].product_lines[0].id
      )
    },
  },
}
</script>

<style scoped>
.offer-no-details {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
