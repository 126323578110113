<template>
  <div class="wrapper wrapper-login h-100">
    <router-view></router-view>
    <login-footer></login-footer>
  </div>
</template>

<script>
import loginFooter from "@/components/login_footer"
export default {
  name: "NoLayout",
  components: {
    loginFooter,
  },
}
</script>
