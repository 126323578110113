<template>
  <div class="mb-4">
    <div class="program-offer__container">
      <div class="program-offer__img">
        <img :src="imageUrl(offer)" alt="Offer Img" />
      </div>
      <div class="program-offer__details">
        <div>
          <p class="font-size-13 mt-1 font-effra-light">
            {{ offerContent.description }}
          </p>
          <h3 class="font-size-25 text-uppercase font-gilroy-regular">
            {{ offerContent.name }}
          </h3>
        </div>
        <button
          class="text-uppercase program-offer__details--button font-size-14 mb-2 mr-3 font-roboto-regular letter-spacing-75"
          :data-cy="`detail-btn-${offer.id}`"
          @click.stop="onAccordionClick"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
    <div v-if="isAccordionOpen && !isLoading" class="program-offer__synthesis">
      <VerticalTab
        class="pl-3"
        :tabs="getTabs"
        @tab-change="setActiveTabIndex"
      ></VerticalTab>
      <ProgramSynthesis
        :item="currentItem"
        :item-type="currentItemType"
        :product-line-id="activeProductLineId"
      ></ProgramSynthesis>
    </div>
  </div>
</template>

<script>
import VerticalTab from "@/components/utils/VerticalTab"
import ImageMixin from "@/mixins/ImageMixin"
import { mapGetters } from "vuex"
import ProgramSynthesis from "@/components/programs/ProgramSynthesis"

export default {
  name: "ProgramOfferSheet",
  components: { VerticalTab, ProgramSynthesis },
  mixins: [ImageMixin],
  props: {
    offer: { type: Object, required: true },
  },
  data() {
    return {
      isAccordionOpen: false,
      isLoading: false,
      activeTabIndex: 0,
      activeProductLineId: 0,
    }
  },
  computed: {
    ...mapGetters("config", ["getCurrentLang"]),
    offerContent() {
      return this.offer.offer_contents.find((content) => content.type === "B2C")
    },
    buttonText() {
      return this.isAccordionOpen
        ? this.$t("programs.close")
        : this.$t("programs.details")
    },
    getTabs() {
      let tabs = []
      const offerLabel =
        this.getCurrentLang === "fr"
          ? `${this.$t("verticaltabs.offer_label")} ${this.offer.name}`
          : `${this.offer.name} ${this.$t("verticaltabs.offer_label")}`
      tabs.push({
        type: "offer",
        label: offerLabel,
        item: this.offer,
        isSubTab: false,
      })
      this.offer.program_products.forEach((pp) => {
        let produtLines = []
        if (pp.product_lines.length > 1) {
          produtLines = [...pp.product_lines]
        }
        tabs.push({
          type: "program_product",
          label: pp.name,
          item: pp,
          isSubTab: true,
          productLines: produtLines,
        })
      })
      return tabs
    },
    currentItem() {
      return this.getTabs[this.activeTabIndex].item
    },
    currentItemType() {
      return this.getTabs[this.activeTabIndex].type
    },
  },
  methods: {
    imageUrl(offer) {
      const offerContent = offer.offer_contents.find(
        (content) => content.type === "B2C"
      )
      return offerContent?.image
        ? this.fitToSizeWithAspectRatioRetained(offerContent.image, 130, 110)
        : "https://via.placeholder.com/130x110"
    },
    async onAccordionClick() {
      this.isAccordionOpen = !this.isAccordionOpen
      this.isLoading = true
      await this.$store.dispatch("productLine/getCoefficientCriteria", {
        id: this.offer.program_products[0].product_lines[0].id,
      })
      this.isLoading = false
      this.activeTabIndex = 0
    },
    setActiveTabIndex(tabIndex, productLineId) {
      this.activeTabIndex = tabIndex
      this.activeProductLineId = productLineId
    },
  },
}
</script>

<style scoped lang="scss">
.program-offer__container {
  display: grid;
  grid-template-columns: 130px auto;
  border-left: 4px solid $body-color;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  margin-bottom: 3px;
  background: white;
}
.program-offer__img img {
  padding: 0.5rem;
  width: 130px;
  height: 110px;
  overflow: hidden;
}
.program-offer__details {
  display: flex;
  padding-left: 14px;
  justify-content: space-between;
  p:last-child {
    margin-bottom: 0;
  }
  .program-offer__details--button {
    align-self: flex-end;
    border: 0.5px solid $light-grey;
    border-radius: 5px;
    padding: 2px 20px;
    background-color: white;
    color: $light-grey;
  }
}
.program-offer__synthesis {
  display: grid;
  grid-template-columns: 2fr 4fr;
  column-gap: 0.8rem;
  background-color: $white;
  padding-top: 50px;
  padding-bottom: 20px;
}
</style>
