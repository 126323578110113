<template>
  <div class="content-souscription">
    <div class="container page-souscription">
      <div class="container offer_souscription-card">
        <!--    Subscription modals   -->
        <modalConfirmation
          v-if="showSubscriptionConfirmationModal"
          :message="$t('subscription.modal_subscription_confirmation_body')"
          :title="$t('modal_default_title')"
          data-cy="modal-subscription-confirmation"
          @close="showSubscriptionConfirmationModal = false"
          @confirm="subscribe"
        ></modalConfirmation>
        <modalFeedback
          v-if="showSubscriptionEmailSentModal"
          :message="$t('subscription.modal_subscription_email_sent_body')"
          :title="$t('modal_default_title')"
          data-cy="modal-subscription-email-sent"
          @close="showSubscriptionEmailSentModal = false"
        ></modalFeedback>
        <!--    Send email confirmation modals   -->
        <modalConfirmation
          v-if="showSendEmailConfirmationModal"
          :message="$t('subscription.modal_email_confirmation_body')"
          :title="$t('modal_default_title')"
          data-cy="modal-email-confirmation"
          @close="showSendEmailConfirmationModal = false"
          @confirm="sendEmail"
        ></modalConfirmation>
        <modalFeedback
          v-if="showEmailSentModal"
          :message="$t('subscription.modal_email_sent_body')"
          :title="$t('modal_default_title')"
          data-cy="modal-email-sent"
          @close="showEmailSentModal = false"
        ></modalFeedback>
        <spinner v-if="showSpinner" class="mt-5 mb-5"></spinner>
        <div v-else>
          <div
            v-if="currentSale && beneficiary"
            class="row block-souscription"
            data-cy="current-sale-container"
          >
            <!-- beneficiary -->
            <div class="col-4 col-xl-3">
              <span class="small medium-gray">{{ $t("subscription.customer") }}</span>
              <div class="souscription-infos" data-cy="beneficiary-data">
                <strong
                  class="extra-large primary-color"
                  data-cy="beneficiary-firstname"
                >
                  {{ beneficiary.user.first_name }}
                  <span class="last_name" data-cy="beneficiary-lastname">
                    {{ beneficiary.user.last_name }}
                  </span>
                </strong>
                {{ currentSale.beneficiary.address_1 }}
                <br />
                {{ beneficiary.zip_code }}
                {{ beneficiary.city }}
                <br />
                {{ beneficiary.phone }}
                <br />
                {{ beneficiary.mobile }}
                <br />
                {{ beneficiary.user.email }}
              </div>
            </div>
            <!-- vehicle -->
            <div class="col-8 col-xl-9 souscription-vehicule" data-cy="vehicle-data">
              <span class="small medium-gray">{{ $t("vehicle") }}</span>
              <div class="row">
                <div class="col">
                  <div class="souscription-infos">
                    <strong
                      class="extra-large primary-color"
                      data-cy="vehicle-make-model"
                    >
                      {{ currentSale.vehicle.make }} {{ currentSale.vehicle.model }}
                    </strong>
                    {{ currentSale.vehicle.version }}
                    <br />
                  </div>
                </div>
                <div class="col">
                  <div class="souscription-infos">
                    <span v-if="currentSale.vehicle.plate">
                      {{ $t("plate") }} :
                      <strong>{{ currentSale.vehicle.plate }}</strong>
                    </span>
                    <br />
                    <span v-if="currentSale.vehicle.vin">
                      {{ $t("VIN") }} :
                      <strong>{{ currentSale.vehicle.vin }}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- offers -->
          <div
            v-if="monthlyPaymentFrequencyOffers.length > 0"
            class="souscription-produits"
            data-cy="monthly-payment-frequency-offers"
          >
            <SubscribedOffersPrices
              :sale="currentSale"
              :offers="monthlyPaymentFrequencyOffers"
              :is-payment-frequency-monthly="true"
            ></SubscribedOffersPrices>
          </div>
          <div
            v-if="cashPaymentFrequencyOffers.length > 0"
            class="souscription-produits"
            data-cy="cash-payment-frequency-offers"
          >
            <SubscribedOffersPrices
              :sale="currentSale"
              :offers="cashPaymentFrequencyOffers"
              :is-payment-frequency-monthly="false"
            ></SubscribedOffersPrices>
          </div>
          <div class="souscription-bottom row justify-content-between">
            <div class="col">
              <div class="row align-items-center">
                <div class="col">
                  <span class="save-infos small medium-gray">
                    {{ $t("subscription.save_help") }}
                  </span>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="row">
                <div class="col-auto">
                  <button
                    v-if="isSubscriptionInProgressOrDone"
                    class="btn btn-success click-disabled"
                    data-cy="subscribe-button"
                    @click.prevent=""
                  >
                    {{ $t("subscription.subscription_sent") }}
                  </button>
                  <button
                    v-else
                    class="btn btn-primary"
                    data-cy="subscribe-button"
                    :disabled="
                      isLoading || !currentSale.product_lines.length || isSharingSpace
                    "
                    data-toggle="modal"
                    data-target="#modalConfirmation"
                    @click.prevent="submit"
                  >
                    {{ $t("button.subscribe") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!isSubscriptionInProgressOrDone && !showSpinner"
        class="nav-bottom-bar"
      >
        <div class="text-left">
          <button
            class="text-uppercase font-size-14 font-roboto-medium nav-button"
            data-cy="subscription-previous-btn"
            @click="$router.go(-1)"
          >
            {{ $t("button.previous") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modalConfirmation from "@/components/modal/ModalConfirmation"
import modalFeedback from "@/components/modal/ModalFeedback"
import { mapGetters } from "vuex"
import SubscribedOffersPrices from "@/components/subscription/SubscribedOffersPrices"

export default {
  name: "Subscription",
  components: {
    modalConfirmation,
    modalFeedback,
    SubscribedOffersPrices,
  },
  props: {
    saleId: { type: [Number, String], required: true },
    sharedToken: { type: String, default: "" },
  },
  data() {
    return {
      showSubscriptionConfirmationModal: false,
      showSubscriptionEmailSentModal: false,
      showEmailSentModal: false,
      showSendEmailConfirmationModal: false,
      isSubscriptionInProgressOrDone: false,
      isEmailSent: false,
      isLoading: true,
      beneficiary: null,
    }
  },
  computed: {
    ...mapGetters("sale", ["currentSale"]),
    cashPaymentFrequencyOffers() {
      let offerIds = []
      return this.currentSale.product_lines.reduce((offers, productLine) => {
        if (!productLine.offer.is_payment_frequency_monthly) {
          if (!offerIds.includes(productLine.offer.id)) {
            offerIds.push(productLine.offer.id)
            offers.push(productLine.offer)
          }
        }
        return offers
      }, [])
    },
    monthlyPaymentFrequencyOffers() {
      let offerIds = []
      return this.currentSale.product_lines.reduce((offers, productLine) => {
        if (productLine.offer.is_payment_frequency_monthly) {
          if (!offerIds.includes(productLine.offer.id)) {
            offerIds.push(productLine.offer.id)
            offers.push(productLine.offer)
          }
        }
        return offers
      }, [])
    },
    showSpinner() {
      return this.isLoading
    },
    isSharingSpace() {
      return this.sharedToken !== ""
    },
  },
  async created() {
    try {
      await this.$store.dispatch("sale/getSubscriptionSale", this.saleId)
      this.beneficiary = this.currentSale.beneficiary
      // Disable subscription and email-sending if the subscription procedure is in progress or already done
      if (
        this.currentSale.current_status === "DOC_SENT" ||
        this.currentSale.current_status === "SUBSCRIBED"
      ) {
        this.isSubscriptionInProgressOrDone = true
        this.isEmailSent = true
      }

      // Compute pricing
      await this.$store.dispatch(
        "sale/retrieveProductLinesPricing",
        this.currentSale.product_lines
      )
    } catch (e) {
      // TODO manage compute pricing errors
      // (Should not happen at this stage as step 2 is OK)
      console.log("Error", e)
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    submit() {
      this.showSubscriptionConfirmationModal = true
    },
    displayEmailModal() {
      this.showSendEmailConfirmationModal = true
    },
    async sendEmail() {
      this.showSendEmailConfirmationModal = false
      this.isLoading = true
      try {
        await this.$store.dispatch("sale/sendEmail", this.saleId)
      } catch (e) {
        console.log("Error", e)
      } finally {
        this.isLoading = false
      }
      this.showEmailSentModal = true
      this.isEmailSent = true
    },
    async subscribe() {
      this.showSubscriptionConfirmationModal = false
      this.isLoading = true
      try {
        await this.$store.dispatch("sale/subscribe", this.saleId)
        this.showSubscriptionEmailSentModal = true
        this.isSubscriptionInProgressOrDone = true
        this.isEmailSent = true
        await this.$store.dispatch("basket/reset")
      } catch (e) {
        console.log("Subscription failed")
      }
      this.isLoading = false
    },
  },
}
</script>

<style scoped>
.click-disabled {
  cursor: not-allowed !important;
}
.last_name {
  text-transform: capitalize;
}
.big-font {
  font-size: 25px;
  position: relative;
  top: 1px;
}

.xl-font {
  font-size: 30px;
  position: relative;
  top: 1px;
}
</style>
