export default {
  extension_garantie_vn: {
    object_1: "icon-check-square",
    coverage_1: "icon-cog",
    coverage_2: "icon-wrench",
    coverage_3: "icon-bottle",
  },
  extension_garantie_vn_elec: {
    object_1: "icon-check-square",
    coverage_1: "icon-cog",
    coverage_2: "icon-wrench",
    coverage_3: "icon-bottle",
  },
  garantie_vo: {
    object_1: "icon-check-square",
    coverage_1: "icon-cog",
    coverage_2: "icon-wrench",
    coverage_3: "icon-bottle",
  },
  rachat_franchise: {
    coverage_1: "icon-window",
    coverage_2: "icon-key",
    coverage_3: "icon-car2",
    coverage_4: "icon-circle",
  },
  gap: {
    coverage_1: "icon-arrow-wave",
    coverage_2: "icon-fire",
    coverage_3: "icon-car2",
  },
  gap_finance: {
    coverage_1: "icon-arrow-wave",
    coverage_2: "icon-fire",
    coverage_3: "icon-car2",
  },
  vitrerie_eclairage: {
    coverage_1: "icon-flip-horizontal",
    coverage_2: "icon-flip-horizontal",
    coverage_3: "icon-flip-horizontal",
    coverage_4: "icon-flip-horizontal",
  },
  interior_care: {
    coverage_1: "icon-car2",
    coverage_2: "icon-car2",
    coverage_3: "icon-car2",
  },
  body_care: {
    coverage_1: "icon-car2",
    coverage_2: "icon-car2",
    coverage_3: "icon-car2",
  },
  maintenance: {
    coverage_1: "icon-cog",
    coverage_2: "icon-wrench",
    coverage_3: "icon-bottle",
  },
  garantie_perfo_batterie: {
    coverage_1: "icon-cog",
    coverage_2: "icon-wrench",
  },
}
