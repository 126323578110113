<template>
  <ModalBootstrap
    id-modal="modalConfirmation"
    class="criteria"
    :title="title"
    modal-size="medium"
    data-cy="confirmation-modal"
    @close="onClose"
  >
    <template #body>
      <div class="mx-2 mb-2 d-flex flex-column justify-content-center">
        <div class="my-5 text-center w-100 font-size-16">{{ message }}</div>

        <div class="row justify-content-center">
          <div class="col-md-6 px-2 text-center">
            <button
              class="btn btn-primary w-75 px-1 mb-1"
              data-cy="cancel-button"
              @click.prevent="onClose"
            >
              {{ $t("modal_cancel") }}
            </button>
          </div>
          <div class="col-md-6 px-2 text-center">
            <button
              class="btn btn-primary w-75 px-1 mb-1"
              data-cy="confirm-button"
              @click.prevent="onConfirm"
            >
              {{ $t("modal_confirm") }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"

export default {
  name: "ModalConfirmation",
  components: { ModalBootstrap },
  props: ["message", "title"],
  methods: {
    onClose() {
      this.$emit("close")
    },
    onConfirm() {
      this.$emit("confirm")
    },
  },
}
</script>
