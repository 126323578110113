<template>
  <div class="compte-parc">
    <span class="small text-uppercase">
      {{ $t("stock_files") }}
    </span>
    <div class="row no-gutters compte-head">
      <div class="col">
        <div class="row">
          <div class="col-9 col-lg-10">
            <span class="small medium-gray">
              {{ $t("vehicle") }}
            </span>
          </div>
          <div class="col-3 col-lg-2">
            <span class="small medium-gray">
              {{ $t("ref") }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-auto"></div>
    </div>
    <ul>
      <li>
        <div class="row align-items-center">
          <div class="col">
            <div class="row">
              <div class="col-9 col-lg-10">
                <span>
                  <strong>TOYOTA Auris</strong>
                  Wagon 1.8 Hybride 99...
                </span>
              </div>
              <div class="col-3 col-lg-2">
                <span>785465-25A</span>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/details.png" alt="" />
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/trash.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row align-items-center">
          <div class="col">
            <div class="row">
              <div class="col-9 col-lg-10">
                <span>
                  <strong>AUDI Q2</strong>
                  1.4 TFSI 150 COD S LINE S TRONIC...
                </span>
              </div>
              <div class="col-3 col-lg-2">
                <span>465358-65D</span>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/details.png" alt="" />
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/trash.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row align-items-center">
          <div class="col">
            <div class="row">
              <div class="col-9 col-lg-10">
                <span>
                  <strong>VOLKSWAGEN T-ROC</strong>
                  2.0 TDI 150 CARAT...
                </span>
              </div>
              <div class="col-3 col-lg-2">
                <span>825467-89E</span>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/details.png" alt="" />
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/trash.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row align-items-center">
          <div class="col">
            <div class="row">
              <div class="col-9 col-lg-10">
                <span>
                  <strong>TOYOTA YARIS III</strong>
                  100H HYBRID DYNAMIC 5P...
                </span>
              </div>
              <div class="col-3 col-lg-2">
                <span>785465-56B</span>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/details.png" alt="" />
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/trash.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row align-items-center">
          <div class="col">
            <div class="row">
              <div class="col-9 col-lg-10">
                <span>
                  <strong>AUDI A3 III</strong>
                  SPORTBACK 1.6 TDI 110 BUSINESS...
                </span>
              </div>
              <div class="col-3 col-lg-2">
                <span>465658-98A</span>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/details.png" alt="" />
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/trash.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="text-right">
      <a class="text-btn" href="">
        {{ $t("add") }}
        <span class="btn btn-icon btn-icon-sm btn-primary ml-2">
          <img src="/img/icons/plus-alt.png" alt="" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Stock",
}
</script>
