<template>
  <ModalBootstrap
    id-modal="modalVehicleSearch"
    class="modal-vehicles"
    :title="$t('vehicleChoice.modal_title')"
    modal-size="extraLarge"
    data-cy="presales-vehicle-search-modal"
    @close="onClose"
  >
    <template #body>
      <spinner v-if="isLoading" class="mt-5"></spinner>
      <div v-else class="body-search">
        <div class="filters-list">
          <button v-if="btnReinit" class="btn btn-light-gray">
            <i class="pr-2 icon-sync" aria-hidden="true"></i>
            {{ $t("vehicleChoice.reset") }}
          </button>
          <div v-else class="title">
            {{ $t("vehicleChoice.title") }}
          </div>
          <div>
            <spinner v-if="areSelectableValuesUpdating" class="mt-5"></spinner>
            <FormRowSelect
              :label-inline="false"
              :label="$t('vehicleChoice.vehicle_type')"
              label-select-attr="label"
              name="vehicle_type"
              :select-options="listVehicleTypesSelectableValues"
              :selected-option.sync="filter.isVn"
              @update:selected-option="onUpdateVehicleTypes"
            />
            <FormRowSelect
              :label-inline="false"
              :label="$t('vehicleChoice.make')"
              label-select-attr="label"
              name="make"
              :multiple="true"
              :allow-empty="true"
              :select-options="listMakesSelectableValues"
              :selected-option.sync="filter.makes"
              @update:selected-option="onUpdateMakes"
            />
            <FormRowSelect
              :label-inline="false"
              :label="$t('vehicleChoice.model')"
              label-select-attr="label"
              name="model"
              :multiple="true"
              :allow-empty="true"
              :select-options="listModelsSelectableValues"
              :selected-option.sync="filter.models"
              @update:selected-option="onUpdateModels"
            />
            <FormRowSelect
              :label-inline="false"
              :label="$t('vehicleChoice.trim_level')"
              label-select-attr="label"
              name="trim_level"
              :multiple="true"
              :allow-empty="true"
              :select-options="listTrimLevelsSelectableValues"
              :selected-option.sync="filter.trimLevels"
              @update:selected-option="onUpdateTrimLevels"
            />
            <FormRowSelect
              :label-inline="false"
              :label="$t('vehicleChoice.year')"
              label-select-attr="label"
              name="year"
              :multiple="true"
              :allow-empty="true"
              :select-options="listYearsSelectableValues"
              :selected-option.sync="filter.years"
              @update:selected-option="onUpdateYears"
            />
            <FormRowSelect
              :label-inline="false"
              :label="$t('vehicleChoice.power')"
              label-select-attr="label"
              name="power"
              :multiple="true"
              :allow-empty="true"
              :select-options="listPowersSelectableValues"
              :selected-option.sync="filter.powers"
              @update:selected-option="onUpdatePowers"
            />
            <FormRowSelect
              :label-inline="false"
              :label="$t('vehicleChoice.cylinders')"
              label-select-attr="label"
              name="cylinder"
              :multiple="true"
              :allow-empty="true"
              :select-options="listCylindersSelectableValues"
              :selected-option.sync="filter.cylinders"
              @update:selected-option="onUpdateCylinders"
            />
            <FormRowSelect
              :label-inline="false"
              :label="$t('vehicleChoice.engine_type')"
              label-select-attr="label"
              name="engine_type"
              :multiple="true"
              :allow-empty="true"
              :select-options="listEngineTypesSelectableValues"
              :selected-option.sync="filter.engineTypes"
              @update:selected-option="onUpdateEngineTypes"
            />
          </div>
        </div>
        <div class="vehicles-list">
          <div v-if="listVehicles.length > 0" class="vehicles" data-cy="vehicles-list">
            <button
              v-if="vehicleInfos"
              class="btn btn-light-gray"
              data-cy="vehicle-show-all"
              @click="showAllVehicles"
            >
              <i class="pr-2 icon-chevron-left" aria-hidden="true"></i>
              {{ $t("vehicleChoice.back") }}
            </button>
            <div
              v-for="vehicle in listVehicles"
              :key="vehicle.id"
              class="vehicle"
              :class="{ 'hover-actived': !vehicleInfos }"
              :data-cy="`vehicle-${vehicle.id}`"
              @click="showVehicleInfos(vehicle)"
            >
              <img :src="vehicle.logo" alt="" />
              <div class="infos">
                <div class="model" :data-cy="`vehicle-model-${vehicle.id}`">
                  {{ vehicle.local_model }}
                </div>
                <div class="badge badge-pill badge-light">
                  {{ $t("vehicleChoice.infos_to_complete") }}
                </div>
              </div>
              <div class="details">
                <div class="finition" :data-cy="`vehicle-trim-level-${vehicle.id}`">
                  {{ vehicle.local_trim_level[0] }}
                </div>
                <div class="power" :data-cy="`vehicle-power-${vehicle.id}`">
                  {{ vehicle.fiscal_hp[0] }} {{ $t("vehicleChoice.fiscal_hp") }}
                </div>
                <div class="year" :data-cy="`vehicle-year-${vehicle.id}`">
                  {{ vehicle.model_year }}
                </div>
              </div>
              <div v-if="vehicleInfos" class="form-infos" data-cy="vehicle-infos">
                <u>
                  {{ $t("vehicleChoice.infos.additional_information") }}
                </u>
                <div class="infos-selects">
                  <FormRowSelect
                    class="input-infos"
                    :label-inline="false"
                    :label="$t('vehicleChoice.infos.cylinders_number')"
                    label-select-attr="label"
                    name="cylinders_number"
                    :select-options="{}"
                  />
                  <FormRowSelect
                    class="input-infos"
                    :label-inline="false"
                    :label="$t('vehicleChoice.infos.tyre_mounting')"
                    label-select-attr="label"
                    name="tyre_mounting"
                    :select-options="{}"
                  />
                  <FormRowSelect
                    class="input-infos"
                    :label-inline="false"
                    :label="$t('vehicleChoice.infos.manufacturer_warranty')"
                    label-select-attr="label"
                    name="manufacturer_warranty"
                    :select-options="{}"
                  />
                </div>
              </div>
            </div>
            <button
              v-if="vehicleInfos"
              class="btn btn-primary ml-auto"
              @click="onValidate"
            >
              {{ $t("vehicleChoice.infos.validate") }}
            </button>
          </div>
          <div v-else>{{ $t("vehicleChoice.no_vehicle_found") }}</div>
          <div
            v-if="hasPagination && !vehicleInfos"
            class="pagination"
            data-cy="vehicle-pagination"
          >
            <div class="label-page">{{ $t("vehicleChoice.page") }}</div>
            <div
              class="page"
              :class="{ current: currentPage === 1 }"
              @click="goToPage(1)"
            >
              1
            </div>
            <div
              class="page"
              :class="{ current: currentPage === 2 }"
              data-cy="vehicle-pagination-2"
              @click="goToPage(2)"
            >
              2
            </div>
            <div
              v-if="hasAThirdPagePagination"
              class="page"
              :class="{ current: currentPage === 3 }"
              @click="goToPage(3)"
            >
              3
            </div>
          </div>
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"
import { mapGetters } from "vuex"

export default {
  name: "ModalVehicleSearch",
  components: {
    ModalBootstrap,
  },
  data() {
    return {
      btnReinit: false,
      vehicleInfos: false,
      isLoading: true,
      currentPage: 1,
      filter: {
        isVn: "false",
        makes: [],
        models: [],
        trimLevels: [],
        years: [],
        powers: [],
        cylinders: [],
        engineTypes: [],
      },
    }
  },
  computed: {
    ...mapGetters("searchVehicleModal", [
      "listVehicleTypesSelectableValues",
      "listMakesSelectableValues",
      "listModelsSelectableValues",
      "listTrimLevelsSelectableValues",
      "listYearsSelectableValues",
      "listPowersSelectableValues",
      "listCylindersSelectableValues",
      "listEngineTypesSelectableValues",
      "areSelectableValuesUpdating",
      "listVehicles",
      "hasPagination",
      "hasAThirdPagePagination",
    ]),
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch("searchVehicleModal/resetFilters")
    await this.$store.dispatch("searchVehicleModal/closeVehicleInfos")
    await this.$store.dispatch("searchVehicleModal/initSelectablesValues")
    this.isLoading = false
  },
  methods: {
    onValidate() {
      this.$emit("validate")
    },
    onClose() {
      this.$emit("close")
    },
    async onUpdateVehicleTypes() {
      await this.$store.dispatch(
        "searchVehicleModal/updateVehicleTypesFilter",
        this.filter.isVn
      )
    },
    onUpdateMakes() {
      this.$store.dispatch("searchVehicleModal/updateMakesFilter", this.filter.makes)
    },
    onUpdateModels() {
      this.$store.dispatch("searchVehicleModal/updateModelsFilter", this.filter.models)
    },
    onUpdateTrimLevels() {
      this.$store.dispatch(
        "searchVehicleModal/updateTrimLevelsFilter",
        this.filter.trimLevels
      )
    },
    onUpdateYears() {
      this.$store.dispatch("searchVehicleModal/updateYearsFilter", this.filter.years)
    },
    onUpdatePowers() {
      this.$store.dispatch("searchVehicleModal/updatePowersFilter", this.filter.powers)
    },
    onUpdateCylinders() {
      this.$store.dispatch(
        "searchVehicleModal/updateCylindersFilter",
        this.filter.cylinders
      )
    },
    onUpdateEngineTypes() {
      this.$store.dispatch(
        "searchVehicleModal/updateEngineTypesFilter",
        this.filter.engineTypes
      )
    },
    showVehicleInfos(vehicle) {
      this.$store.dispatch("searchVehicleModal/showVehicleInfos", vehicle.id)
      this.$store.dispatch("vehicle/setSelectedVehicle", vehicle)
      this.vehicleInfos = true
    },
    showAllVehicles() {
      this.$store.dispatch("searchVehicleModal/closeVehicleInfos")
      this.vehicleInfos = false
    },
    async goToPage(pageNumber) {
      this.isLoading = true
      await this.$store.dispatch(
        "searchVehicleModal/displaySpecificPageVehicles",
        pageNumber
      )
      this.currentPage = pageNumber
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-vehicles {
  .body-search {
    display: grid;
    grid-template-columns: 22% 78%;
    .filters-list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px 20px;
      background: $grey6;
      button {
        min-width: auto;
        width: 100%;
        i {
          font-weight: 600;
        }
      }
      .title {
        font-family: $font-family-title;
        font-size: 26px;
        line-height: 31px;
      }
      label {
        text-transform: uppercase;
      }
    }
    .vehicles-list {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;
      padding: 10px 10px 16px 10px;
      .vehicles {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      .vehicle {
        display: grid;
        grid-template-areas:
          "img infos"
          "img details"
          "form-infos form-infos";
        grid-template-columns: 18% auto;
        column-gap: 10px;
        padding: 10px;
        border: 1px solid $table-border-color;
        &.hover-actived {
          cursor: pointer;
          &:hover {
            background: rgba($bleuc50, 0.5);
          }
        }
        img {
          grid-area: img;
          justify-self: center;
          align-self: center;
          max-width: 100px;
        }
        .infos {
          grid-area: infos;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid $caareagray;
          .model {
            color: $bleuc700;
            font-size: 24px;
            letter-spacing: 0.15px;
            line-height: 28px;
          }
        }
        .details {
          grid-area: details;
          display: grid;
          grid-template-areas:
            "finition power"
            "year engine";
          justify-content: space-between;
          gap: 4px;
          color: $medium-gray;
          font-size: 16px;
          letter-spacing: 0.15px;
          line-height: 19px;
          .finition {
            grid-area: finition;
          }
          .power {
            grid-area: power;
          }
          .year {
            grid-area: year;
          }
          .engine {
            grid-area: engine;
          }
        }
        .form-infos {
          grid-area: form-infos;
          margin: 10px -5px -5px;
          padding: 16px 10px 24px;
          background: $bleuc50;
          u {
            font-family: $font-family-title;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.15px;
          }
          .infos-selects {
            display: flex;
            justify-content: space-around;
          }
        }
      }
    }
    .pagination {
      gap: 8px;
      padding: 4px 16px;
      background: rgba($bleuc50, 0.3);
      font-family: "Effra";
      font-size: 13px;
      .label-page {
        letter-spacing: 0.25px;
      }
      .page {
        color: #9e9fbb;
        cursor: pointer;
        &:hover {
          color: inherit;
        }
        &.current {
          color: inherit;
          cursor: inherit;
        }
      }
    }
  }
}
</style>
