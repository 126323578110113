import Vue from "vue"
import Router from "vue-router"
import guards from "@/router/guards"

// views
import Cypress from "@/views/Cypress"
import Vehicle from "@/views/subscription/Vehicle"
import SearchIndex from "@/views/subscription/SearchIndex"
import PlateSearch from "@/views/subscription/PlateSearch"
import ManualSearch from "@/views/subscription/ManualSearch"
import VinSearch from "@/views/subscription/VinSearch"
import Products from "@/views/subscription/Products"
import Programs from "@/views/Programs"
import Customer from "@/views/subscription/Customer"
import Subscription from "@/views/subscription/Subscription"
import Admin from "@/views/admin/admin"
import Sales from "@/views/admin/sales"
import Home from "@/views/Home"

// containers
import NoContainer from "@/containers/no_container"
import DefaultContainer from "@/containers/default_container"
import SharingContainer from "@/containers/SharingContainer"

// error pages
import Page403 from "@/views/errors/page_403"
import Page404 from "@/views/errors/page_404"
import Page500 from "@/views/errors/page_500"

Vue.use(Router)

const routes = [
  {
    path: "/:lang",
    name: "lang",
    beforeEnter: guards.beforeEnter,
    redirect: { name: "privateZone" },
    meta: { breadcrumb: "breadcrumb.home" },
    component: {
      render(c) {
        return c("router-view")
      },
    },
    children: [
      {
        path: "",
        name: "privateZone",
        beforeEnter: guards.requireAuth,
        redirect: { name: "home" },
        component: DefaultContainer,
        children: [
          {
            path: "",
            name: "home",
            component: Home,
          },
          {
            path: "vehicle",
            name: "vehicle",
            beforeEnter: guards.requireAcceptedCookies,
            meta: { requireWizard: true, breadcrumb: "breadcrumb.select_a_vehicle" },
            component: SearchIndex,
          },
          {
            path: "vehicle/plate-search",
            name: "plateSearch",
            beforeEnter: guards.requireAcceptedCookies,
            meta: { requireWizard: true, breadcrumb: "breadcrumb.select_a_vehicle" },
            component: PlateSearch,
          },
          {
            path: "vehicle/manual-search",
            name: "manualSearch",
            beforeEnter: guards.requireAcceptedCookies,
            meta: { requireWizard: true, breadcrumb: "breadcrumb.select_a_vehicle" },
            component: ManualSearch,
          },
          {
            path: "vehicle/vin-search",
            name: "vinSearch",
            beforeEnter: guards.requireAcceptedCookies,
            meta: { requireWizard: true, breadcrumb: "breadcrumb.select_a_vehicle" },
            component: VinSearch,
          },
          {
            path: "products/:saleId",
            name: "products",
            props: true,
            meta: { requireWizard: true, breadcrumb: "breadcrumb.select_an_offer" },
            component: Products,
          },
          {
            path: "programs",
            name: "programs",
            beforeEnter: guards.requireAcceptedCookies,
            props: true,
            meta: { breadcrumb: "breadcrumb.offer_list" },
            component: Programs,
          },
          {
            path: "customer/:saleId",
            name: "customer",
            beforeEnter: guards.requireAcceptedCookies,
            props: true,
            meta: {
              requireWizard: true,
              breadcrumb: "breadcrumb.complete_client_infos",
            },
            component: Customer,
          },
          {
            path: "subscription/:saleId",
            beforeEnter: guards.requireAcceptedCookies,
            name: "subscription",
            props: true,
            meta: { requireWizard: true, breadcrumb: "breadcrumb.subscription" },
            component: Subscription,
          },
          {
            path: "account",
            name: "admin",
            beforeEnter: guards.requireAcceptedCookies,
            meta: { breadcrumb: "my_account" },
            component: Admin,
          },
          {
            path: "sales",
            name: "sales",
            beforeEnter: guards.requireAcceptedCookies,
            meta: { breadcrumb: "my_sales" },
            component: Sales,
          },
        ],
      },
      {
        path: "",
        redirect: { name: "home" },
        name: "publicZone",
        component: NoContainer,
        children: [
          {
            beforeEnter: guards.logout,
            path: "logout",
            name: "logout",
          },
        ],
      },
      {
        path: "sharing/:sharedToken",
        name: "sharingZone",
        beforeEnter: guards.requireSharedTokenAuth,
        redirect: { name: "shared_home" },
        component: SharingContainer,
        children: [
          {
            path: "",
            name: "shared_home",
            component: Home,
          },
          {
            path: "programs",
            name: "shared_programs",
            beforeEnter: guards.requireAcceptedCookies,
            props: true,
            component: Programs,
          },
          {
            path: "vehicle/:saleId?",
            name: "shared_vehicle",
            beforeEnter: guards.requireAcceptedCookies,
            props: true,
            meta: { requireWizard: true },
            component: SearchIndex,
          },
          {
            path: "vehicle/plate-search",
            name: "shared_plate_search",
            props: true,
            beforeEnter: guards.requireAcceptedCookies,
            meta: { requireWizard: true },
            component: PlateSearch,
          },
          {
            path: "vehicle/manual-search",
            name: "shared_manual_search",
            props: true,
            beforeEnter: guards.requireAcceptedCookies,
            meta: { requireWizard: true },
            component: ManualSearch,
          },
          {
            path: "vehicle/vin-search",
            name: "shared_vin_search",
            props: true,
            beforeEnter: guards.requireAcceptedCookies,
            meta: { requireWizard: true },
            component: VinSearch,
          },
          {
            path: "products/:saleId",
            name: "shared_products",
            beforeEnter: guards.requireAcceptedCookies,
            props: true,
            meta: { requireWizard: true },
            component: Products,
          },
          {
            path: "customer/:saleId",
            name: "shared_customer",
            beforeEnter: guards.requireAcceptedCookies,
            props: true,
            meta: {
              requireWizard: true,
            },
            component: Customer,
          },
          {
            path: "subscription/:saleId",
            name: "shared_subscription",
            beforeEnter: guards.requireAcceptedCookies,
            props: true,
            meta: { requireWizard: true },
            component: Subscription,
          },
        ],
      },
      {
        path: "error",
        redirect: { name: "404" },
        name: "error",
        component: NoContainer,
        children: [
          {
            path: "403",
            name: "403",
            meta: { breadcrumb: "breadcrumb_403" },
            component: Page403,
          },
          {
            path: "404",
            name: "404",
            meta: { breadcrumb: "breadcrumb_404" },
            component: Page404,
          },
          {
            path: "500",
            name: "500",
            meta: { breadcrumb: "breadcrumb_500" },
            component: Page500,
          },
        ],
      },
      {
        path: "*",
        beforeEnter: guards.beforeEnter,
      },
    ],
  },
  {
    path: "*",
    beforeEnter: guards.beforeEnter,
  },
]

if (window.Cypress) {
  // add route for testing purpose
  routes[0].children[0].children.push({
    path: "cypress",
    name: "cypress",
    component: Cypress,
  })
  console.log("routes", routes)
}

routes[0].children[0].children.push({
  path: "cypress",
  name: "cypress",
  component: Cypress,
})

export default new Router({
  mode: "history",
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes,
})
