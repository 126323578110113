import RouterHelper from "@/helpers/router.helper.js"
export default {
  methods: {
    pushI18nRoute(route) {
      this.$router.push(RouterHelper.getI18nRoute(route))
    },
    setPageTitle(i18nKey) {
      let documentTitle = process.env.VUE_APP_MAIN_TITLE || "missing VUE_APP_MAIN_TITLE"
      documentTitle += " - " + this.$t(i18nKey)
      document.title = documentTitle
    },
  },
}
