<template>
  <div id="div-test">
    <div tabindex="0"></div>
    <div
      :id="idModal"
      class="modal fade"
      :class="{ show: show }"
      tabindex="-1"
      role="dialog"
      data-backdrop="false"
      @click="onClose"
      @keyup.esc="onClose"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        :class="getModalSizeClass"
        role="document"
        @click.stop
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" data-cy="modal-title">{{ title }}</h5>
            <button
              v-if="showCloseButton"
              type="button"
              class="modal-btn-close modal-cross icon-cross align-self-center"
              data-cy="modal-close-cross"
              @click="onClose"
            ></button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
              <slot name="body">
                <p>Modal body text goes here.</p>
              </slot>
            </div>
          </div>
          <div v-if="$slots.footer" class="modal-footer">
            <slot name="footer">
              <p>Modal footer text goes here.</p>
            </slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="{ show: show }"></div>
    <div tabindex="0"></div>
  </div>
</template>

<script>
export default {
  name: "ModalBootstrap",
  props: {
    idModal: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    showOnlyConfirmButton: {
      type: Boolean,
      default: false,
    },
    modalSize: {
      type: String,
      default: null,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    getModalSizeClass() {
      if (this.modalSize === "small") return "modal-sm"
      if (this.modalSize === "large") return "modal-lg"
      if (this.modalSize === "extraLarge") return "modal-xl"
      return null
    },
  },
  async mounted() {
    const el = document.body
    el.classList.add("modal-open")
    el.style["padding-right"] = "15px"
    // c'est moche mais je n'ai pas trouvé mieux (pour le moment) pour faire marcher
    // les transitions
    setTimeout(() => (this.show = true), 50)
  },
  destroyed() {
    const el = document.body
    el.classList.remove("modal-open")
    el.style["padding-right"] = null
  },
  methods: {
    onClose() {
      this.$emit("close")
    },
  },
}
</script>

<style scoped lang="scss">
.modal {
  display: block;
}

.modal-header {
  background-color: $primary;
  .modal-title {
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
  }
}
</style>
