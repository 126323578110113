<template>
  <div class="compte-utilisateurs">
    <span class="small text-uppercase">
      {{ $t("users_files") }}
    </span>
    <div class="row no-gutters compte-head">
      <div class="col">
        <div class="row">
          <div class="col-5 col-lg-6">
            <span class="small medium-gray">
              {{ $t("user") }}
            </span>
          </div>
          <div class="col-4 col-lg-3">
            <span class="small medium-gray">
              {{ $t("role") }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-auto">&nbsp;</div>
    </div>
    <ul>
      <li>
        <div class="row align-items-center">
          <div class="col">
            <div class="row">
              <div class="col-5 col-lg-6">
                <strong>Eric Chelli</strong>
              </div>
              <div class="col-4 col-lg-3">
                <span>Administrateur</span>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/details.png" alt="" />
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/trash.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row align-items-center">
          <div class="col">
            <div class="row">
              <div class="col-5 col-lg-6">
                <strong>Julien Marsan</strong>
              </div>
              <div class="col-4 col-lg-3">
                <span>-</span>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/details.png" alt="" />
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/trash.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row align-items-center">
          <div class="col">
            <div class="row">
              <div class="col-5 col-lg-6">
                <strong>Jean-Pierre Sarrazin</strong>
              </div>
              <div class="col-4 col-lg-3">
                <span>-</span>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="row">
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/details.png" alt="" />
                </button>
              </div>
              <div class="col-auto">
                <button class="btn btn-icon btn-icon-sm btn-primary">
                  <img src="/img/icons/trash.png" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="text-right">
      <a class="text-btn" href="">
        {{ $t("add") }}
        <span class="btn btn-icon btn-icon-sm btn-primary ml-2">
          <img src="/img/icons/plus-alt.png" alt="" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Users",
}
</script>
