<template>
  <div class="page-vehicle-search">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-9">
          <button
            class="btn btn-primary btn-small rounded font-size-14 font-roboto-medium"
            @click="onBackBtnClick"
          >
            {{ $t("vehicleChoice.back") }}
          </button>
          <div
            class="d-flex flex-column justify-content-between card-search bg-white mt-4 text-center"
          >
            <h1>{{ $t("vehicleSearch.plate.title") }}</h1>
            <div class="row justify-content-center text-center">
              <div class="col-3 input-center">
                <FormInput
                  v-model="plate"
                  name="searchType"
                  placeholder="XX-999-XX"
                  :max-length="17"
                  @keyboard-enter="onSubmitButton"
                />
              </div>
            </div>
            <ActionButton
              :button-text="$t('button.search')"
              button-data-cy="search-by-plate-btn"
              :disabled="plate === ''"
              @click-action="onSubmitButton"
            ></ActionButton>
            <div v-if="hasVehicles" class="vehicles-list">
              <VehiclesList
                :show-optional-dynamic-inputs="showOptionalDynamicInputs"
                :vehicle-type-options="vehicleTypeOptions"
                @dynamic-inputs-validated="onDynamicInputsValidated"
              ></VehiclesList>
            </div>
            <div v-else-if="hasSearchByPlateError" class="no-info mt-5">
              <div data-cy="no-info" class="mb-2 text-danger">
                {{ $t("vehicleSearch.no_info_on_this_vehicle") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from "@/components/ActionButton"
import VehiclesList from "../../components/subscription/VehiclesList"
import { mapGetters } from "vuex"
import VehicleSearchMixin from "../../components/mixins/VehicleSearchMixin"

export default {
  name: "PlateSearch",
  components: {
    ActionButton,
    VehiclesList,
  },
  mixins: [VehicleSearchMixin],
  props: {
    sharedToken: { type: String, default: "" },
  },
  data() {
    return {
      plate: "",
    }
  },
  computed: {
    ...mapGetters("sale", ["currentSaleId"]),
    ...mapGetters("searchVehicle", [
      "getSelectedVehicle",
      "getVehicleTypeFilterValue",
      "hasVehicles",
      "hasSearchByPlateError",
    ]),
  },
  async created() {
    await this.$store.dispatch("searchVehicle/resetVehicles")
    await this.$store.dispatch("searchVehicle/resetSelectedVehicle")
    await this.$store.dispatch("searchVehicle/resetSearchByPlateError")
  },
  methods: {
    async onSubmitButton() {
      if (this.plate !== "") {
        await this.$store.dispatch("searchVehicle/resetVehicles")
        await this.$store.dispatch("searchVehicle/resetSelectedVehicle")
        await this.$store.dispatch("searchVehicle/searchByPlate", this.plate)
      }
    },
    onBackBtnClick() {
      if (this.sharedToken) {
        this.$router.push({ name: "shared_vehicle" })
      } else {
        this.$router.push({ name: "vehicle" })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-vehicle-search {
  .card-search {
    gap: 24px;
    padding: 36px 0 72px;
    border-left: 4px solid $body-color;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
  }
  h1 {
    margin: 0;
    font-size: rem-calc(32);
    font-weight: 400;
  }
  h2 {
    margin: 0;
    font-size: rem-calc(24);
    font-weight: 400;
  }
  .no-info div {
    font-size: rem-calc(18);
  }
}

.vehicles-list {
  padding: 10px 10px 16px 10px;
}
</style>
