<template>
  <div class="page-clients">
    <ModalWarning
      v-if="hasSaleUpdateFailureModalToBeDisplayed"
      :title="getSaleUpdateFailureModalInfo.title"
      :reason="getSaleUpdateFailureModalInfo.reason"
      :detailed-reason="getSaleUpdateFailureModalInfo.detailedReason"
      :advice="getSaleUpdateFailureModalInfo.advice"
      @close="onCloseWarningModal"
    ></ModalWarning>
    <div class="row justify-content-center">
      <div class="container">
        <div class="offer_customer-card">
          <div class="offer_customer-title p-3">
            <span class="font-size-22 text-uppercase font-gilroy-regular">
              {{ $t("customer_infos.card_title") }}
            </span>
          </div>
          <form v-if="!isLoading" class="p-2 form-simple">
            <div class="pb-2">
              <div class="row">
                <div class="col">
                  <div class="form-group row">
                    <label
                      for="id-last_name"
                      class="col-sm-4 col-form-label offer_customer-label font-gilroy-regular"
                    >
                      {{ $t("placeholder.name") + "*" }}
                    </label>
                    <div class="col-sm-8">
                      <FormInput
                        v-model="form.data.last_name"
                        name="last_name"
                        :placeholder="$t('placeholder.name')"
                        :errors="getBeneficiaryErrors"
                        required
                      ></FormInput>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group row">
                    <label
                      for="id-first_name"
                      class="col-sm-4 col-form-label offer_customer-label font-gilroy-regular"
                    >
                      {{ $t("placeholder.first_name") + "*" }}
                    </label>
                    <div class="col-sm-8">
                      <FormInput
                        v-model="form.data.first_name"
                        name="first_name"
                        :placeholder="$t('placeholder.first_name')"
                        :errors="getBeneficiaryErrors"
                        required
                      ></FormInput>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group row">
                    <label
                      for="id-address_1"
                      class="col-sm-2 col-form-label offer_customer-label font-gilroy-regular"
                    >
                      {{ $t("placeholder.address") + "*" }}
                    </label>
                    <div class="col-sm-10">
                      <FormInput
                        v-model="form.data.address_1"
                        name="address_1"
                        :placeholder="$t('placeholder.address') + '*'"
                        :errors="getBeneficiaryErrors"
                        required
                      ></FormInput>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group row">
                    <label
                      for="id-address_2"
                      class="col-sm-2 col-form-label offer_customer-label font-gilroy-regular"
                    >
                      {{ $t("placeholder.complementary_address") }}
                    </label>
                    <div class="col-sm-10">
                      <FormInput
                        v-model="form.data.address_2"
                        name="address_2"
                        :placeholder="$t('placeholder.complementary_address')"
                        :errors="getBeneficiaryErrors"
                        required
                      ></FormInput>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group row">
                    <label
                      for="id-zip_code"
                      class="col-sm-4 col-form-label offer_customer-label font-gilroy-regular"
                    >
                      {{ $t("placeholder.zip_code") + "*" }}
                    </label>
                    <div class="col-sm-8">
                      <FormInput
                        v-model="form.data.zip_code"
                        name="zip_code"
                        :placeholder="$t('placeholder.zip_code')"
                        :errors="getBeneficiaryErrors"
                        required
                      ></FormInput>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group row">
                    <label
                      for="id-zip_code"
                      class="col-sm-4 col-form-label offer_customer-label font-gilroy-regular"
                    >
                      {{ $t("placeholder.city") + "*" }}
                    </label>
                    <div class="col-sm-8">
                      <FormInput
                        v-model="form.data.city"
                        name="city"
                        :placeholder="$t('placeholder.city')"
                        :errors="getBeneficiaryErrors"
                        required
                      ></FormInput>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group row">
                    <label
                      for="id-mobile"
                      class="col-sm-4 col-form-label offer_customer-label font-gilroy-regular"
                    >
                      {{ $t("placeholder.mobile") + "*" }}
                    </label>
                    <div class="col-sm-8">
                      <FormInput
                        v-model="form.data.mobile"
                        name="mobile"
                        :placeholder="$t('placeholder.mobile')"
                        :errors="getBeneficiaryErrors"
                        required
                      ></FormInput>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group row">
                    <label
                      for="id-phone"
                      class="col-sm-4 col-form-label offer_customer-label font-gilroy-regular"
                    >
                      {{ $t("placeholder.phone") }}
                    </label>
                    <div class="col-sm-8">
                      <FormInput
                        v-model="form.data.phone"
                        name="phone"
                        :placeholder="$t('placeholder.phone')"
                        :errors="getBeneficiaryErrors"
                      ></FormInput>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group row">
                    <label
                      for="id-email"
                      class="col-sm-4 col-form-label offer_customer-label font-gilroy-regular"
                    >
                      {{ $t("placeholder.email") + "*" }}
                    </label>
                    <div class="col-sm-8">
                      <FormInput
                        v-model="form.data.email"
                        name="email"
                        :placeholder="$t('placeholder.email')"
                        :errors="getBeneficiaryErrors"
                        required
                      ></FormInput>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group row">
                    <label
                      for="id-email_confirm"
                      class="col-sm-4 col-form-label offer_customer-label font-gilroy-regular"
                    >
                      {{ $t("placeholder.email_confirmation") + "*" }}
                    </label>
                    <div class="col-sm-8">
                      <FormInput
                        v-model="form.data.email_confirm"
                        name="email_confirm"
                        :placeholder="$t('placeholder.email_confirmation')"
                        :errors="getBeneficiaryErrors"
                        required
                      ></FormInput>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right">
                <span class="xx-small secondary-color">
                  {{ $t("common.required_fields") }}
                </span>
              </div>
              <div v-if="server_error">
                {{ server_error }}
              </div>
            </div>
          </form>
          <spinner v-else class="mt-5 mb-5"></spinner>
        </div>
        <div class="nav-bottom-bar">
          <div class="text-left">
            <button
              class="text-uppercase font-size-14 font-roboto-medium nav-button"
              data-cy="button-previous-step"
              @click="$router.go(-1)"
            >
              {{ $t("button.previous") }}
            </button>
          </div>
          <div class="text-right">
            <button
              data-cy="submit"
              class="text-uppercase font-size-14 font-roboto-medium nav-button"
              @click.prevent="onNextButtonClick"
            >
              {{ $t("next") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ModalWarning from "../../components/modal/ModalWarning"

export default {
  name: "Customer",
  components: { ModalWarning },
  props: {
    saleId: { type: [Number, String], required: true },
    sharedToken: { type: String, default: "" },
  },
  data: function () {
    return {
      isLoading: true,
      form: {
        data: {
          last_name: "",
          first_name: "",
          address_1: "",
          address_2: "",
          zip_code: "",
          city: "",
          mobile: "",
          phone: "",
          email: "",
          email_confirm: "",
        },
        errors: {},
      },
      server_error: null,
    }
  },
  computed: {
    ...mapGetters("sale", ["currentSale", "getBeneficiaryErrors"]),
    ...mapGetters("basket", ["hasItem"]),
    ...mapGetters("gui", [
      "hasSaleUpdateFailureModalToBeDisplayed",
      "getSaleUpdateFailureModalInfo",
    ]),
  },
  async created() {
    this.$store.dispatch("sale/resetBeneficiaryErrors")
    try {
      await this.$store.dispatch("sale/getSale", this.saleId)
      if (this.currentSale.beneficiary) {
        this.init_data()
      }
    } catch (e) {
      console.log("Error", e)
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    init_data() {
      this.form.data.last_name = this.currentSale.beneficiary.user.last_name
      this.form.data.first_name = this.currentSale.beneficiary.user.first_name
      this.form.data.address_1 = this.currentSale.beneficiary.address_1
      this.form.data.address_2 = this.currentSale.beneficiary.address_2
      this.form.data.zip_code = this.currentSale.beneficiary.zip_code
      this.form.data.city = this.currentSale.beneficiary.city
      this.form.data.mobile = this.currentSale.beneficiary.mobile
      this.form.data.phone = this.currentSale.beneficiary.phone || ""
      this.form.data.email = this.currentSale.beneficiary.user.email
      this.form.data.email_confirm = this.currentSale.beneficiary.user.email
    },
    async onNextButtonClick() {
      this.isLoading = true
      try {
        const payload = { ...this.form.data }
        await this.$store.dispatch("sale/createOrUpdateBeneficiary", payload)
        await this.$store.dispatch("sale/finalize")
        await this.goToNextPage()
      } catch (error) {
        console.error("create beneficiary error", error)
      } finally {
        this.isLoading = false
      }
    },
    async goToNextPage() {
      if (this.sharedToken !== "") {
        await this.$router.push({
          name: "shared_subscription",
          params: { sharedToken: this.sharedToken, saleId: this.saleId },
        })
      } else {
        await this.$router.push({
          name: "subscription",
          params: { saleId: this.saleId },
        })
      }
    },
    onCloseWarningModal() {
      this.$store.dispatch("gui/hideSaleUpdateFailureModal")
    },
  },
}
</script>
