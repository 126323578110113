<template>
  <div class="offer_total_price">
    <div class="offer_total_price-insert">
      <div class="text-uppercase font-gilroy-bold font-size-20">
        {{ $t("offers.total_price") }}
      </div>
      <div
        class="font-size-40 font-gilroy-bold"
        :data-cy="`offer-total-price-${offerId}`"
      >
        {{ formattedOfferPriceToDisplay }}
      </div>
      <div>
        <p class="font-size-12 mb-0">{{ $t("offers.ttc") }}</p>
        <span
          class="font-gilroy-bold font-size-22"
          :data-cy="`offer-${offerId}-details-price-monthly`"
        >
          {{ monthlyPayment }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "TotalPrice",
  props: {
    formattedOfferPriceToDisplay: {
      type: String,
      required: true,
    },
    offerId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapGetters("offer", ["isOfferWithMonthlyPayment"]),
    monthlyPayment() {
      return this.isOfferWithMonthlyPayment(this.offerId)
        ? this.$t("offers.by_month")
        : ""
    },
  },
}
</script>

<style scoped lang="scss">
.offer_total_price {
  display: grid;
  grid-template-columns: 7fr 5fr;
  .offer_total_price-insert {
    background-color: $blue-c50;
    grid-column: 2 / span 2;
    display: grid;
    grid-template-columns: 4fr 4fr 3fr;
    align-items: center;
    div {
      margin: 20px 0 20px 20px;
      &:last-child {
        margin-left: 0;
      }
    }
  }
}
</style>
