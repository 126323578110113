<template>
  <div class="page-search-index my-4">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-11 text-center">
          <h1>{{ $t("vehicleSearch.index.choose_vehicle_search_mode") }}</h1>
          <div class="container mt-4">
            <div class="row">
              <div class="col">
                <div
                  class="d-flex flex-column justify-content-between card-search bg-white h-100 py-5 px-4"
                >
                  <div class="bg-primary mx-n4">
                    <img
                      class="my-n3"
                      src="https://res.cloudinary.com/caarea/image/upload/v1675415370/cs/sales/recherche-immatriculation_h6nhau.jpg"
                      alt=""
                    />
                  </div>
                  <h2>{{ $t("vehicleSearch.index.by_plate_number") }}</h2>
                  <ActionButton
                    :button-text="$t('button.search')"
                    :button-class="['rounded']"
                    @click-action="onSearchByPlateClick"
                  ></ActionButton>
                </div>
              </div>
              <div class="col">
                <div
                  class="d-flex flex-column justify-content-between card-search bg-white h-100 py-5 px-4"
                >
                  <div class="bg-primary mx-n4">
                    <img
                      class="my-n3"
                      src="https://res.cloudinary.com/caarea/image/upload/v1675246890/cs/sales/recherche-manuelle_ncvqnz.jpg"
                      alt=""
                    />
                  </div>
                  <h2>{{ $t("vehicleSearch.index.by_manual_search") }}</h2>
                  <ActionButton
                    :button-text="$t('button.search')"
                    :button-class="['rounded']"
                    @click-action="onManualSearchClick"
                  ></ActionButton>
                </div>
              </div>
              <div class="col">
                <div
                  class="d-flex flex-column justify-content-between card-search bg-white h-100 py-5 px-4"
                >
                  <div class="bg-primary mx-n4">
                    <img
                      class="my-n3"
                      src="https://res.cloudinary.com/caarea/image/upload/v1675246890/cs/sales/numero-vin_nykijk.jpg"
                      alt=""
                    />
                  </div>
                  <h2>{{ $t("vehicleSearch.index.by_vin_number") }}</h2>
                  <ActionButton
                    :button-text="$t('button.search')"
                    :button-class="['rounded']"
                    @click-action="onSearchByVinClick"
                  ></ActionButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from "@/components/ActionButton.vue"

export default {
  name: "SearchIndex",
  components: { ActionButton },
  props: {
    sharedToken: { type: String, default: "" },
  },
  methods: {
    onSearchByPlateClick() {
      if (this.sharedToken) {
        this.$router.push({ name: "shared_plate_search" })
      } else {
        this.$router.push({ name: "plateSearch" })
      }
    },
    onManualSearchClick() {
      if (this.sharedToken) {
        this.$router.push({ name: "shared_manual_search" })
      } else {
        this.$router.push({ name: "manualSearch" })
      }
    },
    onSearchByVinClick() {
      if (this.sharedToken) {
        this.$router.push({ name: "shared_vin_search" })
      } else {
        this.$router.push({ name: "vinSearch" })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-search-index {
  h1 {
    font-size: rem-calc(36);
    font-weight: 400;
  }
  .card-search {
    gap: 32px;
  }
  h2 {
    font-weight: 400;
  }
}
</style>
