import criteria from "./criteria.json"
import breadcrumb from "./breadcrumb.json"
import verticaltabs from "./verticaltabs.json"
import programProductSynthesis from "./programProductSynthesis.json"
import button from "./button.json"
import offers from "./views/offers.json"
import programs from "./views/programs.json"
import memo from "./views/memo.json"
import sale from "./views/sale.json"
import caareavlib from "caarea-vlib/src/i18n/en"
import home from "./views/home.json"
import error from "./views/error.json"
import topbar from "./layouts/topbar.json"
import subscription from "./views/subscription.json"
import pricing from "./pricing.json"
import vehicleChoice from "./views/vehicleChoice.json"
import vehicleSearch from "./views/vehicleSearch.json"

export default {
  criteria,
  breadcrumb,
  verticaltabs,
  programProductSynthesis,
  caareavlib,
  offers,
  programs,
  memo,
  button,
  sale,
  home,
  error,
  topbar,
  subscription,
  pricing,
  vehicleChoice,
  vehicleSearch,
}
