<template>
  <div>
    <div class="offer_sheet-container mb-1" :data-cy="`offer-${offer.id}`">
      <div class="offer_sheet-img">
        <img :src="imageUrl" alt="Offer logo" />
      </div>
      <div class="offer_sheet-details">
        <div>
          <p
            :data-cy="`offer-description-${offer.id}`"
            class="offer_sheet-details-catchphrase font-size-13 mt-1 font-effra-light"
          >
            {{ offerContent.description }}
          </p>
          <h3
            :data-cy="`offer-name-${offer.id}`"
            class="font-size-25 text-uppercase font-gilroy-regular"
          >
            {{ offerContent.name }}
          </h3>
          <p v-if="isOfferNotTariffable" class="font-size-12 font-gilroy-regular">
            <span
              :data-cy="`offer-price-${offer.id}`"
              class="font-size-20 mr-1 bold font-gilroy-bold"
            >
              {{ $t("product_lines.price_failed") }}
            </span>
          </p>
          <p v-else-if="offerPrice" class="font-size-12 font-gilroy-regular">
            <span
              :data-cy="`offer-price-${offer.id}`"
              class="font-size-20 mr-1 bold font-gilroy-bold"
            >
              {{ formattedOfferPriceToDisplay }}
            </span>
            <span
              class="font-gilroy-regular font-size-12"
              :data-cy="`offer-${offer.id}-price-monthly`"
            >
              {{ monthlyPayment }}
            </span>
          </p>
        </div>
        <div class="align-self-end">
          <button
            v-if="isSubscribable"
            class="text-uppercase offer_sheet-details-button font-size-14 mb-2 mr-3 font-roboto-regular letter-spacing-75"
            :class="{ 'btn-more-infos': needMoreInfos }"
            :data-cy="`offer-details-button-${offer.id}`"
            @click="toggleAccordion"
          >
            {{ accordionButtonText }}
          </button>
        </div>
      </div>
      <div class="offer_sheet-button">
        <div v-if="offerPrice !== null || needMoreInfos">
          <button
            v-if="!isInBasket"
            :data-cy="`button-add-basket-${offer.id}`"
            class="text-uppercase font-size-14 font-roboto-medium letter-spacing-9"
            :disabled="isSubscribeButtonDisabled"
            @click="onAddToCart"
          >
            <span class="icon-cart font-size-16"></span>
            {{ $t("offers.subscribe") }}
          </button>
          <button
            v-else
            :data-cy="`button-del-basket-${offer.id}`"
            class="text-uppercase font-size-14 font-roboto-medium letter-spacing-9"
            @click="onDeleteFromCart"
          >
            <span class="icon-cross-circle font-size-16"></span>
            {{ $t("offers.cancel") }}
          </button>
        </div>
        <div v-if="needMoreInfos" class="more-infos" data-cy="more-info-needed-text">
          <span class="icon-notification-circle"></span>
          {{ $t("offers.need_additional_infos") }}
        </div>
      </div>
    </div>
    <div v-if="isAccordionOpen && !isOfferNotTariffable">
      <div class="offer_sheet-accordion pr-3" :class="{ 'nbCols-2': needMoreInfos }">
        <VerticalTab
          v-if="needMoreInfos"
          class="pl-3"
          :tabs="getTabs"
          @tab-change="setActiveTabIndex"
        ></VerticalTab>
        <div
          v-if="getTabs[vehicleInfoTabIndex].showTabContent"
          class="subscription-infos"
        >
          <u>{{ $t("offers.more_infos.additional_information") }}</u>
          <div class="infos-selects">
            <div
              v-for="vehicleDatumWithVariations in vehicleDataWithVariations"
              :key="`${vehicleDatumWithVariations}`"
              class="multiselect-44"
            >
              <FormRowSelect
                class="input-infos"
                :label-inline="false"
                :label="$t(`vehicleChoice.infos.${vehicleDatumWithVariations}`)"
                label-select-attr="label"
                :label-options-order="false"
                :name="`${vehicleDatumWithVariations}_variations`"
                :selected-option.sync="
                  form.selectedVehicleVariations[vehicleDatumWithVariations]
                "
                :select-options="getVehicleDatumVariations(vehicleDatumWithVariations)"
              />
            </div>
          </div>
          <div class="text-right mt-3">
            <button
              class="btn btn-primary btn-small"
              :disabled="isValidateButtonDisabled"
              data-cy="validate-button"
              @click="onValidateButtonClick"
            >
              {{ $t("button.validate") }}
            </button>
          </div>
        </div>
        <div v-if="getTabs[subscriptionInfoTabIndex].showTabContent">
          <OfferAccordion
            v-for="program_product in offer.program_products"
            :key="program_product.id"
            :program-product="program_product"
            :is-subscribable="isSubscribable"
            :disabled="isInBasket"
            @coverage-update="onCoverageUpdate"
            @add-to-cart="onAddToCart"
          ></OfferAccordion>
          <div v-if="needMoreInfos" class="text-right mt-3">
            <button
              class="btn btn-primary btn-small"
              :disabled="isValidateButtonDisabled"
              data-cy="validate-button"
              @click="onValidateButtonClick"
            >
              {{ $t("button.validate") }}
            </button>
          </div>
        </div>
      </div>
      <TotalPrice
        v-if="offerPrice !== null"
        :formatted-offer-price-to-display="formattedOfferPriceToDisplay"
        :offer-id="offer.id"
      ></TotalPrice>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import OfferAccordion from "@/components/offers/OfferAccordion"
import TotalPrice from "@/components/offers/TotalPrice"
import ImageMixin from "@/mixins/ImageMixin"
import PricingService from "@/services/business/PricingService"
import VerticalTab from "@/components/utils/VerticalTab"
import VehicleSearchService from "../../services/business/VehicleSearchService"

export default {
  name: "OfferSheet",
  components: {
    OfferAccordion,
    TotalPrice,
    VerticalTab,
  },
  mixins: [ImageMixin],
  props: {
    offer: { required: true },
    vehicle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isAccordionOpen: false,
      needMoreInfos: false,
      getTabs: [
        {
          showTabContent: false,
          type: "offer",
          label: this.$t("offers.tabs.vehicle_infos"),
          // Workaround to use data-cy
          item: { id: 1 },
        },
        {
          showTabContent: false,
          type: "offer",
          label: this.$t("offers.tabs.subscription_infos"),
          // Workaround to use data-cy
          item: { id: 2 },
        },
      ],
      form: {
        selectedVehicleVariations: {},
      },
      vehicleVariations: null,
    }
  },
  created() {
    this.needMoreInfos = this.areMoreVehicleInfoNeeded(this.productLineIds)
  },
  methods: {
    onCoverageUpdate() {
      // Beneficiary criteria changed => offer price must be updated
      if (!this.needMoreInfos) {
        this.$emit("computePricing")
      }
    },
    async toggleAccordion() {
      if (this.mustVehicleVariationsBeRetrieved) {
        await this.retrieveVehicleVariations()
      }
      this.updateShowTabContent()
      this.isAccordionOpen = !this.isAccordionOpen
    },
    async retrieveVehicleVariations() {
      let vehicleVariationsForAllProductLines = []
      for (const productLineId of this.productLineIds) {
        if (this.areMoreVehicleInfoNeededForProductLine(productLineId)) {
          const vehicleVariationsForProductLine = await this.retrieveVehicleVariationsForProductLine(
            productLineId
          )
          vehicleVariationsForAllProductLines.push(vehicleVariationsForProductLine)
        }
      }
      this.vehicleVariations = VehicleSearchService.getMergedVehicleVariations(
        vehicleVariationsForAllProductLines
      )
    },
    async retrieveVehicleVariationsForProductLine(productLineId) {
      return await this.$store.dispatch(
        "vehicle/retrieveVehicleVariations",
        productLineId
      )
    },
    onAddToCart() {
      this.$emit("addToBasket")
    },
    onDeleteFromCart() {
      this.$emit("delFromBasket")
    },
    setActiveTabIndex(tabIndex) {
      this.getTabs[this.vehicleInfoTabIndex].showTabContent = tabIndex === 0
      this.getTabs[this.subscriptionInfoTabIndex].showTabContent = tabIndex === 1
    },
    getVehicleDatumVariations(vehicleDatum) {
      const variations = this.vehicleVariations[vehicleDatum]
      const variationsSortedByValue = Object.entries(variations).sort(([, a], [, b]) =>
        a.localeCompare(b)
      )
      return Object.fromEntries(variationsSortedByValue)
    },
    async onValidateButtonClick() {
      const vehicle = {
        ...this.vehicle,
        ...this.form.selectedVehicleVariations,
      }
      await this.$store.dispatch("sale/updateVehicleOfCurrentSale", vehicle)
      this.$emit("computePricing")
    },
    updateShowTabContent() {
      this.getTabs[this.vehicleInfoTabIndex].showTabContent = this.needMoreInfos
      this.getTabs[this.subscriptionInfoTabIndex].showTabContent = !this.needMoreInfos
    },
  },
  computed: {
    ...mapGetters("basket", ["hasItem"]),
    ...mapGetters("productLine", ["beneficiaryCriteriaDefaultValues"]),
    ...mapGetters("offer", ["productLines", "isOfferWithMonthlyPayment"]),
    ...mapGetters("sale", [
      "getTotalProductLinesPrice",
      "areMoreVehicleInfoNeeded",
      "areMoreVehicleInfoNeededForProductLine",
      "isProductLineNotTariffable",
      "getCurrencyCode",
    ]),
    vehicleInfoTabIndex() {
      return this.getTabs.findIndex(
        (tab) => tab.label === this.$t("offers.tabs.vehicle_infos")
      )
    },
    subscriptionInfoTabIndex() {
      return this.getTabs.findIndex(
        (tab) => tab.label === this.$t("offers.tabs.subscription_infos")
      )
    },
    accordionButtonText() {
      return this.isAccordionOpen
        ? this.$t("offers.close")
        : this.textWhenAccordionIsClosed
    },
    textWhenAccordionIsClosed() {
      if (this.needMoreInfos && this.offerPrice === null) {
        return this.$t("offers.enter_missing_infos")
      }
      return this.$t("offers.details")
    },
    isInBasket() {
      return this.hasItem(this.offer.id)
    },
    isSubscribable() {
      return (
        (!this.hasItem(this.offer.id) && this.offerPrice !== null) ||
        (this.needMoreInfos && !this.isOfferNotTariffable)
      )
    },
    productLineIds() {
      return this.productLines(this.offer.id).reduce((ids, pl) => {
        ids.push(pl.id)
        return ids
      }, [])
    },
    offerPrice() {
      return this.getTotalProductLinesPrice(this.productLineIds)
    },
    isOfferNotTariffable() {
      return (
        this.offerPrice === null &&
        this.productLineIds.some((productLineId) =>
          this.isProductLineNotTariffable(productLineId)
        )
      )
    },
    formattedOfferPriceToDisplay() {
      return PricingService.getFormattedPriceToDisplay(
        this.offerPrice,
        this.isOfferWithMonthlyPayment(this.offer.id),
        this.getCurrencyCode(this.productLineIds)
      )
    },
    offerContent() {
      return this.offer.offer_contents.find(
        (offerContent) => offerContent.type === "B2C"
      )
    },
    imageUrl() {
      return this.offerContent?.image
        ? this.fitToSizeWithAspectRatioRetained(this.offerContent.image, 130, 110)
        : "https://via.placeholder.com/130x110"
    },
    monthlyPayment() {
      return this.isOfferWithMonthlyPayment(this.offer.id)
        ? this.$t("offers.by_month")
        : ""
    },
    vehicleDataWithVariations() {
      return Object.keys(this.vehicleVariations)
    },
    isValidateButtonDisabled() {
      return this.needMoreInfos && !this.areAllVariationsFilled
    },
    areAllVariationsFilled() {
      return this.vehicleDataWithVariations.every(
        (vehicleDatumWithVariations) =>
          this.form.selectedVehicleVariations.hasOwnProperty(
            vehicleDatumWithVariations
          ) && this.form.selectedVehicleVariations[vehicleDatumWithVariations] !== ""
      )
    },
    isSubscribeButtonDisabled() {
      return this.offerPrice === null
    },
    mustVehicleVariationsBeRetrieved() {
      return this.needMoreInfos && this.vehicleVariations === null
    },
  },
}
</script>

<style scoped lang="scss">
.offer_sheet-container {
  display: grid;
  grid-template-columns: 130px auto 250px;
  border-left: 4px solid $body-color;
  border-right: 4px solid $body-color;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  margin-top: 24px;
  background: white;
}
.offer_sheet-details,
.offer_sheet-button {
  display: flex;
  flex-direction: row;
}

.offer_sheet-button {
  position: relative;
}
.more-infos {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  color: #793471;
  font-family: $font-family-title;
  font-size: rem-calc(11);
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
}

.offer_sheet-details {
  padding-left: 14px;
  justify-content: space-between;
  p:last-child {
    margin-bottom: 0;
  }
  .offer_sheet-details-button {
    border: 0.5px solid $light-grey;
    border-radius: 5px;
    padding: 2px 20px;
    background-color: white;
    color: $light-grey;
    &.btn-more-infos {
      background: #793471;
      color: #fff;
      transition: all 0.25s ease-in-out;
      &:hover {
        background: scale-color(#793471, $lightness: 10%);
      }
    }
  }
}

.offer_sheet-button {
  background-color: $background-secondary;
  justify-content: space-evenly;
  align-items: center;
  button {
    background-color: $caareabluec700;
    border: 0.5px solid $caareabluec700;
    border-radius: 5px;
    padding: 4px 21px;
    letter-spacing: 0.75px;
    color: $caareagray6;
    mix-blend-mode: normal;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    .button-cancel {
      background-color: $secondary;
    }
  }
}

.offer_sheet-img img {
  padding: 0.5rem;
  width: 130px;
  height: 110px;
  overflow: hidden;
}

.icon-cart {
  margin-right: 10px;
}

.offer_sheet-accordion {
  display: grid;
  column-gap: 0.8rem;
  background-color: $white;
  &.nbCols-2 {
    grid-template-columns: 2fr 4fr;
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

.subscription-infos {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px;
}

.infos-selects {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}
</style>
