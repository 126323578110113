<template>
  <div
    class="d-flex justify-content-start align-items-center"
    :class="{
      current: isCurrent,
      done: isDone && !isCurrent,
      disabled: disabled,
      wizard_element: !disabled,
    }"
    :disabled="disabled"
    @click="goToRoute()"
  >
    <div class="icon mr-2" :style="bgColor">
      <span v-if="!isDone">{{ number }}</span>
      <span v-else class="la-icon">
        <em class="las la-2x la-check"></em>
      </span>
    </div>
    <div class="d-flex flex-column mr-3">
      <span id="title" class="font-weight-bold" :style="color">{{ title }}</span>
      <span class="font-size-12" :style="color">{{ subTitle }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "HorizontalWizardItem",
  props: ["title", "subTitle", "number", "isDone", "route", "disabled"],
  data() {
    return {
      isCurrent: false,
    }
  },
  computed: {
    ...mapGetters("sale", ["hasSale", "currentSaleId"]),
    color() {
      return this.isCurrent ? { color: "#1C2B4E" } : { color: "#C0C8D7" }
    },
    bgColor() {
      return this.isCurrent
        ? { "background-color": "#1C2B4E" }
        : { "background-color": "#C0C8D7" }
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.checkIsCurrent(to)
    },
  },
  mounted() {
    this.checkIsCurrent(this.$router.currentRoute)
  },
  methods: {
    goToRoute: function () {
      if (!this.disabled && !this.isCurrent) {
        const params = this.hasSale ? { saleId: this.currentSaleId } : {}
        this.$router.push({ name: this.route, params: params })
      }
    },
    checkIsCurrent(route) {
      if (route.name === this.route) {
        this.isCurrent = true
      } else {
        this.isCurrent = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.current {
  & div.icon {
    background-color: $primary !important;
  }
}

.done {
  color: $secondary;
  & div.icon {
    background-color: #c0c8d7 !important;
  }
}

.disabled {
  cursor: default;
}

#title {
  transition: all 0.4s;
}
.wizard_element {
  cursor: pointer;
}
</style>
