<template>
  <div>
    <span
      class="small medium-gray"
      :data-cy="`${dataCyPrefix}-payment-frequency-offers-title`"
    >
      {{ title }}
    </span>
    <ul>
      <li v-for="offer in offers" :key="offer.id">
        <div class="row">
          <div class="col-8">
            <span
              class="extra-large"
              :data-cy="`${dataCyPrefix}-payment-frequency-offer-${offer.id}-name`"
            >
              {{ getOfferName(offer) }}
            </span>
          </div>
          <div class="col-4 souscription-prix px-2">
            <div class="price">
              <div class="text-right">
                <span
                  class="text-uppercase extra-large pr-2"
                  :data-cy="`offer-${offer.id}-price`"
                >
                  {{ getFormattedOfferPriceToDisplay(offer) }}
                </span>
              </div>
              <div>
                <sup :data-cy="`offer-${offer.id}-price-detail`">
                  {{ priceDetail }}
                </sup>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="row">
          <div class="col-8 text-right">
            <span class="text-uppercase extra-large">Total</span>
          </div>
          <div class="col-4 souscription-prix px-2">
            <div class="price">
              <div class="text-right">
                <span
                  class="text-uppercase extra-large pr-2 text-right"
                  :data-cy="`${dataCyPrefix}-offers-total-price`"
                >
                  {{ formattedOffersTotalPriceToDisplay }}
                </span>
              </div>
              <div>
                <sup :data-cy="`${dataCyPrefix}-offers-total-price-detail`">
                  {{ priceDetail }}
                </sup>
              </div>
            </div>
          </div>
          <div class="col-auto souscription-btn"></div>
          <div class="col-auto"></div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import PricingService from "../../services/business/PricingService"
import { mapGetters } from "vuex"

export default {
  name: "SubscribedOffersPrices",
  props: {
    sale: { type: Object, required: true },
    offers: { type: Array, required: true },
    isPaymentFrequencyMonthly: { type: Boolean, required: true },
  },
  computed: {
    ...mapGetters("sale", ["getTotalProductLinesPrice", "getCurrencyCode"]),
    dataCyPrefix() {
      return this.isPaymentFrequencyMonthly ? "monthly" : "cash"
    },
    title() {
      return this.isPaymentFrequencyMonthly
        ? this.$t("subscription.monthly_payment_frequency_offers_title")
        : this.$t("subscription.cash_payment_frequency_offers_title")
    },
    formattedOffersTotalPriceToDisplay() {
      return PricingService.getFormattedPriceToDisplay(
        this.totalPrice,
        this.isPaymentFrequencyMonthly,
        this.getCurrencyCode(this.productLineIds)
      )
    },
    totalPrice() {
      return this.getTotalProductLinesPrice(this.productLineIds)
    },
    productLineIds() {
      return this.sale.product_lines.reduce((ids, productLine) => {
        if (this.offerIds.includes(productLine.offer.id)) {
          ids.push(productLine.id)
        }
        return ids
      }, [])
    },
    offerIds() {
      return this.offers.reduce((ids, offer) => {
        ids.push(offer.id)
        return ids
      }, [])
    },
    priceDetail() {
      return this.isPaymentFrequencyMonthly
        ? `${this.$t("pricing.after_tax")} ${this.$t("pricing.monthly")}`
        : `${this.$t("pricing.after_tax")}`
    },
  },
  methods: {
    getOfferName(offer) {
      return offer.contents.find((content) => content.type === "B2C").name
    },
    getFormattedOfferPriceToDisplay(offer) {
      return PricingService.getFormattedPriceToDisplay(
        this.getOfferPrice(offer.id),
        this.isPaymentFrequencyMonthly,
        this.getCurrencyCode(this.productLineIds)
      )
    },
    getOfferPrice(offerId) {
      const productLineIds = this.listProductLinesIdsByOfferId(offerId)
      return this.getTotalProductLinesPrice(productLineIds)
    },
    listProductLinesIdsByOfferId(offerId) {
      return this.sale.product_lines.reduce((ids, productLine) => {
        if (productLine.offer.id === offerId) {
          ids.push(productLine.id)
        }
        return ids
      }, [])
    },
  },
}
</script>

<style scoped></style>
