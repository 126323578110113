<template>
  <div
    class="product-synthesis__container"
    :data-cy="`offer-${offerInProgress.id}-synthesis`"
  >
    <table class="table table-striped" aria-describedby="offer synthesis">
      <thead></thead>
      <tbody>
        <tr
          v-for="(value, criteria) in criterion"
          :key="value"
          :data-cy="`${offerInProgress.id}-${criteria}`"
        >
          <td v-if="criteria && value !== undefined">
            {{ $t("criteria." + criteria) }}
          </td>
          <td>{{ value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import CriteriaService from "@/services/business/CriteriaService"

export default {
  name: "OfferSynthesis",
  props: {
    offerInProgress: {
      type: Object,
      required: true,
    },
    offerConfig: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("productLine", [
      "listCoefCritOptions",
      "getCoefCritValue",
      "getPaymentFrequencyCriteriaValue",
    ]),
    getListCoefCriteria() {
      return Object.keys(this.offerConfig.coefficient.values)
    },
    sales_mode() {
      return CriteriaService.getSpecialCriteriaValue(
        this.offerConfig,
        this.getListCoefCriteria,
        "sales_mode_"
      )
    },
    usage() {
      return CriteriaService.getSpecialCriteriaValue(
        this.offerConfig,
        this.getListCoefCriteria,
        "usage_"
      )
    },
    offerCriterionList() {
      return [this.sales_mode, "transferability", this.usage, "claims_manager"]
    },
    criterion() {
      let criterionList = {}
      const paymentFrequencyCriteriaValue = this.getPaymentFrequencyCriteriaValue(
        this.offerInProgress.program_products[0].product_lines[0].id
      )
      this.offerCriterionList.map((criteria) => {
        if (criteria && this.getCoeffCriteriaValueTranslated(criteria)) {
          criterionList[criteria] = this.getCoeffCriteriaValueTranslated(criteria)
        }
      })
      criterionList["payment_frequency"] = this.$t(
        `criteria.${paymentFrequencyCriteriaValue}`
      )
      return criterionList
    },
  },
  watch: {
    offerInProgress() {
      this.getCoefficientCriteria()
    },
  },
  methods: {
    getCoeffCriteriaValueTranslated(criterion) {
      if (criterion === "") return ""
      const listOfValues = this.listCoefCritOptions(
        this.offerInProgress.program_products[0].product_lines[0].id,
        criterion
      )
      const valueKey = this.getCoefCritValue(
        this.offerInProgress.program_products[0].product_lines[0].id,
        criterion
      )
      return listOfValues[valueKey]
    },
    async getCoefficientCriteria() {
      await this.$store.dispatch("productLine/getCoefficientCriteria", {
        id: this.offerInProgress.program_products[0].product_lines[0].id,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.product-synthesis__container table {
  font-size: 20px;
}

.product-synthesis__container tr > td:first-child {
  font-family: "Gilroy", sans-serif;
  text-align: right;
  width: 50%;
}

.product-synthesis__container tr > td:last-child {
  color: $caareablue;
  font-family: "Gilroy SemiBold", sans-serif;
  text-transform: capitalize;
}
</style>
