import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import Vue from "vue"
import { HttpError } from "../services/technical/HttpService"
import local_storage_names from "@/conf/local_storage_names"

// STATE (snake_case)
const state = {
  current_sale: null,
  beneficiary_criteria: null,
  beneficiary_errors: null,
  pricing: null,
  external_sales: null,
  pricing_by_product_line_id: null,
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_CURRENT_SALE: (state, sale) => {
    state.current_sale = sale
  },
  SET_BENEFICIARY_CRITERIA(state, payload) {
    if (state.beneficiary_criteria === null) state.beneficiary_criteria = {}
    Vue.set(state.beneficiary_criteria, payload.id, payload.beneficiaryCriteria)
  },
  SET_EXTERNAL_SALES: (state, sales) => {
    state.external_sales = sales
  },
  SET_BENEFICIARY_ERRORS: (state, errors) => {
    state.beneficiary_errors = errors
  },
  RESET_BENEFICIARY_ERRORS(state) {
    state.beneficiary_errors = null
  },
  RESET_SALE: (state) => {
    state.current_sale = null
    state.beneficiary_criteria = null
  },
  SET_PRODUCT_LINE_PRICING(state, payload) {
    if (state.pricing_by_product_line_id === null) state.pricing_by_product_line_id = {}
    Vue.set(state.pricing_by_product_line_id, payload.productLineId, {
      status: payload.status,
      price: payload.price,
      currency_code: payload.currency_code,
    })
  },
  REMOVE_PRODUCT_LINE_PRICING(state, productLineId) {
    if (
      state.pricing_by_product_line_id !== null &&
      state.pricing_by_product_line_id.hasOwnProperty(productLineId)
    )
      Vue.delete(state.pricing_by_product_line_id, productLineId)
  },
}

// ACTIONS (camelCase)
const actions = {
  async _updateBeneficiaryCriteriaOnSaleRetrieve({ dispatch }, sale) {
    // If some product lines are selected, update beneficiary criteria with the selected values
    // (useful in case of reload : state.beneficiary_criteria is lost)
    if (sale.beneficiary_criteria.length !== 0) {
      for (const bc of sale.beneficiary_criteria) {
        const payload = {
          productLineId: bc.product_line_id,
          beneficiaryCriteria: { ...bc.selected_values },
        }
        await dispatch("updateBeneficiaryCriteria", payload)
      }
    }
  },
  async getSale({ commit, dispatch }, saleId) {
    let sale = null
    try {
      sale = await HttpService.get(UrlService.render("saleRetrieve", { id: saleId }))
    } catch (e) {
      console.error("getSale failed: ", e)
      throw e
    }
    commit("SET_CURRENT_SALE", sale)

    // Update beneficiary criteria (if needed)
    await dispatch("_updateBeneficiaryCriteriaOnSaleRetrieve", sale)
  },
  async getExternalSales({ commit }) {
    let sales = null
    try {
      sales = await HttpService.get(UrlService.render("external_sale"))
    } catch (e) {
      console.error("getSale failed: ", e)
      throw e
    }
    commit("SET_EXTERNAL_SALES", sales)
  },
  async createSale({ commit }, payload) {
    let sale = null
    try {
      sale = await HttpService.post(UrlService.render("sale"), payload)
    } catch (e) {
      console.error("createSale failed: ", e)
      throw e
    }
    commit("SET_CURRENT_SALE", sale)
  },
  resetSale({ commit }) {
    commit("RESET_SALE")
  },
  _buildPayloadForAddOrRemoveProductLines({ state, rootGetters }, offerId) {
    return rootGetters["offer/productLines"](offerId).reduce((acc, productLine) => {
      const beneficiaryCriteria = rootGetters[
        "productLine/getBeneficiaryCriteriaValues"
      ](productLine.id)
      acc.push({
        product_line_id: productLine.id,
        selected_values: beneficiaryCriteria,
      })
      return acc
    }, [])
  },
  async addProductLinesToSale({ commit, state, dispatch }, offerId) {
    try {
      let sale = await HttpService.post(
        UrlService.render("saleSelectedProductLines", { id: state.current_sale.id }),
        {
          product_lines: await dispatch(
            "_buildPayloadForAddOrRemoveProductLines",
            offerId
          ),
        }
      )
      commit("SET_CURRENT_SALE", sale)
    } catch (e) {
      console.error("addProductLinesToSale failed: ", e)
      throw e
    }
  },
  async removeProductLinesFromSale({ commit, state, dispatch }, offerId) {
    try {
      let sale = await HttpService.delete(
        UrlService.render("saleSelectedProductLines", { id: state.current_sale.id }),
        {
          product_lines: await dispatch(
            "_buildPayloadForAddOrRemoveProductLines",
            offerId
          ),
        }
      )
      commit("SET_CURRENT_SALE", sale)
    } catch (e) {
      console.error("removeProductLinesFromSale failed: ", e)
      throw e
    }
  },
  async createOrUpdateBeneficiary({ commit, dispatch, getters }, payload) {
    commit("RESET_BENEFICIARY_ERRORS")
    try {
      if (getters.hasBeneficiary) {
        await dispatch("_updateBeneficiary", payload)
      } else {
        payload["lang"] = localStorage.getItem(local_storage_names.lang)
        const beneficiary = await dispatch("_createBeneficiary", payload)
        await dispatch("_updateSaleBeneficiary", beneficiary.id)
      }
    } catch (e) {
      let errors = {}
      for (const [key, value] of Object.entries(e.response.data)) {
        errors[key] = value
      }
      commit("SET_BENEFICIARY_ERRORS", e.data)
      throw e
    }
  },
  async _updateBeneficiary({ getters }, payload) {
    // TODO jlm: est inutile ! => modifier le back
    payload["id"] = getters.getBeneficiaryId
    await HttpService.put(
      UrlService.render("updateBeneficiary", { id: getters.getBeneficiaryId }),
      payload
    )
  },
  async _createBeneficiary({ getters }, payload) {
    return await HttpService.post(UrlService.render("beneficiary"), payload)
  },
  async _updateSaleBeneficiary({ getters }, beneficiaryId) {
    await HttpService.put(
      UrlService.render("saleBeneficiary", { id: getters.currentSaleId }),
      { beneficiary_id: beneficiaryId }
    )
  },
  async updateBeneficiaryCriteria({ commit, state }, payload) {
    commit("SET_BENEFICIARY_CRITERIA", {
      id: payload.productLineId,
      beneficiaryCriteria: payload.beneficiaryCriteria,
    })
  },
  async getSubscriptionSale({ commit, dispatch }, saleId) {
    let sale = null
    try {
      sale = await HttpService.get(
        UrlService.render("subscriptionSaleRetrieve", { id: saleId })
      )
    } catch (e) {
      console.error("getSale failed: ", e)
      throw e
    }
    // save sale in the store
    commit("SET_CURRENT_SALE", sale)
    // Update beneficiary criteria
    await dispatch("_updateBeneficiaryCriteriaOnSaleRetrieve", sale)
    // Update productLine store to be able to correctly display prices
    await dispatch("productLine/setProductLines", sale.product_lines, { root: true })
  },
  async subscribe({ commit, dispatch }, saleId) {
    try {
      const sale = await HttpService.post(
        UrlService.render("saleSubscribe", { id: saleId })
      )
      // save sale in the store
      commit("SET_CURRENT_SALE", sale)
    } catch (e) {
      console.error("subscribe failed: ", e)
      throw e
    }
  },
  async sendEmail({ commit, dispatch }, saleId) {
    try {
      const sale = await HttpService.post(
        UrlService.render("saleSendEmail", { id: saleId })
      )
      // save sale in the store
      commit("SET_CURRENT_SALE", sale)
    } catch (e) {
      console.error("sendEmail failed: ", e)
      throw e
    }
  },
  async finalize({ commit, getters, dispatch }) {
    // TODO: quite dirty workaround (if business logic changes in back, we shall change it here too...)
    // Don't finalize if status doesn't allow it
    if (getters.getCurrentSaleStatus !== "PROSPECT") {
      return
    }
    try {
      const sale = await HttpService.post(
        UrlService.render("saleFinalize", { id: getters.currentSaleId })
      )
      commit("SET_CURRENT_SALE", sale)
    } catch (e) {
      console.error("finalize failed: ", e)
      if (e instanceof HttpError) {
        if (e.status === 422 || e.status === 404) {
          await dispatch(
            "gui/showSaleUpdateFailureModal",
            { error: e.data },
            { root: true }
          )
        }
      }
      throw e
    }
  },
  resetBeneficiaryErrors({ commit }) {
    commit("RESET_BENEFICIARY_ERRORS")
  },
  async retrieveProductLinesPricing({ commit, rootGetters }, productLines) {
    let payload = []
    productLines.forEach((productLine) => {
      const beneficiaryCriteria = rootGetters[
        "productLine/getBeneficiaryCriteriaValues"
      ](productLine.id)
      payload.push({
        product_line_id: productLine.id,
        beneficiary_criteria: beneficiaryCriteria,
      })
    })
    try {
      const productLinesPricing = await HttpService.post(
        UrlService.render("productLinesPricing", { id: state.current_sale.id }),
        {
          product_lines: payload,
        }
      )
      productLinesPricing.forEach((productLinePricing) => {
        commit("SET_PRODUCT_LINE_PRICING", {
          productLineId: productLinePricing.product_line_id,
          status: productLinePricing.status,
          price: productLinePricing.price,
          currency_code: productLinePricing.currency_code,
        })
      })
    } catch (e) {
      console.error("retrieveProductLinesPricing failed: ", e)
      payload.forEach((productLine) => {
        commit("REMOVE_PRODUCT_LINE_PRICING", productLine.product_line_id)
      })
      throw e
    }
  },
  async updateVehicleOfCurrentSale({ commit, getters, dispatch }, vehicle) {
    try {
      const sale = await HttpService.put(
        UrlService.render("saleUpdateVehicle", { id: getters.currentSaleId }),
        { vehicle }
      )
      commit("SET_CURRENT_SALE", sale)
    } catch (e) {
      console.error("vehicle update of current sale failed: ", e)
      throw e
    }
  },
}

// GETTERS
const getters = {
  hasSale: (state) => !!state.current_sale,
  hasExternalSale: (state) => state.external_sales?.length > 0,
  currentSaleId: (state) => state.current_sale.id,
  currentSale: (state) => state.current_sale,
  hasAtLeastOneProductLine: (state) => state.current_sale?.product_lines.length > 0,
  wasSubscriptionSent: (state) =>
    state.current_sale && state.current_sale.current_status === "DOC_SENT",
  hasBeneficiary: (state) => state.current_sale?.hasOwnProperty("beneficiary"),
  getBeneficiaryId: (state) => state.current_sale.beneficiary.id,
  getBeneficiaryErrors: (state) =>
    state.beneficiary_errors ? state.beneficiary_errors : {},
  hasBeneficiaryCriteriaValues: (state) => (productLineId) =>
    state.beneficiary_criteria !== null &&
    state.beneficiary_criteria.hasOwnProperty(productLineId),
  beneficiaryCriteriaValues: (state) => (productLineId) =>
    JSON.parse(JSON.stringify(state.beneficiary_criteria[productLineId])),
  listExternalSalesByReference: (state) => (reference) => {
    // reference may not be complete, that's why we use includes instead of ===
    return state.external_sales.filter((sale) => sale.num_external.includes(reference))
  },
  getTotalProductLinesPrice: (state, getters) => (productLineIds) => {
    if (
      state.pricing_by_product_line_id &&
      productLineIds.every((productLineId) =>
        getters.hasProductLinePrice(productLineId)
      )
    ) {
      return productLineIds.reduce((sum, productLineId) => {
        return sum + state.pricing_by_product_line_id[productLineId].price
      }, 0)
    }
    return null
  },
  getCurrencyCode: (state, getters) => (productLineIds) => {
    if (
      state.pricing_by_product_line_id &&
      productLineIds.every((productLineId) =>
        getters.hasProductLinePrice(productLineId)
      )
    ) {
      return state.pricing_by_product_line_id[productLineIds[0]].currency_code
    }
    return "EUR"
  },
  hasProductLinePrice: (state) => (productLineId) => {
    return (
      state.pricing_by_product_line_id.hasOwnProperty(productLineId) &&
      state.pricing_by_product_line_id[productLineId] !== null &&
      state.pricing_by_product_line_id[productLineId].status === "tariffable"
    )
  },
  isProductLineNotTariffable: (state) => (productLineId) => {
    return (
      state.pricing_by_product_line_id.hasOwnProperty(productLineId) &&
      state.pricing_by_product_line_id[productLineId] !== null &&
      state.pricing_by_product_line_id[productLineId].status === "not_tariffable"
    )
  },
  areMoreVehicleInfoNeeded: (state, getters) => (productLineIds) => {
    return (
      state.pricing_by_product_line_id &&
      productLineIds.some((productLineId) =>
        getters.areMoreVehicleInfoNeededForProductLine(productLineId)
      ) &&
      productLineIds.every(
        (productLineId) => !getters.isProductLineNotTariffable(productLineId)
      )
    )
  },
  areMoreVehicleInfoNeededForProductLine: (state) => (productLineId) => {
    return (
      state.pricing_by_product_line_id.hasOwnProperty(productLineId) &&
      state.pricing_by_product_line_id[productLineId] !== null &&
      state.pricing_by_product_line_id[productLineId].status === "missing_info"
    )
  },
  getVehicleUniqueId: (state) => state.current_sale.vehicle.unique_id,
  getCurrentSaleStatus: (state) => state.current_sale.current_status,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
