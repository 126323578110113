import AbstractService from "@/services/AbstractService"

class ArrayUtils extends AbstractService {
  isArray(value) {
    return Array.isArray(value)
  }

  /**
   * Creates a sequence of numbers, starting from 0 by default, increments by 1
   * and stops before a specified number
   *
   * same as Python range()
   *
   * @param start
   * @param end
   * @returns {unknown[]}
   */
  range(start, end) {
    return [...Array(end - start).keys()].map((v) => start + v)
  }

  areSame(first, second) {
    return (
      first.every((e) => second.includes(e)) && second.every((e) => first.includes(e))
    )
  }

  /**
   * Adds an element to the array if it does not already exist
   *
   * @param baseArray
   * @param value
   */
  pushIfNotExist(baseArray, value) {
    if (!baseArray.includes(value)) {
      baseArray.push(value)
    }
    return baseArray
  }

  /**
   * Return an object containing only the entries common to all objects.
   * Limitation: dict should contain only simple types
   *
   * @param {Array} array
   */
  getObjectWithCommonEntriesOnly(array) {
    if (array.length === 0) {
      return {}
    }
    // Copy array because sort() applies in place !
    const arrayCopy = [...array]
    // Sort array of objects by number of keys
    // => in order to have the object with the lowest number of keys at index 0
    const arraySortedByNbOfEntries = arrayCopy.sort((a, b) => {
      return Object.keys(a).length > Object.keys(b).length ? 1 : -1
    })
    // Filter first object to keep only the keys that are included in all other objects
    return Object.fromEntries(
      Object.entries(arraySortedByNbOfEntries[0]).filter(([key, value]) => {
        return arrayCopy.every((item) => Object.keys(item).includes(key))
      })
    )
  }
}

let ArrayService = new ArrayUtils()
export default ArrayService
