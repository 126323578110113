<template>
  <transition v-if="visible" name="slide-fade">
    <div id="sidenav" data-cy="burger_menu">
      <div class="row mt-3">
        <div class="col text-right fixed-height">
          <button
            type="button"
            class="invisible-button close-cross icon-cross align-self-center"
            aria-hidden="true"
            data-cy="basket-close"
            @click.prevent="$emit('toggleMenuVisibility')"
          ></button>
        </div>
      </div>
      <nav>
        <ul class="text-right" @click="$emit('toggleMenuVisibility')">
          <li>
            <router-link
              data-cy="burger_vehicle_link"
              :to="{ name: 'vehicle' }"
              class="nav-item menu-item"
            >
              {{ $t("subscribe.title") }}
            </router-link>
          </li>
          <li>
            <router-link
              data-cy="burger_vehicle_link"
              :to="{ name: 'programs' }"
              class="nav-item menu-item"
            >
              {{ $t("catalog") }}
            </router-link>
          </li>
          <li>
            <router-link
              data-cy="burger_sales_link"
              :to="{ name: 'sales' }"
              class="nav-item menu-item"
            >
              {{ $t("my_sales") }}
            </router-link>
          </li>
          <li>
            <router-link
              data-cy="burger_account_link"
              :to="{ name: 'admin' }"
              class="nav-item menu-item"
            >
              {{ $t("my_account") }}
            </router-link>
          </li>
          <li><span>&nbsp;</span></li>
          <li><span>&nbsp;</span></li>
          <li>
            <router-link
              data-cy="burger_diconnect_link"
              :to="{ name: 'logout' }"
              class="nav-item menu-item"
            >
              {{ $t("disconnect") }}
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </transition>
</template>

<script>
export default {
  name: "BurgerMenu",
  props: ["visible"],
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s;
}
.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);
}
.menu-item:hover {
  font-weight: 500;
}
.menu-item {
  transition: all 0.3s;
}
.close-cross {
  color: white;
}
</style>
