import HttpService from "@/services/technical/HttpService"
import UrlService from "@/services/technical/UrlService"
import VehicleSearchService from "@/services/business/VehicleSearchService"
import i18n from "@/i18n"
import Vue from "vue"

// STATES (snake_case)
const state = {
  filters_structure: {
    vehicle_type: {
      rank: 1,
      data: "all_vehicles",
      isDisabled: false,
    },
    make: {
      rank: 2,
      data: [],
      isDisabled: false,
    },
    model: {
      rank: 3,
      data: [],
      isDisabled: true,
    },
    trim_level: {
      rank: 4,
      data: [],
      isDisabled: true,
    },
    year: {
      rank: 5,
      data: [],
      isDisabled: false,
    },
    power: {
      rank: 6,
      data: [],
      isDisabled: false,
    },
    cylinders: {
      rank: 7,
      data: [],
      isDisabled: false,
    },
    engine_type: {
      rank: 8,
      data: [],
      isDisabled: false,
    },
  },
  selectable_values_structure: {
    vehicle_type: [
      { id: "vn_only", local_name: "vehicleChoice.vn_only" },
      { id: "all_vehicles", local_name: "vehicleChoice.all_vehicles" },
    ],
    makes: [],
    models: [],
    trim_levels: [],
    years: [],
    powers: [],
    cylinders: [],
    engine_types: [],
  },
  filters: {},
  selectable_values: {},
  vehicles: {
    results: [],
    count: 0,
  },
  selected_vehicle: null,
  search_errors: {
    by_plate: null,
    by_vin: null,
  },
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SET_VEHICLE_TYPE_FILTER(state, payload) {
    state.filters.vehicle_type.data = payload.values
  },
  SET_VEHICLE_TYPE_SELECTABLE_VALUES(state, payload) {
    state.selectable_values.vehicle_type = [
      { id: payload.values, local_name: `vehicleChoice.${payload.values}` },
    ]
  },
  SET_MAKES_SELECTABLE_VALUES(state, payload) {
    state.selectable_values.makes = payload.values
  },
  SET_MAKE_FILTER(state, payload) {
    state.filters.make.data = payload.values
  },
  SET_MODELS_SELECTABLE_VALUES(state, payload) {
    state.selectable_values.models = payload.values
  },
  SET_MODEL_FILTER(state, payload) {
    state.filters.model.data = payload.values
  },
  SET_TRIM_LEVELS_SELECTABLE_VALUES(state, payload) {
    state.selectable_values.trim_levels = payload.values
  },
  SET_TRIM_LEVEL_FILTER(state, payload) {
    state.filters.trim_level.data = payload.values
  },
  SET_YEARS_SELECTABLE_VALUES(state, payload) {
    state.selectable_values.years = payload.values
  },
  SET_YEAR_FILTER(state, payload) {
    state.filters.year.data = payload.values
  },
  SET_POWERS_SELECTABLE_VALUES(state, payload) {
    state.selectable_values.powers = payload.values
  },
  SET_POWER_FILTER(state, payload) {
    state.filters.power.data = payload.values
  },
  SET_CYLINDERS_SELECTABLE_VALUES(state, payload) {
    state.selectable_values.cylinders = payload.values
  },
  SET_CYLINDERS_FILTER(state, payload) {
    state.filters.cylinders.data = payload.values
  },
  SET_ENGINE_TYPES_SELECTABLE_VALUES(state, payload) {
    state.selectable_values.engine_types = payload.values
  },
  SET_ENGINE_TYPE_FILTER(state, payload) {
    state.filters.engine_type.data = payload.values
  },
  REMOVE_FILTER_VALUE(state, payload) {
    const index_to_remove = state.filters[payload.name].data.indexOf(payload.value)
    if (index_to_remove !== -1) {
      state.filters[payload.name].data.splice(index_to_remove, 1)
    }
  },
  RESET_ALL_FILTERS(state) {
    state.filters = JSON.parse(JSON.stringify(state.filters_structure))
  },
  RESET_ALL_SELECTABLE_VALUES(state) {
    state.selectable_values = JSON.parse(
      JSON.stringify(state.selectable_values_structure)
    )
    state.selectable_values.engine_types = VehicleSearchService.listEngineTypes()
  },
  SET_VEHICLES(state, vehicles) {
    state.vehicles = vehicles
  },
  RESET_VEHICLES(state) {
    state.vehicles.results = []
    state.vehicles.count = 0
  },
  SET_IS_DISABLE_FILTER(state, payload) {
    state.filters[payload.filterName].isDisabled = payload.isDisabled
  },
  SET_SELECTED_VEHICLE(state, vehicle) {
    state.selected_vehicle = structuredClone(vehicle)
  },
  RESET_SELECTED_VEHICLE(state) {
    state.selected_vehicle = null
  },
  SET_SEARCH_BY_PLATE_ERROR(state, error) {
    Vue.set(state.search_errors, "by_plate", error)
  },
  RESET_SEARCH_BY_PLATE_ERROR(state) {
    state.search_errors.by_plate = null
  },
  SET_SEARCH_BY_VIN_ERROR(state, error) {
    Vue.set(state.search_errors, "by_vin", error)
  },
  RESET_SEARCH_BY_VIN_ERROR(state) {
    state.search_errors.by_vin = null
  },
}

// ACTIONS (camelCase)
const actions = {
  resetAllFilters({ commit }) {
    commit("RESET_ALL_FILTERS")
  },
  async initFiltersAndSelectableValues({ commit }) {
    commit("SET_VEHICLES", { results: [] })
    commit("RESET_ALL_FILTERS")
    commit("RESET_ALL_SELECTABLE_VALUES")
  },
  async updateVehicleTypesFilter({ state, commit, dispatch }, payload) {
    if (payload?.values) {
      commit("SET_VEHICLE_TYPE_FILTER", {
        values: payload.values,
      })
    }
    const filterNames = Object.keys(state.filters_structure)
    const vehicleTypeIndex = filterNames.indexOf("vehicle_type")
    filterNames.splice(vehicleTypeIndex, 1)
    for (const filterName of filterNames) {
      commit(
        `SET_${VehicleSearchService.uppercaseFilterNameMatching[filterName]}_FILTER`,
        {
          values: [],
        }
      )
      await dispatch("_updateFilterIfNecessary", {
        filterName: filterName,
      })
    }
  },
  async updateFilter({ state, commit, dispatch }, payload) {
    const uppercaseFilterName =
      VehicleSearchService.uppercaseFilterNameMatching[payload.filter]
    commit(`SET_${uppercaseFilterName}_FILTER`, {
      values: payload.values,
    })
    const filterNames = VehicleSearchService.listFilterValuesWithHigherRank(
      state.filters_structure,
      payload.filter
    )
    for (const filterName of filterNames) {
      await dispatch("_updateFilterIfNecessary", {
        filterName: filterName,
      })
    }
  },
  async _updateFilterSelectableValues({ state, commit }, payload) {
    const url = VehicleSearchService.urlFilterNameMatching[payload.filterName]
    const uppercaseSelectableName =
      VehicleSearchService.uppercaseSelectableNameMatching[payload.filterName]
    let queryParams = VehicleSearchService.createQueryParams(
      payload.filterName,
      state.filters
    )
    try {
      const selectableValues = await HttpService.get(
        UrlService.render(url, {}, queryParams)
      )
      commit(`SET_${uppercaseSelectableName}_SELECTABLE_VALUES`, {
        values: selectableValues,
      })
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
  async searchVehicles({ state, commit }) {
    const payload = {
      ...VehicleSearchService.listQueryFilter(state.filters),
    }
    if (
      state.filters.make.data.length === 0 &&
      state.filters.vehicle_type.data.length === 0
    ) {
      payload["make"] = VehicleSearchService.listMakes(state.selectable_values.makes)
    }
    try {
      const vehicles = await HttpService.post(
        UrlService.render("vehicleSearch", {}, { limit: 5 }),
        payload
      )
      commit("SET_VEHICLES", vehicles)
    } catch (e) {
      console.error("failed: ", e)
      throw e
    }
  },
  async removeFilterValuesIfNotInSelectableValues({ state, commit }, payload) {
    let filtersToControl = []
    for (let filter in state.filters) {
      if (
        state.filters[filter].rank > payload.rank &&
        state.filters[filter].data.length > 0
      ) {
        filtersToControl.push({
          [filter]: state.filters[filter],
        })
      }
    }
    filtersToControl.forEach((filter) => {
      for (let filterKey in filter) {
        const selectableValueKeyName =
          VehicleSearchService.filterAndSelectableValueNameMatching[filterKey]
        filter[filterKey].data.forEach((filterValue) => {
          const isFilterInSelectableValues = state.selectable_values[
            selectableValueKeyName
          ].find((selectableValue) => Object.keys(selectableValue)[0] === filterValue)
          if (isFilterInSelectableValues === undefined) {
            commit("REMOVE_FILTER_VALUE", {
              name: filterKey,
              value: filterValue,
            })
          }
        })
      }
    })
  },
  async displaySpecificPageVehicles({ commit, state }, payload) {
    const postPayload = {}
    if (
      state.filters.make.data.length === 0 &&
      state.filters.vehicle_type.data.length === 0
    ) {
      postPayload["make"] = VehicleSearchService.listMakes(
        state.selectable_values.makes
      )
    } else {
      Object.assign(postPayload, {
        ...VehicleSearchService.listQueryFilter(state.filters),
      })
    }
    const vehicles = await HttpService.post(
      process.env.VUE_APP_ENDPOINT +
        "vehicles/search/?limit=5&page=" +
        payload.pageNumber,
      postPayload
    )
    commit("SET_VEHICLES", vehicles)
  },
  async _updateFilterIfNecessary({ state, commit, dispatch }, payload) {
    const filterName = payload.filterName
    if (
      (filterName === "model" && state.filters["make"].data.length > 0) ||
      (filterName === "trim_level" &&
        ((state.filters["model"].data.length > 0 &&
          state.filters["make"].data.length > 0) ||
          state.selectable_values["models"].length === 1)) ||
      (filterName !== "model" &&
        filterName !== "trim_level" &&
        !state.filters[filterName].isDisabled)
    ) {
      await dispatch("_updateFilterSelectableValues", {
        filterName: filterName,
      })
      if (
        (filterName === "trim_level" || filterName === "model") &&
        state.selectable_values[
          VehicleSearchService.filterAndSelectableValueNameMatching[filterName]
        ].length !== 0
      ) {
        await commit(`SET_IS_DISABLE_FILTER`, {
          filterName: filterName,
          isDisabled: false,
        })
      }
    } else {
      await commit(
        `SET_${VehicleSearchService.uppercaseSelectableNameMatching[filterName]}_SELECTABLE_VALUES`,
        {
          values: [],
        }
      )
      await commit(
        `SET_${VehicleSearchService.uppercaseFilterNameMatching[filterName]}_FILTER`,
        {
          values: [],
        }
      )
      commit(`SET_IS_DISABLE_FILTER`, {
        filterName: filterName,
        isDisabled: true,
      })
    }
  },
  resetVehicles({ commit }) {
    commit("RESET_VEHICLES")
  },
  async searchByVin({ commit }, vin) {
    commit("RESET_SEARCH_BY_VIN_ERROR")
    try {
      const vehicles = await HttpService.post(UrlService.render("vehicleSearch"), {
        vin: vin,
      })
      commit("SET_VEHICLES", vehicles)
    } catch (e) {
      console.error("failed: ", e)
      commit("SET_SEARCH_BY_VIN_ERROR", e.data)
    }
  },
  resetSearchByVinError({ commit }) {
    commit("RESET_SEARCH_BY_VIN_ERROR")
  },
  async searchByPlate({ commit }, plate) {
    commit("RESET_SEARCH_BY_PLATE_ERROR")
    try {
      const vehicles = await HttpService.post(UrlService.render("vehicleSearch"), {
        plate: plate,
      })
      commit("SET_VEHICLES", vehicles)
    } catch (e) {
      console.error("failed: ", e)
      commit("SET_SEARCH_BY_PLATE_ERROR", e.data)
    }
  },
  resetSearchByPlateError({ commit }) {
    commit("RESET_SEARCH_BY_PLATE_ERROR")
  },
  setSelectedVehicle({ commit }, vehicle) {
    commit("SET_SELECTED_VEHICLE", vehicle)
  },
  resetSelectedVehicle({ commit }) {
    commit("RESET_SELECTED_VEHICLE")
  },
}

// GETTERS (camelCase)
const getters = {
  getVehicleTypesSelectableValues: (state) => {
    let selectableValues = {}
    state.selectable_values.vehicle_type.forEach((value) => {
      Object.assign(selectableValues, { [value.id]: i18n.t(value.local_name) })
    })
    return selectableValues
  },
  getMakeSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesObjectsToObjectSelectableValues(
      state.selectable_values.makes
    )
  },
  getModelSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesObjectsToObjectSelectableValues(
      state.selectable_values.models
    )
  },
  getTrimLevelSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesToObjectSelectableValues(
      state.selectable_values.trim_levels
    )
  },
  getYearSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesToObjectSelectableValues(
      state.selectable_values.years
    )
  },
  getPowerSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesToObjectSelectableValues(
      state.selectable_values.powers
    )
  },
  getCylindersSelectableValues: (state) => {
    return VehicleSearchService.transformArraySelectableValuesToObjectSelectableValues(
      state.selectable_values.cylinders
    )
  },
  getEngineTypeSelectableValues: (state) => {
    let isHybridIncluded = false
    const selectableValues = state.selectable_values.engine_types.reduce(
      (acc, engineValue) => {
        const engineCode = Object.keys(engineValue)[0]
        const engineName = Object.values(engineValue)[0]
        if (!VehicleSearchService.HYBRID_ENGINE_CODES.includes(engineCode)) {
          acc.push({ [engineCode]: engineName })
        } else {
          isHybridIncluded = true
        }
        return acc
      },
      []
    )
    if (isHybridIncluded) {
      selectableValues.push({
        [i18n.t("vehicleChoice.hybrid")]: i18n.t("vehicleChoice.hybrid"),
      })
    }
    return VehicleSearchService.transformArraySelectableValuesToObjectSelectableValues(
      selectableValues
    )
  },
  getFiltersData: (state) =>
    Object.keys(state.filters).reduce((result, param) => {
      result[param] = state.filters[param].data
      let isHybridIncluded = false
      let filterValues = state.filters[param].data
      if (param === "engine_type") {
        filterValues = state.filters[param].data.filter((value) => {
          if (!isHybridIncluded) {
            isHybridIncluded = VehicleSearchService.hybridTypes.includes(
              Object.values(value)[0]
            )
          }
          return !VehicleSearchService.hybridTypes.includes(Object.values(value)[0])
        })
        if (isHybridIncluded) filterValues.push(i18n.t("vehicleChoice.hybrid"))
      }
      result[param] = filterValues
      return result
    }, {}),
  getVehicleTypeFilterValue: (state) => {
    return state.filters.hasOwnProperty("vehicle_type") &&
      state.filters.vehicle_type !== null
      ? state.filters.vehicle_type.data
      : state.filters_structure.vehicle_type.data
  },
  listVehicles: (state) => state.vehicles.results,
  hasVehicles: (state) => {
    return state.vehicles.results.length > 0
  },
  hasPagination: (state) => {
    return state.vehicles.count > 5
  },
  hasAThirdPagePagination: (state) => {
    return state.vehicles.count > 10
  },
  getFilterRank: (state) => (filterName) => {
    return state.filters_structure[filterName].rank
  },
  getIsFiltersDisabled: (state) =>
    Object.keys(state.filters).reduce((result, param) => {
      result[param] = state.filters[param].isDisabled
      return result
    }, {}),
  getIsFilterDisabled: (state) => (filterName) => state.filters[filterName].isDisabled,
  getSelectedVehicle: (state) => state.selected_vehicle,
  isSelectedVehicle: (state, getters) => (vehicle) =>
    getters.getSelectedVehicle !== null && getters.getSelectedVehicle.id === vehicle.id,
  hasSearchByPlateError: (state) => state.search_errors.by_plate !== null,
  hasSearchByVinError: (state) => state.search_errors.by_vin !== null,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
