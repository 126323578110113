<template>
  <ModalBootstrap
    class="criteria"
    :title="title"
    modal-size="medium"
    data-cy="feedback-modal"
    @close="onClose"
  >
    <template #body>
      <div class="mx-2 mb-2 d-flex flex-column justify-content-center">
        <div class="my-5 text-center w-100 font-size-16" data-cy="feedback-message">
          {{ message }}
        </div>

        <div class="row justify-content-center">
          <div class="col-md-12 px-2">
            <button
              class="btn btn-primary w-25 px-1 mb-1 float-right"
              data-cy="ok-button"
              @click.prevent="onClose"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"

export default {
  name: "ModalFeedback",
  components: { ModalBootstrap },
  props: ["message", "title"],
  methods: {
    onClose() {
      this.$emit("close")
    },
  },
}
</script>
