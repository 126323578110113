<template>
  <div :class="['product-line-edition__criteria__benef', ...componentClass]">
    <div class="benef-criteria">
      <FormRowSelect
        v-for="criterion in beneficiaryCriteriaNames"
        :key="criterion"
        class="font-size-14 white_space_nowrap"
        :label-inline="false"
        :label="$t('criteria.' + criterion)"
        :for-id="`program-context-${criterion}`"
        :name="`${productLineId}-${criterion}`"
        :selected-option.sync="form.beneficiaryCriteria[criterion]"
        label-select-attr="label"
        :select-options="listCoefCritOptions(productLineId, criterion)"
        :errors="{}"
        :disabled="disabled"
        @update:selected-option="onUpdateCriterion(criterion)"
      ></FormRowSelect>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import BeneficiaryCriteriaService from "@/services/business/BeneficiaryCriteriaService"

export default {
  name: "CoveragePerformanceCriteria",
  props: {
    productLineId: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
    componentClass: {
      type: Array,
      default: () => [""],
    },
  },
  data() {
    return {
      form: {
        beneficiaryCriteria: {},
      },
    }
  },
  computed: {
    ...mapGetters("productLine", [
      "listCoefCritOptions",
      "productLineCoefficients",
      "getBeneficiaryCriteriaValues",
      "beneficiaryCriteriaDefaultValues",
    ]),
    beneficiaryCriteriaNames() {
      return this.productLineCoefficients(this.productLineId).beneficiary
    },
  },
  created() {
    this.form.beneficiaryCriteria = this.getBeneficiaryCriteriaValues(
      this.productLineId
    )
    this.getValidEwDuration()
  },
  methods: {
    async onUpdateCriterion(criterion) {
      const payload = {
        productLineId: this.productLineId,
        beneficiaryCriteria: { ...this.form.beneficiaryCriteria },
      }
      await this.$store.dispatch("sale/updateBeneficiaryCriteria", payload)
      this.$emit("coverage-update")
    },
    getValidEwDuration() {
      if (
        Object.keys(this.form.beneficiaryCriteria).includes("ew_duration") &&
        !BeneficiaryCriteriaService.isCriterionValueInCovDurationOptions(
          this.form.beneficiaryCriteria,
          this.listCoefCritOptions(this.productLineId, "ew_duration"),
          "ew_duration"
        )
      ) {
        this.form.beneficiaryCriteria = BeneficiaryCriteriaService.getValidCoverageDurationWithoutSelectValues(
          this.beneficiaryCriteriaDefaultValues(this.productLineId),
          this.listCoefCritOptions(this.productLineId, "ew_duration"),
          "ew_duration"
        )
      }
    },
  },
}
</script>

<style scoped lang="scss">
.white_space_nowrap {
  white-space: nowrap;
}

.product-line-edition__criteria__benef {
  padding: 0.5rem 1rem;
}

.benef-criteria {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  align-items: center;
  font-family: "Effra", sans-serif;

  & label {
    color: $primarybutton;
  }
}
</style>
