// STATES (snake_case)
import i18n from "../i18n"

const state = {
  show_sharing_status_modal: false,
  show_sale_update_failure_modal: false,
  sale_update_failure_modal_info: {},
}

// MUTATIONS (SNAKE_CASE)
const mutations = {
  SHOW_SHARING_STATUS_MODAL(state) {
    state.show_sharing_status_modal = true
  },
  SET_SHOW_SALE_UPDATE_FAILURE_MODAL(state, value) {
    state.show_sale_update_failure_modal = value
  },
  SET_SALE_UPDATE_FAILURE_INFO(state, payload) {
    state.sale_update_failure_modal_info = payload
  },
  RESET_SALE_UPDATE_FAILURE_INFO(state) {
    state.sale_update_failure_modal_info = {}
  },
}

// ACTIONS (camelCase)
const actions = {
  showSharingStatusModal({ commit }) {
    commit("SHOW_SHARING_STATUS_MODAL")
  },
  showSaleUpdateFailureModal({ commit }, payload) {
    const modalInfo = {
      title: i18n.t("sale_update_failure_modal.title"),
      reason: payload.error,
      detailedReason: "",
      advice: i18n.t("sale_update_failure_modal.advice"),
    }
    commit("SET_SALE_UPDATE_FAILURE_INFO", modalInfo)
    commit("SET_SHOW_SALE_UPDATE_FAILURE_MODAL", true)
  },
  hideSaleUpdateFailureModal({ commit }) {
    commit("SET_SHOW_SALE_UPDATE_FAILURE_MODAL", false)
    commit("RESET_SALE_UPDATE_FAILURE_INFO")
  },
}

// GETTERS (camelCase)
const getters = {
  hasSharingStatusModalToBeDisplayed: (state) => state.show_sharing_status_modal,
  hasSaleUpdateFailureModalToBeDisplayed: (state) =>
    state.show_sale_update_failure_modal,
  getSaleUpdateFailureModalInfo: (state) => state.sale_update_failure_modal_info,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
