// STATES
const state = {
  items: [],
}

// MUTATIONS
const mutations = {
  ADD: (state, item) => {
    state.items.push(item)
  },
  DEL: (state, item) => {
    state.items = state.items.filter((i) => i !== item)
  },
  RESET: (state) => {
    state.items = []
  },
}

// ACTIONS
const actions = {
  add({ commit }, item) {
    commit("ADD", item)
  },
  del({ commit }, item) {
    commit("DEL", item)
  },
  reset({ commit }) {
    commit("RESET")
  },
}

// GETTERS
const getters = {
  nbItems: (state) => state.items.length,
  hasItem: (state) => (item) => state.items.includes(item),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
