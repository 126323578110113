<template>
  <div
    id="identification-vehicule-page"
    class="page-vehicule"
    style="width: 96%; margin: auto"
    data-cy="vehicle-infos-page"
  >
    <ModalSharingStatus
      v-if="showSharingStatusModal"
      :status="sharingStatus"
    ></ModalSharingStatus>
    <ModalVehicleSearch
      v-if="showVehicleSearchModal"
      @validate="onValidate"
      @close="onCloseModalVehicleSearch"
    ></ModalVehicleSearch>
    <spinner
      v-if="isLoading"
      :message="$t('spinner.loading')"
      class="mt-5 mb-5"
    ></spinner>
    <div v-else>
      <div v-if="displaySearchBar && hasExternalSale">
        <SearchBar
          name="sale_search"
          :errors="{}"
          @search-sale="searchSale"
        ></SearchBar>
      </div>
      <div v-else>
        <div :class="getSearchAreaClass">
          <div>
            <VehicleSearchArea
              v-model="form"
              :manual-search="manualSearch"
              class="mt-4"
              :is-search-loading="isSearchLoading"
              :used-vehicles-search-errors="getVelasticSearchErrors"
              :manual-search-errors="getVelasticSearchErrors"
              :has-used-vehicles-search-result="hasUsedVehiclesSearchResult"
              :is-immat-mocked="isImmatMocked"
              @used-vehicle-search="onUsedVehicleSearch"
              @manual-vehicle-search="onVehicleManualSearch"
            ></VehicleSearchArea>
            <ActionButton
              :button-text="$t('button.search')"
              :button-class="['mt-5']"
              @click-action="showVehicleSearchModal = true"
            ></ActionButton>
          </div>
          <div v-if="sharedToken === '' && hasExternalSale" class="mt-4">
            <ExternalSaleSearchArea
              class="h-100 p-3"
              @search-external-sale="searchSale"
            ></ExternalSaleSearchArea>
          </div>
        </div>
        <ExternalSalesList
          v-if="externalSales.length > 0 && currentSearch === null"
          :external-sales="externalSales"
          @click-external-sale="onExternalSaleClick"
        ></ExternalSalesList>
        <div
          v-else-if="
            hasExternalSale && externalSales.length === 0 && currentSearch === null
          "
          class="external-search__no-result"
          data-cy="no-external-sales"
        >
          <h3>{{ $t("sale.no_result") }}</h3>
          <p>{{ $t("sale.search_again") }}</p>
          <ActionButton
            :button-text="$t('button.return')"
            button-data-cy="return-button"
            @click-action="onDisplaySearchBar"
          ></ActionButton>
        </div>
        <VehicleSearchResult
          v-if="currentSearch"
          v-model="form"
          data-cy="vehicle-search-result"
          :search-text="getSearchText"
          :elements="listVelasticSearchResults"
          :total-hits-nb="listVelasticSearchResults.length"
          :are-element-clickable="true"
          :errors="getVelasticSearchErrors"
          class="search-result"
          @validate-version="onValidateVersion"
          @filter-input="onFilterInput"
          @reset-filters="onResetFilters"
        ></VehicleSearchResult>
        <ModalInfoVehicle
          v-if="showVehicleInfoModal"
          :title="$t('your_vehicle.modalTitle')"
          :sub-title="$t('your_vehicle.modalSubTitle')"
          @validate-vehicle="onValidateVehicle"
          @vehicle-info-modal-close="onCloseVehicleInfoModal"
        ></ModalInfoVehicle>
        <ModalWarning
          v-if="warningModal.show"
          :title="warningModal.title"
          :reason="warningModal.reason"
          :advice="warningModal.advice"
          @close="onCloseWarningModal"
        ></ModalWarning>
      </div>
    </div>
  </div>
</template>

<script>
import ModalInfoVehicle from "@/components/vehicle/modal/ModalInfoVehicle"
import ModalWarning from "@/components//modal/ModalWarning"
import { mapGetters } from "vuex"
import SearchBar from "@/components/sale/SearchBar"
import ExternalSalesList from "@/components/sale/ExternalSalesList"
import ActionButton from "@/components/ActionButton"
import SsoService from "@/services/technical/SsoService"
import ExternalSaleSearchArea from "@/components/sale/ExternalSaleSearchArea"
import ArrayService from "@/services/technical/ArrayService"
import ModalSharingStatus from "@/components/modal/ModalSharingStatus"
import ModalVehicleSearch from "@/components/modal/ModalVehicleSearch.vue"

export default {
  name: "Vehicle",
  components: {
    ModalVehicleSearch,
    ActionButton,
    ExternalSalesList,
    SearchBar,
    ModalInfoVehicle,
    ModalWarning,
    ExternalSaleSearchArea,
    ModalSharingStatus,
  },
  props: {
    sharedToken: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showSharingStatusModal: false,
      showVehicleSearchModal: false,
      sharingStatus: null,
      showVehicleInfoModal: false,
      manualSearch: false,
      currentSearch: null,
      saleId: null,
      isExternalSale: false,
      activeFilters: {},
      isImmatMocked: process.env.NODE_ENV === "development",
      form: {
        plate: "",
        vin: "",
        manual: "",
        filters: {
          model_year: { inputType: "search", outputType: Number, value: "" },
          make: { inputType: "select", outputType: Array, value: [], choices: {} },
          model: { inputType: "select", outputType: Array, value: [], choices: {} },
          energy: { inputType: "select", outputType: Array, value: [], choices: {} },
          fiscal_hp: { inputType: "search", outputType: Number, value: "" },
        },
      },
      warningModal: {
        show: false,
        title: this.$t("offers.modals.noEligibleOffers.title"),
        reason: this.$t("offers.modals.noEligibleOffers.reason"),
        advice: this.$t("offers.modals.advice"),
      },
      displaySearchBar: true,
      externalSales: [],
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters("vehicle", [
      "getVelasticFound",
      "listVelasticSearchResults",
      "getUsedVehiclesSearchResult",
      "hasUsedVehiclesSearchResult",
      "isSearchLoading",
      "getActiveFilters",
      "getVelasticSearchErrors",
    ]),
    ...mapGetters("repository", ["listMakes", "listModels", "listEnergies"]),
    ...mapGetters("sale", [
      "currentSaleId",
      "hasExternalSale",
      "listExternalSalesByReference",
    ]),
    ...mapGetters("program", ["getSharedProgramStatus"]),

    getSearchText() {
      return this.currentSearch.searchType
        ? this.currentSearch.searchText
        : this.currentSearch.text
    },
    getSearchAreaClass() {
      return this.sharedToken === "" && this.hasExternalSale ? "search-area" : ""
    },
  },
  async created() {
    this.isLoading = true
    if (SsoService.isAuthenticated()) {
      await this.$store.dispatch("sale/getExternalSales")
    }
    if (this.sharedToken !== "") {
      await this.checkIfSharedLinkIsExpired()
    }
    await this.$store.dispatch("vehicle/resetUsedVehicleSearch")
    await this.$store.dispatch("vehicle/resetVelasticSearch")
    await this.$store.dispatch("vehicle/resetVehicleDetails")
    await this.$store.dispatch("basket/reset")
    await this.$store.dispatch("sale/resetSale")
    await this.$store.dispatch("repository/getAllEnergies")
    await this.$store.dispatch("repository/getAllMakes")
    this.form.filters.make.choices = this.listMakes
    this.form.filters.model.choices = this.listModels
    this.form.filters.energy.choices = this.listEnergies
    this.isLoading = false
  },
  methods: {
    onCloseModalVehicleSearch() {
      this.showVehicleSearchModal = false
      this.$store.dispatch("searchVehicle/resetFilters")
    },
    async onValidate() {
      this.showVehicleSearchModal = false
      this.manualSearch = true
      const tags = await this.$store.dispatch(
        "offer/getTagsFromEligibleOffers",
        this.getVelasticFound
      )

      if (tags.length === 0) {
        this.warningModal.show = true
      } else {
        this.showVehicleInfoModal = true
      }
    },
    async checkIfSharedLinkIsExpired() {
      try {
        await this.$store.dispatch("program/retrieveSharedProgram", this.sharedToken)
      } catch (e) {
        this.sharingStatus = this.getSharedProgramStatus
        if (this.getSharedProgramStatus && e && e.status && e.status === 400) {
          this.showSharingStatusModal = true
        }
      }
    },
    async onExternalSaleClick(externalSale) {
      this.isExternalSale = true
      this.saleId = externalSale.id
      await this.$store.dispatch(
        "vehicle/setVelasticSearchResultSelected",
        externalSale.vehicle
      )
      await this.$store.dispatch(
        "vehicle/setUsedVehicleSearchResult",
        externalSale.vehicle
      )
      this.onValidateVehicle()
    },
    async onValidateVersion(selectedVehicle) {
      await this.$store.dispatch(
        "vehicle/setVelasticSearchResultSelected",
        selectedVehicle
      )

      const tags = await this.$store.dispatch(
        "offer/getTagsFromEligibleOffers",
        this.getVelasticFound
      )

      if (tags.length === 0) {
        this.warningModal.show = true
      } else {
        this.showVehicleInfoModal = true
      }
    },
    onValidateVehicle() {
      this.submitVehicle()
    },
    onCloseVehicleInfoModal() {
      this.showVehicleInfoModal = false
    },
    onCloseWarningModal() {
      this.warningModal.show = false
    },
    async doVelasticSearch(query, filters = this.getActiveFilters) {
      this.currentSearch = query
      const cleanFilters = Object.assign(
        { ...query },
        Object.keys(filters).length === 0 ? null : { filters }
      )
      if (query != null) {
        await this.$store.dispatch("vehicle/velasticSearch", cleanFilters)
      }
    },
    async onVehicleManualSearch() {
      this.manualSearch = true
      await this.$store.dispatch("vehicle/setActiveFilter", {
        name: "model_year",
        type: Number,
        value: this.form.filters.model_year.value,
      })
      this.doVelasticSearch({ text: this.form.manual })
    },
    async onUsedVehicleSearch(searchType) {
      try {
        await this.$store.dispatch("vehicle/velasticSearch", {
          searchType: searchType,
          searchText: this.form[searchType],
        })
      } catch (e) {
        console.error("failed: ", e)
      } finally {
        if (this.hasUsedVehiclesSearchResult) {
          this.currentSearch = {
            searchType: searchType,
            searchText: this.form[searchType],
          }
        }
      }
    },
    async setCorrectFilters(filterName, filterInfos) {
      let filterInfosValue = ArrayService.isArray(filterInfos.value)
        ? filterInfos.value[0]
        : filterInfos.value
      await this.$store.dispatch("vehicle/setActiveFilter", {
        name: filterName,
        type: filterInfos.outputType,
        value: filterInfosValue,
      })
    },
    async onFilterInput(filterName, filterInfos) {
      await this.setCorrectFilters(filterName, filterInfos)
      if (filterName === "make") {
        await this.$store.dispatch("repository/selectMake", filterInfos.value)
        if (
          filterInfos.value !== "" &&
          filterInfos.value !== null &&
          JSON.stringify(filterInfos.value) !== "[null]"
        ) {
          let models
          if (ArrayService.isArray(filterInfos.value)) {
            models = await this.$store.dispatch(
              "vehicle/searchModelByMake",
              filterInfos.value.filter((n) => n)
            )
          } else {
            models = await this.$store.dispatch(
              "vehicle/searchModelByMake",
              filterInfos.value
            )
          }
          await this.$store.dispatch("repository/setModels", models)
          this.form.filters.model.choices = this.listModels
          if (!(this.form.filters.model.value in this.listModels)) {
            this.form.filters.model.value = []
          }
        } else {
          await this.resetMakesAndModels()
        }
      }
      await this.doVelasticSearch(this.currentSearch, this.getActiveFilters)
    },
    async resetMakesAndModels() {
      await this.$store.dispatch("repository/selectMake", null)
      this.form.filters.make.choices = this.listMakes
      this.form.filters.model.choices = this.listModels
      this.form.filters.model.value = []
      for (const filter in this.form.filters) {
        await this.$store.dispatch("vehicle/setActiveFilter", {
          name: filter,
          type: this.form.filters[filter].outputType,
          value: null,
        })
      }
    },
    async onResetFilters(fromCallback = false) {
      await this.resetMakesAndModels()
      await this.$store.dispatch("vehicle/resetVelasticErrors")
      if (!fromCallback)
        await this.doVelasticSearch(this.currentSearch, this.getActiveFilters)
    },
    async submitVehicle() {
      const found_vehicle = this.getVelasticFound
      const payload = {
        ...found_vehicle,
        plate: null,
        vin: null,
      }

      if (!this.manualSearch) {
        const used_vehicle = this.getUsedVehiclesSearchResult
        payload.plate = used_vehicle.car_plate
        payload.vin = used_vehicle.ident_type
      }

      if (!this.isExternalSale) {
        await this.$store.dispatch("sale/createSale", { vehicle: payload })
        this.saleId = this.currentSaleId
      }

      // Que faire si echec ?
      if (this.sharedToken !== "") {
        await this.$router.push({
          name: "shared_products",
          params: { sharedToken: this.sharedToken, saleId: this.saleId },
        })
      } else {
        await this.$router.push({
          name: "products",
          params: { saleId: this.saleId },
        })
      }
    },
    searchSale(reference) {
      this.externalSales = this.listExternalSalesByReference(reference)
      this.displaySearchBar = false
    },
    onDisplaySearchBar() {
      this.displaySearchBar = true
    },
  },
}
</script>

<style lang="scss">
.clickable {
  cursor: pointer;
  &:hover {
    transition: 0.3s;
    background-color: $caarealightgray;
  }
  &:hover > .icon-fade {
    transition: 0.4s;
    opacity: 0.8;
  }
}
.icon-fade {
  opacity: 0;
}
.search-area {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-column-gap: 12px;
}
.slide-up-enter-active {
  transition: all 0.3s ease;
}
.slide-up-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-up-enter, .slide-up-leave-to
    /* .slide-up-leave-active below version 2.1.8 */ {
  transform: translateX(-100%);
}
.external-search__no-result {
  margin-top: 5rem;
  p,
  h3 {
    text-align: center;
  }
}

.search-result {
  margin-bottom: 5rem;
}
</style>
