<template>
  <ModalBootstrap
    v-if="!isLoading"
    :title="title"
    :show-only-confirm-button="false"
    modal-size="large"
    data-cy="vehicle-info-modal"
    @close="onClose"
  >
    <template #body>
      <div class="mx-2 d-flex flex-column justify-content-center">
        <span class="font-size-12 mb-2 d-flex justify-content-center">
          {{ subTitle }}
        </span>
        <div class="w-100 mt-4">
          <FormRowSelect
            class="vehicle-info"
            :label="$t('vehicle_info.vehicle_type')"
            :label-class="[
              'text-right',
              'col-sm-4',
              'align-self-center',
              'font-size-20',
            ]"
            :control-class="['col-sm-6', 'align-self-center']"
            :selected-option.sync="form.vehicleType"
            :select-options="getTagsFromEligibleOffers"
            :name="`vehicle-type`"
            label-select-attr="key"
            :multiple="false"
            :errors="{}"
          ></FormRowSelect>
          <FormRowInput
            v-if="form.vehicleType !== '' && !isRetailPriceProvidedByVelastic"
            v-model="form.retailPrice"
            class="vehicle-info"
            :label="$t('vehicle_info.retail_price.label')"
            :label-class="[
              'text-right',
              'col-sm-4',
              'align-self-center',
              'font-size-20',
            ]"
            :control-class="['col-sm-6', 'align-self-center']"
            name="price"
            :placeholder="$t('vehicle_info.retail_price.placeholder')"
            :errors="{}"
          ></FormRowInput>
          <div
            v-if="form.vehicleType !== '' && form.vehicleType !== 'VNPJ'"
            class="row vehicle-info"
          >
            <label class="text-right align-self-center col-sm-4">
              {{ $t("vehicle_info.first_release_date.label") }}
            </label>
            <datepicker
              id="first_release_date"
              v-model="form.firstReleaseDate"
              class="col-sm-6 align-self-center"
              data-cy="first-release-date"
              input-class="form-control"
              :placeholder="$t('vehicle_info.first_release_date.placeholder')"
              :language="language"
              :monday-first="isMondayFirst"
              :disabled-dates="{ from: new Date() }"
            ></datepicker>
          </div>
          <FormRowInput
            v-if="form.vehicleType !== '' && form.vehicleType !== 'VNPJ'"
            v-model="form.kilometers"
            :label="$t('vehicle_info.mileage')"
            class="vehicle-info"
            :label-class="[
              'text-right',
              'col-sm-4',
              'align-self-center',
              'font-size-20',
            ]"
            :control-class="['col-sm-6', 'align-self-center']"
            name="kilometers"
            placeholder="5400"
            :errors="{}"
          ></FormRowInput>
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-md-6 px-2 text-center">
            <button
              class="btn btn-secondary w-75 px-1 mb-1"
              data-cy="cancel-vehicle-button"
              @click.prevent="onClose"
            >
              {{ $t("modal_cancel") }}
            </button>
          </div>
          <div class="col-md-6 px-2 text-center">
            <button
              class="btn btn-primary w-75 px-1 mb-1"
              data-cy="confirm-vehicle-info-button"
              :disabled="!areInputsValid"
              @click.prevent="onValidateVehicle"
            >
              {{ $t("modal_consult_offers") }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </ModalBootstrap>
</template>

<script>
import ModalBootstrap from "@/components/modal/ModalBootstrap"
import { mapGetters } from "vuex"
import Datepicker from "vuejs-datepicker"
import { en, fr } from "vuejs-datepicker/dist/locale"
import DateService from "@/services/technical/DateService"
import NumberService from "@/services/technical/NumberService"

export default {
  name: "ModalInfoVehicle",
  components: { ModalBootstrap, Datepicker },
  props: {
    title: {
      required: true,
      type: String,
    },
    subTitle: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      form: {
        vehicleType: "",
        retailPrice: "",
        kilometers: "",
        firstReleaseDate: "",
      },
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters("vehicle", ["getVelasticFound"]),
    ...mapGetters("offer", ["tagsFromEligibleOffers"]),
    ...mapGetters("config", ["getCurrentLang"]),
    getTagsFromEligibleOffers() {
      return this.tagsFromEligibleOffers?.reduce((acc, tag) => {
        acc[tag] = this.$t(`vehicle_info.filters.${tag}`)
        return acc
      }, {})
    },
    language() {
      return this.getCurrentLang === "fr" ? fr : en
    },
    isMondayFirst() {
      return this.getCurrentLang === "fr"
    },
    areInputsValid() {
      // Temporary ugly patch
      let isPriceValid = false
      if (this.isRetailPriceProvidedByVelastic) {
        isPriceValid = true
      } else {
        isPriceValid =
          NumberService.isNumber(this.form.retailPrice) && this.form.retailPrice !== ""
      }

      return this.form.vehicleType === "VNPJ"
        ? isPriceValid
        : NumberService.isNumber(this.form.kilometers) &&
            this.form.kilometers !== "" &&
            this.form.vehicleType !== "" &&
            this.form.firstReleaseDate !== "" &&
            isPriceValid
    },
    isRetailPriceProvidedByVelastic() {
      return (
        this.getVelasticFound.hasOwnProperty("retail_price") &&
        this.getVelasticFound.retail_price !== null &&
        this.getVelasticFound.retail_price !== undefined &&
        this.getVelasticFound.retail_price !== ""
      )
    },
  },
  async created() {
    this.isLoading = true
    await this.$store.dispatch("vehicle/resetVehicleDetails")
    this.isLoading = false
  },
  methods: {
    onClose() {
      this.$emit("vehicle-info-modal-close")
    },
    onValidateVehicle() {
      // Temporary ugly patch
      const retailPrice = this.isRetailPriceProvidedByVelastic
        ? this.getVelasticFound.retail_price
        : this.form.retailPrice
      const payload = {
        ...(this.form["kilometers"] !== "" && { kilometers: this.form["kilometers"] }),
        ...(retailPrice !== "" && {
          price: retailPrice,
        }),
        ...(this.form["firstReleaseDate"] !== "" && {
          first_release_date: DateService.format(
            this.form["firstReleaseDate"],
            "YYYY-MM-DD"
          ),
        }),
        vehicleType: this.form["vehicleType"],
      }
      this.$store.dispatch("vehicle/setVehicleDetails", payload)
      this.$emit("validate-vehicle")
    },
  },
}
</script>

<style scoped lang="scss">
.vehicle-info {
  min-height: 3.5rem;
  margin-bottom: 0.5rem;
  font-family: "Gilroy", sans-serif;
  background: #f7f5f0;
  font-size: 14px;
}
</style>
